import { ErrorHandler, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable(

)
export class MyErrorHandler implements ErrorHandler {
    public error$: Subject<any> = new Subject<any>();
    handleError(error: any) {
        console.log("in handler");
        this.error$.next(error);
        // Make sure to rethrow the error so Angular can pick it up
        throw error;
    }

    getMessage(): Observable<any> {
        return this.error$.asObservable();
    }

    sendMessage(error: any) {
        let msg = error.errorText ? error.errorText : "";
        let component = error.component ? error.component : "";
        if (error.constructor.name == "AppError") {
            msg = error.message;
        }
        this.error$.next({ errorText: msg, error: error, component: component });
    }
}