import { Component, ElementRef, OnChanges, OnDestroy, OnInit, Renderer, ViewChild, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { UpdateFormDirty, UpdateFormValue, UpdateFormStatus, SetFormPristine } from '@ngxs/form-plugin';
import { Select, Store } from '@ngxs/store';
import { FileSaverService } from 'ngx-filesaver';
import { Papa, PapaParseModule } from 'ngx-papaparse';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddNewListings, UpdateExistingListings, UpdateInputsFormListings } from 'src/app/actions/inputs.actions';
import { ClearAllListings, DeleteListings, FetchDrafts, FetchListings } from 'src/app/actions/listing.actions';
import { FetchCurrentUserProducts, UpdateSelectedInputProduct } from 'src/app/actions/product.actions';
import { FetchReleases } from 'src/app/actions/release.actions.';
import { md5 } from 'src/app/lib/md5';
import { Utils } from 'src/app/lib/utils';
import { Listing } from 'src/app/models/listing.model';
import { Product } from 'src/app/models/product.model';
import { Release } from 'src/app/models/release.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { AuthZService } from 'src/app/services/auth-z.service';
import { AuthService } from 'src/app/services/auth.service';
import { ListingsState } from 'src/app/state/listing.state';
import { ProductState } from 'src/app/state/product.state';
import { ReleaseState } from 'src/app/state/release.state';
import { CSVUtils } from 'src/app/utils/csv-utils';
import { ClrForm, ClrDatagrid } from '@clr/angular';
import { LocationStrategy } from '@angular/common';
import { nonAsciiValidator } from 'src/app/services/non-ascii.validator';
import { noWhitespaceValidator } from 'src/app/services/no-space.validator';
import { JSONUtils } from 'src/app/utils/json-utils';
import { WhitneyService } from 'src/app/services/whitney.service';
import { ExportToCsv } from 'export-to-csv';


@Component({
  selector: 'new-listings',
  templateUrl: './new-listings.component.html',
  styleUrls: ['./new-listings.component.scss']
})
export class NewListingsComponent implements OnInit, OnDestroy, AfterViewChecked {

  rowSelected = [];
  overflowBtns: boolean = false;
  appLabels: any;
  transLang: any;
  showReleases: boolean;
  selectedProduct: Product;
  submitted: boolean = false;
  publishConfirm: boolean = false;
  hideInputsForm: boolean = false;
  showResetWarn: boolean = false;
  timeSlots: Array<string> = [];
  publishFutureConfirm: boolean = false;
  publishConfirmation: boolean = false;
  publishConfirmationMessage: string;
  showDeleteConfirm: boolean = false;
  disableConfirm: boolean = false;
  @Output() collapse = new EventEmitter();

  //Popup editor
  editField: HTMLInputElement;
  editorOn: boolean;

  //alert message
  alertShow: boolean = false;
  alertText: string = "Saving...";
  hideEditInfo: boolean = true;
  editInfoText: string = "Saved data will be avaiable for edits at <a routerLink='/manage/edit'>Edit Port Data</a>";

  //File upload
  @ViewChild('fileUploadEle1')
  fileInputElement1: ElementRef;
  @ViewChild('fileUploadEle2')
  fileInputElement2: ElementRef;
  @ViewChild(ClrDatagrid)
  grid: ClrDatagrid;
  //loader
  loader: boolean = true;
  //Constants
  readonly maxDate = new Date(8640000000000000);


  inputsForm: FormGroup = this.formBuilder.group({
    listings: this.formBuilder.array([this.buildListing()])
  });
  publishForm = this.formBuilder.group({
    publishDate: ['', Validators.required],
    publishTime: ['00:00', Validators.required]
  });
  subscriptions: Array<Subscription> = [];
  hideCSVError: boolean = true;
  submissionStatus: string;
  csvErrorMsg: string = '';
  userProducts: Product[] = [];
  @ViewChild(ClrForm) clrForm;

  get listings(): FormArray {
    return <FormArray>this.inputsForm.get("listings");
  }
  constructor(private store: Store, private formBuilder: FormBuilder,
    private appStringService: AppStringsService, private renderer: Renderer,
    private papa: Papa, public authZ: AuthZService, private auth: AuthService,
    private fileSaver: FileSaverService, private location: LocationStrategy,
    private whitneyService: WhitneyService,
    private _ref: ChangeDetectorRef) {
  }

  hasUnsavedData(): any {
    if (this.inputsForm.dirty == true) {
      return true;
    }
    else {
      return false;
    }
  }

  @Select(ProductState.getSelectInputProduct) inputProduct$: Observable<Product>;
  @Select(ProductState.getUserProducts) userProducts$: Observable<Product[]>;
  @Select(ReleaseState.getReleases) releases$: Observable<Release[]>;
  @Select(ListingsState.getlistingsForEdit) listingsForEdit$: Observable<Listing[]>;

  ngOnDestroy() {
    this.onReset(true);
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title + " - Add Port Data", "/manage/addnew", "");
    //Remove existing Listings
    this.onReset(true);
    setTimeout(() => {
      this.collapse.emit("true");
    }, 0);
    this.setTimeSlots();
    let userProductsSub = this.userProducts$.subscribe(
      (products: Product[]) => {
        this.userProducts = products;
      }
    )
    //fetch releases when product is selected
    let inputsSub = this.inputProduct$.subscribe(
      (input: Product) => {
        if (input != null) {
          this.selectedProduct = this.getProductObj(input);
          this.store.dispatch(new FetchReleases(input));
        }
      }
    );
    this.subscriptions.push(inputsSub);
    //fetch user details with user products
    let userProdSub = this.store.dispatch(new FetchCurrentUserProducts()).subscribe(
      () => {
        this.loader = false;
      }
    );
    this.subscriptions.push(userProdSub);
    this.publishFutureConfirm = false;
    const earlierDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.publishForm.get('publishDate').setValue(earlierDate.toDateString());
    this.publishConfirmationMessage = this.appStringService.appStrings['publishNowConfirmationMessage'];
  }

  ngAfterViewChecked() {
    //column toggle adjustment
    let button: Element = document.getElementsByClassName("column-toggle--action")[0];
    let columenToggleList: HTMLElement = <HTMLElement>document.querySelector("clr-dg-column-toggle .column-switch");
    if (columenToggleList != null) {
      let transformStyle: string = columenToggleList.style.transform;
      if (this.listings.length <= 3) {
        this.renderer.setElementStyle(columenToggleList, 'transform', transformStyle.split(" ")[0] + " translateY(0px)");
      }
    }
  }

  setTimeSlots(): void {
    const numberOfHours = 24;
    for (let index = 0; index < numberOfHours; index++) {
      this.timeSlots.push(Utils.pad2(index) + ":00");
    }
  }

  buildListing(rowIndex: number = 0) {
    return this.formBuilder.group({
      id: [''],
      releases: ['', Validators.required],
      source: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      destination: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      port: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      protocol: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      purpose: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      serviceDescription: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      classification: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      selectedReleases: ['', Validators.required],
      product: '',
      productId: '',
      releasesId: '',
      rowIndex: rowIndex
    })
  }

  buildListingFromData(listing: Listing, rowIndex: number = 0) {
    let selectedReleasesText = '';
    if (listing["releases"]) {
      listing["releases"].forEach(element => {
        selectedReleasesText += (element["displayName"] ? element["displayName"] : "") + ",";
      });
      selectedReleasesText = selectedReleasesText.substring(0, selectedReleasesText.length - 1);
    }
    return this.formBuilder.group({
      id: [listing.id],
      releases: [listing.releases, Validators.required],
      source: [listing.source, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      destination: [listing.destination, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      port: [listing.port, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      protocol: [listing.protocol, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      purpose: [listing.purpose, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      serviceDescription: [listing.serviceDescription, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      classification: [listing.classification, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      selectedReleases: ['', Validators.required],
      product: '',
      productId: '',
      releasesId: '',
      rowIndex: rowIndex
    })
  }

  addListing(listing = undefined): void {
    if (listing == undefined) {
      this.listings.push(this.buildListing(this.listings.length));
    }
    else {
      this.listings.push(this.buildListingFromData(listing, this.listings.length));
    }
    let currentIndex = this.listings.length - 1;
    setTimeout(() => {
      for (const key1 in this.listings.controls[currentIndex]["controls"]) {
        if (this.listings.controls[currentIndex]["controls"][key1]) {
          this.listings.controls[currentIndex]["controls"][key1].markAsTouched({ propagateChildren: true });
          this.listings.controls[currentIndex]["controls"][key1].updateValueAndValidity();
        }
      }
    }, 1000);
  }


  removeRow(): void {
    let newListings: Array<Listing> = this.inputsForm.value.listings;
    console.log(newListings);
    console.log(this.listings);
    var indexSelected = []
    newListings.forEach(listing => {
      if (this.isListingSelected(listing)) {
        var i = newListings.indexOf(listing)
        indexSelected.push(i);
      }
    });
    indexSelected.sort();
    for (let i = 0; i < indexSelected.length; i++) {
      this.listings.removeAt(indexSelected[i] - i);
    }
  }

  showReleasesModal(): void {
    this.showReleases = true;
  }
  assignReleases(selectEle: HTMLSelectElement): void {
    let newListings: Array<Listing> = this.inputsForm.value.listings;
    let patchListings: any[] = [];
    let selectedReleases: any[] = [];
    let selectedReleasesId: any[] = [];
    let selectedReleasesText: string = "";
    for (let i = 0; i < selectEle.selectedOptions.length; i++) {
      selectedReleases.push({ id: selectEle.selectedOptions[i].value });
      selectedReleasesId.push(selectEle.selectedOptions[i].value);
      selectedReleasesText += selectEle.selectedOptions[i].textContent + ",";
    };
    if (selectedReleasesText != "") {
      selectedReleasesText = selectedReleasesText.substr(0, selectedReleasesText.length - 1);
    }
    newListings.forEach(listing => {
      patchListings.push(listing);
      if (this.isListingSelected(listing)) {
        this.inputsForm.controls.listings["controls"][patchListings.length - 1].markAsDirty();
        patchListings[patchListings.length - 1].releases = selectedReleases;
        patchListings[patchListings.length - 1].releasesId = selectedReleasesId;
        patchListings[patchListings.length - 1].selectedReleases = selectedReleasesText;
      }
    });
    this.inputsForm.patchValue({ listings: patchListings });
    this.showReleases = false;
  }


  onReset(force: boolean = false) {
    if (force == false) {
      if (this.listings.dirty == true) {
        this.showResetWarn = true;
      }
    }
    else {
      this.cleanFormState();
      this.listings.push(this.buildListing());
      this.showResetWarn = false;
    }
  }

  importCSV($event) {
    if ($event.target.files.length != 0) {
      const selectedFile: File = $event.target.files[0];
      let fr: FileReader = new FileReader();
      let csvData = new Subject<string>();
      let csvSub = csvData.subscribe(
        data => {
          this.loadCSVData(data);
        }
      );
      this.subscriptions.push(csvSub);
      fr.readAsText(selectedFile);
      fr.onloadend = function () {
        csvData.next(<string>fr.result);
      }
    }
  }

  loadCSVData(data: string) {
    this.papa.parse(data, {
      complete: (result) => {
        if (result.errors.length > 0) {
          this.hideCSVError = false;
          this.csvErrorMsg = "Error parsing the selected file. Retry with another file";
          setTimeout(() => {
            this.hideCSVError = true;
          }, 6000);
          return;
        }
        //Remove existing Listings
        while (this.listings.length > 0) {
          this.listings.removeAt(0);
        }
        result["data"] = CSVUtils.sanitizeCSVFormula(result["data"]);
        for (let i = 1; i < result["data"].length; i++) {
          if (result["data"][i][0]) {
            this.addListing(
              CSVUtils.getListingsFromCSV(
                result["data"][i],
                CSVUtils.getHeaderIndexes(result["data"][0])
              )
            );
            this.listings.markAsDirty();
          }
        }
      },
      error: (error, file) => {
        this.hideCSVError = false;
        this.csvErrorMsg = error.toString();
      }
    });
  }

  importJSON($event) {
    if ($event.target.files.length != 0) {
      const selectedFile: File = $event.target.files[0];
      let fr: FileReader = new FileReader();
      let jsonData = new Subject<string>();
      let jsonSub = jsonData.subscribe(
        data => {
          this.loadJSONData(data);
        }
      );
      this.subscriptions.push(jsonSub);
      fr.readAsText(selectedFile);
      fr.onloadend = function () {
        jsonData.next(<string>fr.result);
      }
    }
  }

  loadJSONData(data: string) {
    let result: any = {};
    try {
      result = JSON.parse(data);
      if (!this.isValidJSONInput(result)) {
        throw new SyntaxError();
      }
    }
    catch (e) {
      this.hideCSVError = false;
      this.csvErrorMsg = "Error parsing the selected file. Retry with another file";
      setTimeout(() => {
        this.hideCSVError = true;
      }, 6000);
      return;
    }

    //Remove existing Listings
    while (this.listings.length > 0) {
      this.listings.removeAt(0);
    }
    result = JSONUtils.sanitizeJSONData(result);
    for (let i = 0; i < result.length; i++) {
      this.addListing(result[i]);
    }
    this.listings.markAsDirty();
  }

  fileUpload1() {
    let event = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInputElement1.nativeElement, 'dispatchEvent', [event]);
  }

  fileUpload2() {
    let event = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInputElement2.nativeElement, 'dispatchEvent', [event]);
  }

  updateInputProduct($event) {
    this.onReset();
    let selectedProduct = $event.target.value;
    this.store.dispatch(new UpdateSelectedInputProduct(selectedProduct));
  }

  cancelPublishConfirm() {
    this.publishForm.reset();
    this.show('inputs');
    this.clearPublishForm();
    const earlierDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.publishForm.get('publishDate').setValue(earlierDate.toDateString());
    this.publishForm.get('publishTime').setValue('00:00');
    this.publishConfirmationMessage = this.appStringService.appStrings['publishNowConfirmationMessage'];
  }

  publish() {
    this.submissionStatus = "PUBLISHED";
    this.show('publishConfirm');
  }

  review() {
    this.submissionStatus = "REVIEW";
    this.show('publishConfirm');
  }

  show(comp: string): void {
    switch (comp) {
      case "inputs":
        this.hideInputsForm = false;
        this.submitted = false;
        this.publishConfirm = false;
        this.showResetWarn = false;
        break;
      case "publishConfirm":
        this.hideInputsForm = true;
        this.submitted = false;
        this.publishConfirm = true;
        this.showResetWarn = false;
        break;
      case "checkmark":
        this.hideInputsForm = true;
        this.submitted = true;
        this.publishConfirm = false;
        this.showResetWarn = false;
        break;
    }
  }
  openEditor($event) {
    this.editorOn = true;
    this.editField = $event.target.parentNode.getElementsByTagName("input")[0];
  }

  assignEditorText(text: string) {
    let index = this.editField.parentNode.parentElement.id;
    this.listings.value[index][this.editField.attributes.getNamedItem("formcontrolname").value] = text;
    this.editField.value = text;
    this.editorOn = false;
  }

  saveAsDraft() {
    let submissionId = md5(new Date() + " " + this.auth.tokenGetter());
    for (let i = 0; i < this.listings.length; i++) {
      if (!this.listings.value[i].submissionId) {
        this.listings.value[i].submissionId = submissionId;
      }
      //selected Product
      this.listings.value[i].product = { id: this.selectedProduct.id };
      this.listings.value[i].productId = this.selectedProduct.id;
      //this.listings.value[i].publishDate = this.maxDate.toISOString().split("T")[0] + " 00:00";
      this.listings.value[i].publishDate = Utils.getDateString(this.maxDate) + " 00:00";
      this.listings.value[i].status = "SAVED";
    }
    let updatEditSub = this.store.dispatch(this.getAction(this.inputsForm.value.listings)).subscribe(
      (data) => {
        this.alertShow = false;
        this.onReset(true);
        this.hideEditInfo = false;
        this.rowSelected = [];
        setTimeout(() => {
          this.hideEditInfo = true;
        }, 5000);
      }
    );
    this.subscriptions.push(updatEditSub);
    this.alertShow = true;
  }

  onSubmit() {
    this.publishConfirmation = false;
    let submissionId = md5(new Date() + " " + this.auth.tokenGetter());
    for (let i = 0; i < this.listings.length; i++) {
      if (!this.listings.value[i].submissionId) {
        this.listings.value[i].submissionId = submissionId;
      }
      //listing status
      if (this.submissionStatus) {
        this.listings.value[i].status = this.submissionStatus;
      }
      else {
        this.listings.value[i].status = "PUBLISHED";
      }
      //selected Product
      this.listings.value[i].product = { id: this.selectedProduct.id };
      this.listings.value[i].productId = this.selectedProduct.id;
      //this.listings.value[i].publishDate = new Date(this.publishForm.get('publishDate').value).toISOString().split("T")[0] + " 00:00";
      this.listings.value[i].publishDate = Utils.getDateString(this.publishForm.get('publishDate').value) + " 00:00";
    }
    this.store.dispatch(this.getAction(this.inputsForm.value.listings));
    this.show('checkmark');
    this.rowSelected = [];
    setTimeout(() => {
      this.onReset(true);
      this.show('inputs');
      this.clearPublishForm();
      this.publishForm.clearValidators();
      this.publishFutureConfirm = false;
    }, 3000);
  }

  // loadDrafts() {
  //   this.alertText = "Loading Drafts...";
  //   this.alertShow = true;
  //   this.store.dispatch(new FetchDrafts(this.selectedProduct.id)).subscribe(
  //     (state) => {
  //       this.loader = false;
  //       this.alertShow = false;
  //       let draftListings = state["inputs"]["inputsForm"]["model"]["listings"];
  //       if (draftListings.length > 0) {
  //         if (draftListings[0]["source"] != "") {
  //           while (this.listings.length != 0) {
  //             this.listings.removeAt(0);
  //           }
  //           for (let i = 0; i < draftListings.length; i++) {
  //             this.addListing(draftListings[i]);
  //           }
  //         }
  //       }
  //     }
  //   );
  // }

  exportToCSV() {
    let data = CSVUtils.formatToCSVData(this.listings.value);
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers:[
        this.transLang['ports'],
        this.transLang['protocols'],
        this.transLang['source'],
        this.transLang['destination'],
        this.transLang['serviceDesc'],
        this.transLang['purpose'],
        this.transLang['classification']]
    }
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(data);
  }

  isListingSelected(listing: Listing) {
    for (let index = 0; index < this.rowSelected.length; index++) {
      const element = this.rowSelected[index];
      if (element.value.rowIndex == listing.rowIndex) {
        return true;
      }
    }
    return false;
  }

  reloadApp() {
    location.reload();
  }

  getAction(listings: Listing[]) {
    let idPresent: boolean = false;
    listings.forEach(element => {
      if (element.id != null && element.id != undefined && element.id != "") {
        idPresent = true;
      }
    });
    if (!idPresent) {
      return new AddNewListings(listings);
    }
    else {
      return new UpdateExistingListings(listings);
    }
  }

  getProductObj(obj: Product) {
    let filledObj = new Product();
    this.userProducts.forEach(element => {
      if (element.id == obj.id) {
        filledObj = element;
      }
    });
    return filledObj;
  }

  clearPublishForm() {
    this.publishForm.reset();
    this.store.dispatch(
      new UpdateFormValue(
        {
          value: {},
          path: 'inputs.publishForm'
        }
      )
    );
    this.store.dispatch(
      new UpdateFormStatus({
        status: "",
        path: 'inputs.publishForm'
      })
    );
    this.store.dispatch(
      new UpdateFormDirty({
        dirty: false,
        path: 'inputs.publishForm'
      })
    );
    this.store.dispatch(new SetFormPristine('inputs.publishForm'));
  }


  cleanFormState() {
    while (this.listings.length > 0) {
      this.listings.removeAt(0);
    }
    this.inputsForm.reset();
    this.store.dispatch(new UpdateFormValue({
      value: { listings: [] },
      path: 'inputs.inputsForm'
    }))
    this.store.dispatch(new UpdateFormStatus({
      status: "INVALID",
      path: 'inputs.inputsForm'
    }))
    this.store.dispatch(new UpdateFormDirty({
      dirty: false,
      path: 'inputs.inputsForm'
    }))
    this.store.dispatch(new SetFormPristine('inputs.inputsForm'));
  }

  isValidJSONInput(data: any): boolean {
    let validKeys = [
      "classification",
      "destination",
      "port",
      "protocol",
      "purpose",
      "serviceDescription",
      "source"
    ]
    let isValid: boolean = true;
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        for (let keyIndex = 0; keyIndex < validKeys.length; keyIndex++) {
          const key = validKeys[keyIndex];
          if (!element.hasOwnProperty(key)) {
            isValid = false;
          }
        }
      }
    }
    else {
      isValid = false;
    }
    return isValid;
  }
  publishFuture(value: boolean) {
    if (value) {
      this.publishFutureConfirm = true;
      this.publishForm.get('publishDate').setValue('');
      this.publishForm.get('publishTime').setValue('00:00');
      this.publishForm.get('publishDate').setValidators([Validators.required]);
      this.publishForm.get('publishTime').setValidators([Validators.required]);
    } else {
      this.publishFutureConfirm = false;
      this.publishForm.clearValidators();
      const earlierDate = new Date(new Date().setDate(new Date().getDate() - 3));
      this.publishForm.get('publishDate').setValue(earlierDate.toDateString());
      this.publishForm.get('publishTime').setValue('00:00');
      this.publishConfirmationMessage = this.appStringService.appStrings['publishNowConfirmationMessage'];
    }
  }

  publishConfirmationEvent() {
    if (this.publishFutureConfirm) {
      this.publishConfirmationMessage = this.appStringService.appStrings['publishFutureConfirmationMessage'] + String(this.publishForm.get('publishDate').value) + ' at ' + this.publishForm.get('publishTime').value + ' PST?';
    }
    this.publishConfirmation = true;
  }
}
