import { AppState } from 'src/app/state/app.state';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { ProductState } from 'src/app/state/product.state';
import { ClearNetworkDiagramMeta, FetchNetworkDiagramMeta } from 'src/app/actions/app.actions';
import { FetchReleasesForSelectedProduct } from 'src/app/actions/release.actions.';
import { ReleaseState } from 'src/app/state/release.state';
import { Release } from 'src/app/models/release.model';
import { AsyncPipe, LocationStrategy } from '@angular/common';
import { FetchProductsForNetworkDiagrams } from 'src/app/actions/product.actions';
import { NetDiagramMeta } from 'src/app/models/netDiagraMeta.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentsService } from 'src/app/services/environment.service';
import { WhitneyService } from 'src/app/services/whitney.service';

@Component({
  selector: 'app-view-networkdiagrams',
  templateUrl: './view-networkdiagrams.component.html',
  styleUrls: ['./view-networkdiagrams.component.scss']
})
export class ViewNetworkdiagramsComponent implements OnInit,OnDestroy {

  @Select(ProductState.getProductsForNetworkDiagrams) products$: Observable<Product[]>;
  @Select(AppState.getNetworkDiagramsMeta) netDiagramsMeta$: Observable<any[]>;
  @Select(ReleaseState.getReleasesForSelectedProduct) releases$: Observable<Release[]>;
  transLang: any;
  productId: string;
  showLoader: boolean = false;
  showErrorPopup: boolean = false;
  env = this.envService.config;
  fullscreenImgSrc: string = "";
  openModal: boolean = false;
  showGallery: boolean = false;
  showProdList: boolean = true;
  keyword: string = "";
  diagramsMetaData: Array<NetDiagramMeta> = [];
  appLabels: Array<string> = [];
  selectedTags: Array<any> = [];
  selectedProductId: string;
  constructor(private store: Store, private _ref: ChangeDetectorRef, private renderer: Renderer2, public appStrings: AppStringsService, private route: ActivatedRoute, private location: LocationStrategy,
    private envService: EnvironmentsService, private whitneyService: WhitneyService) { }

  ngOnInit() {
    this.appLabels = this.appStrings.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    let productsSub = this.store.dispatch(new FetchProductsForNetworkDiagrams());
    combineLatest(productsSub, this.route.params).subscribe(
      ([stateData, params]) => {
        let productList = stateData.products.productsForNetworkDiagrams;
        if (productList && Array.isArray(productList) && productList.length > 0 &&
          params && params["id"]) {
            let filteredProds = productList.filter(ele => { return this.getURL(ele.displayName) == params["id"]});
            if(filteredProds && Array.isArray(filteredProds) && filteredProds.length>0)
            {
              this.selectedProductId = filteredProds[0]["id"];
              this.handleProductClick(this.selectedProductId);
            }
            else{
              this.location.pushState({},"Network Diagrams - VMware Ports and Protocols", "/network-diagrams", "");
            }
        }
      }
    )
    combineLatest(this.netDiagramsMeta$, this.releases$).subscribe(
      ([metadata, releases]) => {
        if (metadata) {
          this.diagramsMetaData = JSON.parse(JSON.stringify(metadata));
          if (releases) {
            for (let index = 0; index < this.diagramsMetaData.length; index++) {
              let rels = [];
              if (this.diagramsMetaData[index]["releaseIds"] !== undefined) {
                this.diagramsMetaData[index]["releaseIds"].forEach(element => {
                  releases.forEach(ele => {
                    if (ele.id == element) {
                      rels.push(ele.displayName);
                    }
                  })
                });
              }
              this.diagramsMetaData[index]["releases"] = rels;
            }
          }
        }
      }
    )
  }

  fetchNetworkDiagrams() {
    this.store.dispatch(new FetchNetworkDiagramMeta(this.productId)).subscribe(
      () => {
        this.showProdList = false;
        this.showGallery = true;
        this.showLoader = false;
      }
    )
  }

  download(url: string, meta: any) {
    this.showLoader = true;
    let fileName = meta.title.replace(/\s/g,"-");
    if(meta.contentType == "image/png"){
      fileName += ".png";
    }
    if(meta.contentType == "image/jpeg"){
      fileName += ".jpeg";
    }
    this.forceDownload(url, fileName);
  }

  forceDownload(url: string, fileName: string){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    window["parentComp"] = this;
    xhr.onload = function(){
        if(window["parentComp"]){
          window["parentComp"].showLoader  = false;
          delete window["parentComp"];
        }
        var urlObj = window.URL || (window as any).webkitURL;
        var imageUrl = urlObj.createObjectURL(this.response);
        var anchor = document.createElement('a');
        anchor.href = imageUrl;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
    xhr.send();
}

  openFullScreen(url) {
    this.fullscreenImgSrc = url;
    this.openModal = true;
  }

  updateReleases() {
    this.store.dispatch(new FetchReleasesForSelectedProduct({ id: this.productId }));
  }
  getReleases(releaseIds: string): Array<any> {
    if (!releaseIds) {
      return [];
    }
    let relIds: Array<string>;
    if (Array.isArray(releaseIds)) {
      relIds = JSON.parse(JSON.stringify(releaseIds));
    }
    else {
      relIds = releaseIds.split(",");
    }
    let rels = new AsyncPipe(this._ref).transform(this.releases$);
    let filteredRels = [];
    if (!rels) {
      return [];
    }
    filteredRels = rels.filter(element => {
      if (relIds.indexOf(element.id) != -1) {
        return element
      }
    });
    return filteredRels;
  }
  getReleasesWithND(prodId: string) {
    return prodId;
  }
  updateURL(prodId){
    let productStr = this.getURL(new AsyncPipe(this._ref).transform(this.products$).filter(ele=>{return ele.id == prodId})[0]["displayName"]);
    this.location.pushState({}, productStr +"Network Diagrams - VMware Ports and Protocols", "/network-diagrams/" + productStr, "");
  }

  getURL(str: string){
    return str.replace(/\s/g, "-").replace("/", "_");
  }
  getProductName(prodId){
    return new AsyncPipe(this._ref).transform(this.products$).filter(ele=>{return ele.id == prodId})[0]["displayName"]
  }
  handleProductClick(prodId) {
    this.showLoader = true;
    this.productId = prodId;
    this.fetchNetworkDiagrams();
    this.updateReleases()
  }
  handleBackBtn() {
    this.showProdList = true;
    this.showGallery = false;
    this.showLoader = false;
    this.clearFilters();
    this.location.pushState({},"Network Diagrams - VMware Ports and Protocols", "/network-diagrams", "");
  }

  removeLabel(label: string) {
    if (this.keyword.indexOf(" " + label) != -1)
      this.keyword = this.keyword.replace(" " + label, "");
    else if (this.keyword.indexOf(label) != -1)
      this.keyword = this.keyword.replace(label, "");
    this.keyword = this.keyword.trim();
  }

  updateSelectedTags(event: any, className: string, relTag:any) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.selectedTags = this.selectedTags.filter(ele => {
        return ele.value.toLowerCase().trim() != relTag.value.toLowerCase().trim() || ele.type.toLowerCase().trim() != relTag.type.toLowerCase().trim()
      });
    } else {
      this.selectedTags.push(relTag);
    }
  }

  getKeywordStr(): any[] {
    return this.selectedTags;
  }

  isSelectedTag(relTag): boolean{
    let returnVal: boolean = false;
    this.selectedTags.forEach(element => {
      if(element.type == relTag.type && element.value == relTag.value){
        returnVal = true;
      }
    });
    return returnVal;
  }

  clearFilters(){
    this.selectedTags = [];
    this.keyword = "";
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearNetworkDiagramMeta());
  }
}
