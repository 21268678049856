// import { Listing } from 'src/app/models/listing.model';

export class AddNewListings{
    static readonly type = '[ADD NEW LISTING] Add new Listings';

    constructor(public payload: any) {}

}
export class UpdateExistingListings{
    static readonly type = '[UPDATE EXISTING LISTING] Update Existing Listings';
    constructor(public payload: any) {}
}

export class DraftNewListings{
    static readonly type = '[SAVE AS DRAFT NEW LISTING] Draft new Listings';

    constructor(public payload: string) {}
}

export class UpdateInputsFormListings{
    static readonly type = '[UPDATE INPUTS FORM] Update Inputs Form';
    constructor(public payload: any) {}
}