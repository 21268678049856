import { PipeTransform, Pipe } from '@angular/core';
import { Utils } from '../lib/utils';

@Pipe({
    name: 'nameURL'
})
export class NameURLPipe implements PipeTransform {
    transform(value: string): string {
        return "/home/" + Utils.createdisplayNameURL(value);
    }
}