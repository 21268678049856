import { Product } from '../models/product.model';

export class AddNewProduct{
    static readonly type = '[PRODUCT] Add Product';

    constructor(public payload: Product) {}

}

export class DeleteProduct{
    static readonly type = '[PRODUCT] Remove Product';

    constructor(public payload: Product) {}

}

export class FetchProducts{
    static readonly type = '[PRODUCT] Get Products';
}

export class FetchProductsForHome{
    static readonly type = '[GET PRODUCT] Get Products for Home';
}

export class FetchProductsForNetworkDiagrams{
    static readonly type = '[GET PRODUCT] Get Products for Network Diagrams';
}


export class FetchAllProducts{
    static readonly type = '[PRODUCT] Get All Products';
}

export class UpdateSortOrderForProducts{
    static readonly type = '[PRODUCT] Update sort order for Products';
    constructor(public payload: any) {}
}

export class ClearAppAlert{
    static readonly type = '[PRODUCT] Clear App Alert';
    constructor() { }
}

export class UpdateSelectStatus{
    static readonly type = '[PRODUCT] Update Select Status';

    constructor(public payload: any) {}

}

export class FetchCurrentUserProducts{
    static readonly type = '[FETCH CURRENT USER PRODUCTS] Add User Products';

    //constructor(public payload: string) {}
}

export class UpdateSelectedInputProduct{
    static readonly type = '[UPDATE SELECTEDPRODUCT] Update Selected Product';

    constructor(public payload: string) {}
}

export class UpdateSelectedInputProductForEdit{
    static readonly type = '[UPDATE SELECTEDPRODUCT FOR EDIT] Update Selected Product in Listings Edit';
    constructor(public payload: string) {}
}

export class FetchRMProductsMatching{
    static readonly type = '[FETCH RMPRODUCTS] Fetch RM Products Matchin text';
    constructor(public payload: any) {}
}

export class ClearProductForm{
    static readonly type = '[CLEAR PRODUCT FORM] Clear Product Form';
}