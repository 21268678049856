import { State, StateContext, Selector, Action } from '@ngxs/store';
import { AddNewListings, DraftNewListings, UpdateExistingListings, UpdateInputsFormListings } from '../actions/inputs.actions';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Listing } from '../models/listing.model';
import { HttpClient } from '@angular/common/http';
import { FetchDrafts } from '../actions/listing.actions';
import { MyErrorHandler } from '../services/error-handler.service';
import { AppError } from '../models/app-error.model';
import { Utils } from '../lib/utils';
import { EnvironmentsService } from '../services/environment.service';

export class StateForm{
        model: any = undefined;
        dirty?: boolean = false;
        status?: string ="";
        errors?:any =  {};
}
class InputsStateModel{
        inputsForm:StateForm =  new StateForm();
        publishForm :StateForm = new StateForm();
        inputsForEditForm:StateForm = new StateForm();
        publishForEditForm :StateForm = new StateForm();
        approvedSubmission?: any;
}

@State<InputsStateModel>({
    name: "inputs",
    defaults: new InputsStateModel()
})
export class InputsState {

    constructor(private http: HttpClient, private erorHandler:MyErrorHandler, private envServie: EnvironmentsService) { }
    //for initial state
    ngxsOnInit(ctx: StateContext<any>) {
        //console.log('State initialized, now getting default listing details');
    }

    @Selector()
    static getListings(state: any) {
        return state.listings;
    }
    @Selector()
    static getApprovedSubmission(state: any) {
        return state.approvedSubmission;
    }

    @Action(AddNewListings)
    AddnewListings(ctx: StateContext<any>, payload: any) {
        return this.http.post(this.envServie.config.api + 'manage/v1/listings', payload['payload']).pipe(tap((result: Listing[]) => {
            ctx.patchState({
                inputsForm: {}
            });
        }),
            catchError(error => { this.erorHandler.handleError(new AppError('could not post new listings', error));console.error( error); return new Observable<String>(); })
        );
    }

    @Action(UpdateExistingListings)
    updateExistingListings(ctx: StateContext<any>, payload: any) {
        return this.http.put(this.envServie.config.api + 'manage/v1/listings', payload['payload']).pipe(tap((result: Listing[]) => {
            console.log("Existing Listings Updated!");
            if(result.length>0){
                ctx.patchState(
                    {
                        approvedSubmission : result[0].submissionId
                    }
                )
            }

        }),
            catchError(error => { this.erorHandler.handleError(new AppError('could not save updated listings', error)); return new Observable<String>(); })
        );
    }

    @Action(DraftNewListings)
    draftNewListings(ctx: StateContext<any>, payload: string) {
        return this.http.post(this.envServie.config.api + 'manage/v1/listings', payload['payload']).pipe(tap((result: Listing[]) => {
            console.log(result);
            ctx.setState({
                inputsForm: result
            });
        }),
            catchError(error => { this.erorHandler.handleError(new AppError('could not save new listings', error));console.error( error); return new Observable<String>(); })
        );
    }

    @Action(FetchDrafts)
    fetchDraftListings(ctx: StateContext<InputsStateModel>, payload: string) {
        if (payload["payload"]) {
            return this.http.get(this.envServie.config.api + 'manage/v1/vmwareproducts/' + payload['payload'] + '/listings?status=SAVED').pipe(tap((result: Listing[]) => {
                let inputsDefaults:Partial<InputsStateModel> = new InputsStateModel();
                inputsDefaults.inputsForm.model = {};
                inputsDefaults.inputsForm.model["listings"] = result;
                ctx.patchState(inputsDefaults);
            }),
                catchError(error => { console.error('could not fetch saved listings'); return new Observable<String>(); })
            );
        }
    }

    @Action(UpdateInputsFormListings)
    updateInputsFormListings({getState, patchState}:StateContext<InputsStateModel>, { payload } : any){
        let state = getState();
        let form = Utils.clone(state.inputsForm, new StateForm());
        form.model = payload;
        patchState( {
            inputsForm : form
        })
    }
}