import { Pipe, PipeTransform } from '@angular/core';
import { Listing } from '../models/listing.model';

@Pipe({
    name: 'pSort'
})
export class ProductsSortPipe implements PipeTransform {
    transform(items: any[]): any[] {
        let retArr = [];
        let itemsWithSortOrder = [];
        itemsWithSortOrder = items.filter(ele => { return ele.sortOrder }).sort((a, b) => { return a.sortOrder - b.sortOrder });
        items = items.filter(ele => { return !ele.sortOrder }).sort(
            (a, b) => {
                var x = a.displayName.toLowerCase();
                var y = b.displayName.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            }
        );
        retArr = itemsWithSortOrder.concat(items);
        return retArr;
    }
}
