import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UpdateExistingListings } from 'src/app/actions/inputs.actions';
import { FetchSubmissionsForReview, ClearSubmissionsForReview } from 'src/app/actions/listing.actions';
import { FetchCurrentUserProducts } from 'src/app/actions/product.actions';
import { Listing } from 'src/app/models/listing.model';
import { Product } from 'src/app/models/product.model';
import { Submission } from 'src/app/models/submission.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { ListingsState } from 'src/app/state/listing.state';
import { ProductState } from 'src/app/state/product.state';
import { Utils } from 'src/app/lib/utils';
import { MyErrorHandler } from 'src/app/services/error-handler.service';
import { LocationStrategy } from '@angular/common';
import { AddNewComment } from 'src/app/actions/comments.action';
import { SubmissionComment } from 'src/app/models/submission-comment.model';
import { InputsState } from 'src/app/state/inputs.state';
import { Router } from '@angular/router';
import { WhitneyService } from 'src/app/services/whitney.service';

@Component({
  selector: 'review-submissions',
  templateUrl: './review-submissions.component.html',
  styleUrls: ['./review-submissions.component.scss'],
  animations:
    [
      trigger('listAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),

          query('clr-dg-row', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ])
    ]
})
export class ReviewSubmissionsComponent implements OnInit, OnDestroy {

  @Select(ListingsState.getSubmissionsForReview) submissionsForReview$: Observable<Submission[]>;
  @Select(ProductState.getUserProducts) userProducts$: Observable<Product[]>;
  @Select(InputsState.getApprovedSubmission) approvedSubmission$: Observable<any>;
  search: string = "";
  appLabels: any;
  transLang: any;
  submissionsForReview: Submission[];
  numberOfCalls: number = 1;// default value's first call
  loader: boolean = true;
  errorMessage: any;
  hideErrorPopup: boolean = true;
  currentSubmissionId: string;
  currentStatus: string;
  openCommentModal: boolean = false;
  comment: string = "";
  subs: Subscription[] = [];
  constructor(private store: Store, private appStringService: AppStringsService,
    private errorHandler: MyErrorHandler, private _ref: ChangeDetectorRef, private router: Router,
    private location: LocationStrategy,private whitneyService: WhitneyService) { }

  ngOnDestroy() {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title+" - Review Submissions", "/manage/review", "");
    this.loader = true;
    this._ref.detectChanges();
    this.fetchSubmissionsForReview();
    let reviewSub = this.submissionsForReview$.subscribe(
      (submissions) => {
        if (submissions != null && submissions != undefined) {
          this.numberOfCalls--;
          if (this.numberOfCalls <= 1) {
            this.loader = false;
          }
          else {
            this.loader = true;
          }
          this.submissionsForReview = submissions;
        }
      }
    )
    this.subs.push(reviewSub);
    // Error Handler
    let errorSub = this.errorHandler.getMessage().subscribe(
      (error) => {
        console.log("in receiver");
        if (error && error.component) {
          if (error.component == "review") {
            this.errorMessage = error.errorText;
            this.hideErrorPopup = false;
            this.loader = false;
            this._ref.detectChanges();
          }
          if (error.component == "manage") {
            this.loader = false;
            this._ref.detectChanges();
          }
        }
      });
    this.subs.push(errorSub);
  }
  fetchSubmissionsForReview(): any {
    this.store.dispatch(new FetchCurrentUserProducts()).subscribe(
      (state) => {
        let userProducts = state.products.userProducts;
        this.store.dispatch(new ClearSubmissionsForReview()).subscribe(
          () => {
            for (let index = 0; index < userProducts.length; index++) {
              const element = userProducts[index];
              this.numberOfCalls++;
              this.store.dispatch(new FetchSubmissionsForReview(element.id));
            }
          }
        );
      }
    );
  }

  showCommentModalForSubmission(submissionId: string, status: string) {
    this.currentSubmissionId = submissionId;
    this.currentStatus = status;
    this.openCommentModal = true;
  }
  updateSubmissonStatus() {
    // Approval or Rejection
    this.loader = true;
    let submission: Submission;
    this.submissionsForReview.forEach(element => {
      if (element.submissionId == this.currentSubmissionId) {
        submission = element;
      }
    });
    if (submission) {
      let listingsToApprove: Listing[] = [];
      for (let index = 0; index < submission.listings.length; index++) {
        let listing = Utils.clone(submission.listings[index], new Listing());
        listing = this.sanitizeForSubmission(listing);
        listing["status"] = this.currentStatus;
        listingsToApprove.push(listing);
      }
      let updateSub = this.store.dispatch(new UpdateExistingListings(listingsToApprove)).subscribe(
        (result) => {
          if (this.comment != "" && this.currentSubmissionId) {
            let subComment = new SubmissionComment();
            subComment.submissionId = this.currentSubmissionId;
            subComment.submissionComment = this.comment;
            subComment.status = this.currentStatus;
            this.store.dispatch(new AddNewComment(subComment));
            this.comment = "";
          }
          this.fetchSubmissionsForReview();
          this.loader = false;
          this.reloadApp();
        }
      );
      this.subs.push(updateSub);
    }
  }

  reloadApp() {
    location.reload();
  }

  sanitizeForSubmission(listing: Listing) {
    delete listing["createdBy"];
    delete listing["createdDate"];
    return listing;
  }

  ngOnDestry() {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
}
