export class AppError implements Error {
    name: string;
    message: string;
    stack?: string;
    component: string;
    constructor(private msg: string, private comp: string = "") {
        this.message = msg;
        this.component = comp;
    }
}
