import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let displayName: string[] = [];
    let fieldName = ( args != undefined ) ? args : "displayName";
    if (!Array.isArray(value)) {
      return value;
    } else {
      value.map((val, index, arr) => {
        if (val) {
          displayName.push(val[fieldName]);
        }
        return displayName;
      });
    }
    return displayName.join(', ');
  }
}
