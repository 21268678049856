import { Release } from './release.model';

export class Product {
    id: string;
    productName ?: string;
    displayName ?: string;
    releases?: Release[];
    publishDate ?: string;
    rmProductId ?: number;
    rmProductIds ?: number[];
    logo ?: string | ArrayBuffer;
    sortOrder ?: number | null;
}
export class SelectStatus extends Product{
    selected: boolean;
}