import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentsService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ESPApiService {

  private url: string;
  constructor(private http: HttpClient, private envService: EnvironmentsService) {
    this.url = this.envService.config.authApiUrl;
  }

  authenticate(code: string, clientId: string, redirectUri: string) {
//     let data = {grant_type: 'authorization_code', code: code, client_id: clientId, redirect_uri : redirectUri, bypass_uri_check : true};
    const oktaClientId = this.envService.config.oktaClientId;
    const oktaClientPassword = this.envService.config.oktaClientPassword;
    const credentials = btoa(`${oktaClientId}:${oktaClientPassword}`); // 使用base64编码用户名和密码
//     const headers = new HttpHeaders({
//         'Content-Type': 'application/json'
//       });
    let data = {grant_type: 'authorization_code', code: code, redirect_uri : redirectUri, code_verifier: this.envService.config.oktaCodeVerifier};
    return this.http.post(this.envService.config.api + 'admin/v1/auth/token', data);
  }

  authorize(){
    let data = {resource: 'vcdr-planner-app', action: 'access'};
    return this.http.post(`${this.envService.config.authorizationUrl}`, data);
  }

  refreshToken(token: string) {
    let data = {grant_type: 'refresh_token', refresh_token: token};
    return this.http.post(this.envService.config.api + 'admin/v1/auth/token', data);
  }
}
