import * as jsonPath from 'jsonpath/jsonpath';

export class JSONUtils{
    static sanitizeJSONData(data: any){
        return data;
    }
    static formatToJSONData(data: any[], keyJSONPathMap: any): any[]{
        let tempData:Array<any> = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let newElement = {};
            for (const key in keyJSONPathMap) {
                if (keyJSONPathMap.hasOwnProperty(key)) {
                    const jsonPathStr: string = keyJSONPathMap[key];
                    if(jsonPathStr.indexOf("$") != -1){
                        newElement[key] =jsonPath.query(element, jsonPathStr);
                    }
                    else{
                        newElement[key] = element[jsonPathStr];
                    }
                }
            }
            tempData.push(newElement);
        }
        return tempData;
    }
}