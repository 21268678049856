import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../lib/utils';

@Pipe({
    name: 'jodaDate'
})
export class JodaDateFilter implements PipeTransform {
    transform(jodaDateObj: any): string {
        if (typeof jodaDateObj == "object") {
            return Utils.pad2(jodaDateObj["monthOfYear"]) +
                "-" + Utils.pad2(jodaDateObj["dayOfMonth"]) +
                "-" + jodaDateObj["yearOfEra"] +
                " " + Utils.pad2(jodaDateObj["hourOfDay"]) +
                ":" + Utils.pad2(jodaDateObj["minuteOfHour"]) ;/*+
                ":" + Utils.pad2(jodaDateObj["secondOfMinute"]) +
                " " + jodaDateObj["zone"]["id"];*/
        }
    }
}