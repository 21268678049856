import { Component, OnChanges, OnDestroy, OnInit, Renderer, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { UpdateFormDirty, UpdateFormValue, UpdateFormStatus, SetFormPristine } from '@ngxs/form-plugin';
import { Select, Store } from '@ngxs/store';
import { FileSaverService } from 'ngx-filesaver';
import { Papa } from 'ngx-papaparse';
import { Observable, Subscription } from 'rxjs';
import { AddNewListings, UpdateExistingListings } from 'src/app/actions/inputs.actions';
import { DeleteListings, FetchDrafts, FetchListingsForEdit, ClearListingsForEdit } from 'src/app/actions/listing.actions';
import { FetchCurrentUserProducts, UpdateSelectedInputProduct, UpdateSelectedInputProductForEdit } from 'src/app/actions/product.actions';
import { FetchReleases } from 'src/app/actions/release.actions.';
import { Utils } from 'src/app/lib/utils';
import { Listing } from 'src/app/models/listing.model';
import { Product } from 'src/app/models/product.model';
import { Release } from 'src/app/models/release.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { AuthZService } from 'src/app/services/auth-z.service';
import { AuthService } from 'src/app/services/auth.service';
import { ListingsState } from 'src/app/state/listing.state';
import { ProductState } from 'src/app/state/product.state';
import { ReleaseState } from 'src/app/state/release.state';
import { CSVUtils } from 'src/app/utils/csv-utils';
import { JoinPipe } from 'src/app/pipes/join.pipe';
import { LocationStrategy, AsyncPipe } from '@angular/common';
import { StateForm } from 'src/app/state/inputs.state';
import { noWhitespaceValidator } from 'src/app/services/no-space.validator';
import { nonAsciiValidator } from 'src/app/services/non-ascii.validator';
import { WhitneyService } from 'src/app/services/whitney.service';
import { ExportToCsv } from 'export-to-csv';


@Component({
  selector: 'edit-listings',
  templateUrl: './edit-listings.component.html',
  styleUrls: ['./edit-listings.component.scss']
})
export class EditListingsComponent implements OnInit, OnDestroy {


  rowSelected = [];
  appLabels: any;
  transLang: any;
  showReleases: boolean;
  showAssignRelsModal: boolean = false;
  selectedProduct: Product;
  submitted: boolean = false;
  publishConfirm: boolean = false;
  publishFutureConfirm: boolean = false;
  hideinputsForEditForm: boolean = false;
  showUpdatedRecords: boolean = false;
  disableProductSelection: boolean = false;
  showDeleteWarn: boolean = false;
  showResetWarn: boolean = false;
  timeSlots: Array<string> = [];
  updatedListings: Array<Listing> = [];
  publishConfirmation: boolean = false;
  publishConfirmationMessage: string;
  @Output() collapse: EventEmitter<string> = new EventEmitter();
  //Popup editor
  editField: HTMLInputElement;
  editorOn: boolean;

  //alert message
  alertHide: boolean = true;
  alertText: string = "Saving ...";

  //loader
  loader: boolean = true;
  //Constants
  readonly maxDate = new Date(8640000000000000);

  //Assign Release modal
  versionsNotAssigned: Array<string> = [];
  versionsNotAssignedPartially: Array<string> = [];
  versionsAssigned: Array<string> = [];
  versionsAssignedPartially: Array<string> = [];
  selectedRels: any = {};

  inputsForEditForm: FormGroup = this.formBuilder.group({
    listings: this.formBuilder.array([this.buildListing()])
  });

  publishForEditForm = this.formBuilder.group({
    publishDate: [''],
    publishTime: ['00:00']
  });
  subscriptions: Array<Subscription> = [];
  submissionStatus: string = "PUBLISHED";
  savingModal: boolean = false;
  get listings(): FormArray {
    return <FormArray>this.inputsForEditForm.get("listings");
  }
  constructor(private store: Store, private formBuilder: FormBuilder,
    private appStringService: AppStringsService, private renderer: Renderer,
    private papa: Papa, public authZ: AuthZService, private auth: AuthService,
    private fileSaver: FileSaverService, private location: LocationStrategy,
    private whitneyService: WhitneyService,
    private _ref: ChangeDetectorRef) {
  }

  hasUnsavedData(): any {
    if (this.inputsForEditForm.dirty == true) {
      return true;
    }
    else {
      return false;
    }
  }

  @Select(ProductState.getSelectedInputProductForEdit) inputProductForEdit$: Observable<Product>;
  @Select(ProductState.getUserProducts) userProducts$: Observable<Product[]>;
  @Select(ReleaseState.getReleases) releases$: Observable<Release[]>;
  @Select(ListingsState.getlistingsForEdit) listingsForEdit$: Observable<Listing[]>;

  ngOnDestroy() {
    this.clearFormState();
    this.clearPublishForm();
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.publishFutureConfirm = false;
  }
  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title + " - Edit Port Data", "/manage/edit", "");
    this.setTimeSlots();
    //fetch releases when product is selected
    let inputsEditSub = this.inputProductForEdit$.subscribe(
      (input: Product) => {
        if (input != null) {
          this.selectedProduct = input;
          this.store.dispatch(new FetchReleases(input));

          //fetch already exiting listings in "edit" action inputs
          this.loader = true;
          let fetchSub = this.store.dispatch(new FetchListingsForEdit(this.selectedProduct["id"])).subscribe(
            () => {
              this.loader = false;
              this.disableProductSelection = false;
            }
          );
          this.subscriptions.push(fetchSub);
          let listingsSub = this.listingsForEdit$.subscribe(
            (listings) => {
              // if(listings.length>0){
              //   this.loader = false;
              // }
              //clear esiting listings
              while (this.listings.length > 0) {
                this.listings.removeAt(0);
              }
              console.log(listings.length);
              //add product's listings
              listings.forEach(element => {
                this.addListing(element);
              });
              this.listings.markAsPristine();
            }
          );
          this.subscriptions.push(listingsSub);

        }
      }
    );
    this.subscriptions.push(inputsEditSub);
    //fetch user details with user products
    let currentUserProdSub = this.store.dispatch(new FetchCurrentUserProducts()).subscribe(
      () => {
        //this.loader = false;
      }
    );
    this.subscriptions.push(currentUserProdSub);
    this.publishFutureConfirm = false;
    const earlierDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.publishForEditForm.get('publishDate').setValue(earlierDate.toDateString());
    this.publishConfirmationMessage = this.appStringService.appStrings['publishNowConfirmationMessage'];
  }

  setTimeSlots(): void {
    const numberOfHours = 24;
    for (let index = 0; index < numberOfHours; index++) {
      this.timeSlots.push(Utils.pad2(index) + ":00");
    }
  }

  buildListing(rowIndex: number = 0) {
    return this.formBuilder.group({
      id: [''],
      releases: ['', Validators.required],
      source: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      destination: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      port: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      protocol: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      purpose: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      serviceDescription: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      classification: ['', [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      selectedReleases: ['', Validators.required],
      submissionId: [{ value: '', disabled: true }],
      product: '',
      productId: '',
      releasesId: '',
      rowIndex: rowIndex,
      status: ''
    })
  }

  buildListingFromData(listing: Listing, rowIndex: number = 0) {
    let selectedReleasesText = '';
    if (listing["releases"]) {
      listing["releases"].forEach(element => {
        selectedReleasesText += (element["displayName"] ? element["displayName"] : "") + ",";
      });
      selectedReleasesText = selectedReleasesText.substring(0, selectedReleasesText.length - 1);
    }
    return this.formBuilder.group({
      id: [listing.id],
      releases: [listing.releases, Validators.required],
      source: [listing.source, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      destination: [listing.destination, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      port: [listing.port, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      protocol: [listing.protocol, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      purpose: [listing.purpose, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      serviceDescription: [listing.serviceDescription, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      classification: [listing.classification, [Validators.required, noWhitespaceValidator(), nonAsciiValidator()]],
      selectedReleases: [selectedReleasesText, [Validators.required, this.noWhitespaceValidator()]],
      submissionId: [listing.submissionId],
      product: '',
      productId: '',
      releasesId: '',
      rowIndex: rowIndex,
      status: listing.status
    })
  }

 addListing(listing = undefined): void {
   if (listing == undefined) {
      this.listings.push(this.buildListing(this.listings.length));
    }
    else {
      this.listings.push(this.buildListingFromData(listing, this.listings.length));
    }
  }

  deleteListing(force = false): void {
    let listingsToDelete: Array<Listing> = [];
    this.rowSelected.forEach(element => {
      element.value.product = { "id": this.selectedProduct.id };
      element.value.productId = this.selectedProduct.id;
      element.value.releases = [];
      element.value.releasesId = [];
      listingsToDelete.push(element.value);
    });
    if (!force) {
      this.showDeleteWarn = true;
    }
    else {
      this.loader = true;
      let deleteListSub = this.store.dispatch(new DeleteListings(listingsToDelete)).subscribe(
        () => {
          let listEditSub = this.store.dispatch(new FetchListingsForEdit(this.selectedProduct.id)).subscribe(
            () => {
              this.loader = false;
              this.disableProductSelection = false;
              this.alertText = "Deletion successful !"
              this.alertHide = false;
              setTimeout(() => {
                this.alertHide = true;
                this.alertText = "Saving...";
              }, 2000);
            }
          );
          this.subscriptions.push(listEditSub);
        }
      );
      this.subscriptions.push(deleteListSub);
      this.showDeleteWarn = false;
    }
  }

  populateRelsForAssign() {
    let rels = new AsyncPipe(this._ref).transform(this.releases$);
    let selectedPorts = this.rowSelected.map((ele) => { return ele.value; });
    let relCounts = {};
    selectedPorts.forEach(element => {
      element.releases.forEach(ele => {
        if (!relCounts.hasOwnProperty(ele.id)) {
          relCounts[ele.id] = 0;
        }
        relCounts[ele.id]++;
      });
    });
    this.versionsNotAssigned = [];
    this.versionsNotAssignedPartially = [];
    this.versionsAssigned = [];
    this.versionsAssignedPartially = [];
    for (const key in relCounts) {
      if (Object.prototype.hasOwnProperty.call(relCounts, key)) {
        const element = relCounts[key];
        if (element == selectedPorts.length) {
          this.versionsAssigned.push(key);
        }
        if (element > 0 && element < selectedPorts.length) {
          this.versionsAssignedPartially.push(key);
          this.versionsNotAssignedPartially.push(key);
        }
      }
    }
    rels.forEach(element => {
      if (!relCounts.hasOwnProperty(element.id)) {
        this.versionsNotAssigned.push(element.id);
      }
    });

  }

  getVerName(id) {
    let rels = new AsyncPipe(this._ref).transform(this.releases$);
    return rels.filter(ele => { return ele.id == id })[0]["displayName"];
  }

  showReleasesModal(): void {
    //this.showReleases = true;
    this.populateRelsForAssign();
    this.showAssignRelsModal = true;
  }

  assignRelsSelectedPorts() {
    let newListings: Array<Listing> = this.inputsForEditForm.value.listings;
    let patchListings: any[] = [];

    newListings.forEach(listing => {
      patchListings.push(listing);
      if (this.isListingSelected(listing)) {
        this.inputsForEditForm.controls.listings["controls"][patchListings.length - 1].markAsDirty();
        patchListings[patchListings.length - 1].releases = this.addAssignedRels(this.removeUnassignedRels(patchListings[patchListings.length - 1].releases));
        patchListings[patchListings.length - 1].releasesId = this.getRelIds(patchListings[patchListings.length - 1].releases);
      }
      patchListings[patchListings.length - 1].selectedReleases = new JoinPipe().transform(patchListings[patchListings.length - 1].releases, "displayName");
      delete patchListings[patchListings.length - 1]["publishDate"];
    });
    this.inputsForEditForm.setValue({ listings: patchListings });
    this.showAssignRelsModal = false;
    this.updateValidationStatus();
  }

  removeUnassignedRels(relsForListing: Array<any>) {
    let selectedReleases = [];
    for (let index = 0; index < relsForListing.length; index++) {
      const element = relsForListing[index];
      if (this.versionsNotAssigned.indexOf(element["id"]) == -1) {
        selectedReleases.push({ id: element["id"], displayName: element["displayName"] });
      }
    }
    return selectedReleases;
  }

  addAssignedRels(relsForListing: Array<any>) {
    let assignedRels = this.getAssignRelObjs(this.versionsAssigned);
    for (const key in assignedRels) {
      if (assignedRels.hasOwnProperty(key) && (relsForListing.filter((ele) => { return ele["id"] == key }).length == 0)) {
        const element = assignedRels[key];
        relsForListing.push({ id: element["id"], displayName: element["displayName"] })
      }
    }
    return relsForListing;
  }
  
  getAssignRelObjs(assignedRels: Array<string>) {
    let rels = new AsyncPipe(this._ref).transform(this.releases$);
    let returnObjs = {};
    rels.forEach(element => {
      if (assignedRels.indexOf(element["id"]) != -1) {
        returnObjs[element["id"]] = element;
      }
    });
    return returnObjs;
  }
  getRelIds(rels: Array<any>) {
    let relIds = [];
    rels.forEach(element => {
      relIds.push(element["id"]);
    });
    return relIds;
  }

  assignReleases(selectEle: HTMLSelectElement): void {
    let newListings: Array<Listing> = this.inputsForEditForm.value.listings;
    let patchListings: any[] = [];
    let selectedReleases: any[] = [];
    let selectedReleasesId: any[] = [];
    let selectedReleasesText: string = "";
    for (let i = 0; i < selectEle.selectedOptions.length; i++) {
      selectedReleases.push({ id: selectEle.selectedOptions[i].value, displayName: selectEle.selectedOptions[i].textContent });
      selectedReleasesId.push(selectEle.selectedOptions[i].value);
      selectedReleasesText += selectEle.selectedOptions[i].textContent + ",";
    };
    if (selectedReleasesText != "") {
      selectedReleasesText = selectedReleasesText.substr(0, selectedReleasesText.length - 1);
    }
    newListings.forEach(listing => {
      patchListings.push(listing);
      if (this.isListingSelected(listing)) {
        this.inputsForEditForm.controls.listings["controls"][patchListings.length - 1].markAsDirty();
        patchListings[patchListings.length - 1].releases = selectedReleases;
        patchListings[patchListings.length - 1].releasesId = selectedReleasesId;
        patchListings[patchListings.length - 1].selectedReleases = selectedReleasesText;
      }
      else {
        patchListings[patchListings.length - 1].selectedReleases = new JoinPipe().transform(patchListings[patchListings.length - 1].releases, "displayName");
      }
    });
    this.inputsForEditForm.setValue({ listings: patchListings });
    this.showReleases = false;
    this.updateValidationStatus();
  }

  updateValidationStatus() {
    setTimeout(() => {
      for (let i = 0; i < this.listings.controls.length; i++) {
        for (const key1 in this.listings.controls[i]["controls"]) {
          if (this.listings.controls[i]["controls"][key1]) {
            this.listings.controls[i]["controls"][key1].markAsTouched({ propagateChildren: true });
            this.listings.controls[i]["controls"][key1].updateValueAndValidity();
          }
        }
      }
    }, 1000);
  }

  onReset(force = false, showLoader = false) {
    if (force == false) {
      if (this.listings.dirty == true) {
        this.showResetWarn = true;
      }
    }
    else {
      this.clearFormState();
      this.loader = true;
      this.store.dispatch(new FetchListingsForEdit(this.selectedProduct.id)).subscribe(
        () => {
          this.loader = showLoader;
        }
      );
      this.showResetWarn = false;
    }
  }

  loadCSVData(data: string) {
    this.papa.parse(data, {
      complete: (result) => {
        //Remove existing Listings
        while (this.listings.length > 0) {
          this.listings.removeAt(0);
        }
        for (let i = 1; i < result["data"].length; i++) {
          if (result["data"][i][0]) {
            this.addListing(
              CSVUtils.getListingsFromCSV(
                result["data"][i],
                CSVUtils.getHeaderIndexes(result["data"][0])
              )
            );
          }
        }
      }
    });
  }



  updateInputProduct($event) {
    this.disableProductSelection = true;
    //clear existing listings
    while (this.listings.length > 0) {
      this.listings.removeAt(0);
    }
    this.onReset(false, true);
    let selectedProduct = $event.target.value;
    this.loader = true;
    let updateEditSub = this.store.dispatch(new UpdateSelectedInputProductForEdit(selectedProduct));
  }

  cancelPublishConfirm() {
    this.publishFutureConfirm = false;
    this.publishForEditForm.reset();
    this.disableProductSelection = false;
    this.show('inputs');
    this.clearPublishForm();
    this.clearPublishForEditForm();
    this.publishConfirmationMessage = this.appStringService.appStrings['publishNowConfirmationMessage'];
  }

  publish() {
    this.disableProductSelection = true;
    this.submissionStatus = "PUBLISHED";
    this.clearPublishForEditForm();
    this.updateEditedListings();
    this.show('publishConfirm');
  }

  review() {
    this.disableProductSelection = true;
    this.submissionStatus = "REVIEW";
    this.updateEditedListings();
    this.show('publishConfirm');
  }


  updateEditedListings() {
    //If rows are selected , consider the selected one for action
    this.updatedListings = [];
    if (this.rowSelected.length > 0) {
      this.rowSelected.forEach(element => {
        console.log(element.value.releases);
        element.value.selectedReleases = new JoinPipe().transform(element.value.releases);
        this.updatedListings.push(element.value);
      });
    }
    else {
      //If none selected then identify the modified ones
      for (let index = 0; index < this.listings.controls.length; index++) {
        let element = this.listings.controls[index];
        if (element.dirty) {
          element.value.selectedReleases = new JoinPipe().transform(element.value.releases);
          this.updatedListings.push(element.value);
        }
      }
    }
  }
  show(comp: string): void {
    switch (comp) {
      case "inputs":
        this.hideinputsForEditForm = false;
        this.submitted = false;
        this.publishConfirm = false;
        this.showUpdatedRecords = false;
        this.showResetWarn = false;
        this.showDeleteWarn = false;
        break;
      case "publishConfirm":
        this.hideinputsForEditForm = true;
        this.submitted = false;
        this.publishConfirm = true;
        this.showUpdatedRecords = true;
        this.showResetWarn = false;
        this.showDeleteWarn = false;
        break;
      case "checkmark":
        this.hideinputsForEditForm = true;
        this.submitted = true;
        this.publishConfirm = false;
        this.showUpdatedRecords = false;
        this.showResetWarn = false;
        this.showDeleteWarn = false;
        break;
    }
  }

  openEditor($event) {
    this.editorOn = true;
    this.editField = $event.target.parentNode.getElementsByTagName("input")[0];
  }

  assignEditorText(text: string) {
    let index = this.editField.parentNode.parentElement.id;
    this.listings.value[index][this.editField.attributes.getNamedItem("formcontrolname").value] = text;
    this.editField.value = text;
    this.editorOn = false;
  }

  sanitizeListingsForSubmission(listings: Listing[], status = "PUBLISHED") {
    for (let i = 0; i < listings.length; i++) {
      //selected Product
      listings[i].product = { id: this.selectedProduct.id };
      listings[i].productId = this.selectedProduct.id;
      let a = this.publishForEditForm.get('publishDate').value;
      let b = this.publishForEditForm.get('publishTime').value;
      if (status == "PUBLISHED" || status == "REVIEW") {
        //listings[i].publishDate = new Date(this.publishForEditForm.get('publishDate').value).toISOString().split("T")[0] + " " + this.publishForEditForm.get('publishTime').value;
        listings[i].publishDate = Utils.getDateString(this.publishForEditForm.get('publishDate').value) + " " + this.publishForEditForm.get('publishTime').value;

      }
      else {
        listings[i].publishDate = Utils.getDateString(this.maxDate) + " 00:00";
      }
      //listing status
      if (status == "MODIFIED" && listings[i].status == "SAVED") {
        //dont change status . let it be SAVED
      }
      else {
        listings[i].status = status;
      }
      let releases = [];
      let releasesId = [];
      listings[i].releases.forEach(element => {
        releases.push(element);
        releasesId.push(element.id);
      });
      listings[i].releases = releases;
      listings[i].releasesId = releasesId;
    }
    return listings;
  }
  saveAsDraft() {
    this.disableProductSelection = true;
    this.updateEditedListings();
    let sanitizedListings = this.sanitizeListingsForSubmission(this.updatedListings, "MODIFIED");
    for (let index = 0; index < sanitizedListings.length; index++) {
      this.listings.value[index] = sanitizedListings[index];
    }
    let updateSub = this.store.dispatch(this.getAction(sanitizedListings)).subscribe(
      (data) => {
        this.store.dispatch(new FetchListingsForEdit(this.selectedProduct.id)).subscribe(
          () => {
            this.alertHide = true;
            this.savingModal = false;
            this.disableProductSelection = false;
          }
        )
      }
    );
    this.subscriptions.push(updateSub);
    this.store.dispatch(new UpdateFormDirty({
      dirty: false,
      path: 'inputs.inputsForEditForm'
    }))
    this.store.dispatch(new SetFormPristine('inputs.inputsForEditForm'));
    this.alertHide = false;
    this.savingModal = true;
    this.rowSelected = [];
  }

  onSubmit() {
    this.publishConfirmation = false;
    this.disableProductSelection = true;
    this.updatedListings = this.sanitizeListingsForSubmission(this.updatedListings, this.submissionStatus);
    this.loader = true;
    let submitSub = this.store.dispatch(this.getAction(this.updatedListings)).subscribe(
      () => {
        let listEditSub = this.store.dispatch(new FetchListingsForEdit(this.selectedProduct.id)).subscribe(
          () => {
            this.loader = false;
            this.disableProductSelection = false;
          }
        );
        this.subscriptions.push(listEditSub);
      }
    );
    this.subscriptions.push(submitSub);
    this.show('checkmark');
    this.rowSelected = [];
    setTimeout(() => {
      this.onReset();
      this.show('inputs');
      this.clearPublishForm();
      this.publishForEditForm.clearValidators();
      this.publishFutureConfirm = false;
    }, 3000);
  }

  // loadDrafts() {
  //   //TODO - figure load draft for published listings
  //   this.store.dispatch(new FetchDrafts(this.selectedProduct.id)).subscribe(
  //     (state) => {
  //       this.loader = false;
  //       let draftListings = state["inputs"]["inputsForEditForm"]["model"]["listings"];
  //       if (draftListings.length > 0) {
  //         if (draftListings[0]["source"] != "") {
  //           while (this.listings.length != 0) {
  //             this.listings.removeAt(0);
  //           }
  //           for (let i = 0; i < draftListings.length; i++) {
  //             this.addListing(draftListings[i]);
  //           }
  //         }
  //       }
  //     }
  //   );
  // }

  exportToCSV() {
    let data = CSVUtils.formatToCSVData(this.listings.value);
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers:[
        this.transLang['ports'],
        this.transLang['protocols'],
        this.transLang['source'],  
        this.transLang['destination'],
        this.transLang['serviceDesc'],
        this.transLang['purpose'],
        this.transLang['classification']]
    }
      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(data);
  }

  isListingSelected(listing: Listing) {
    for (let index = 0; index < this.rowSelected.length; index++) {
      const element = this.rowSelected[index];
      if (element.value.rowIndex == listing.rowIndex) {
        return true;
      }
    }
    return false;
  }

  reloadApp() {
    location.reload();
  }

  getAction(listings: Listing[]) {
    let idPresent: boolean = false;
    listings.forEach(element => {
      if (element.id != null) {
        idPresent = true;
      }
    });
    if (!idPresent) {
      return new AddNewListings(listings);
    }
    else {
      return new UpdateExistingListings(listings);
    }
  }

  clearPublishForm() {
    this.publishForEditForm.reset();
    this.store.dispatch(
      new UpdateFormValue(
        {
          value: {},
          path: 'inputs.publishForEditForm'
        }
      )
    );
    this.store.dispatch(
      new UpdateFormStatus({
        status: "",
        path: 'inputs.publishForEditForm'
      })
    );
    this.store.dispatch(
      new UpdateFormDirty({
        dirty: false,
        path: 'inputs.publishForEditForm'
      })
    );
    this.store.dispatch(new SetFormPristine('inputs.publishForEditForm'));
  }

  clearFormState() {
    while (this.listings.length > 0) {
      this.listings.removeAt(0);
    }
    this.store.dispatch(
      new ClearListingsForEdit()
    );
    this.store.dispatch(new UpdateFormValue({
      value: { listings: [] },
      path: 'inputs.inputsForEditForm'
    }))
    this.store.dispatch(new UpdateFormStatus({
      status: "INVALID",
      path: 'inputs.inputsForEditForm'
    }))
    this.store.dispatch(new UpdateFormDirty({
      dirty: false,
      path: 'inputs.inputsForEditForm'
    }))
    this.store.dispatch(new SetFormPristine('inputs.inputsForEditForm'));
  }

  clearInputsForm() {
    this.publishForEditForm.reset();
    this.store.dispatch(new UpdateFormValue({
      value: { listings: [] },
      path: 'inputs.inputsForm'
    }))
    this.store.dispatch(new UpdateFormStatus({
      status: "INVALID",
      path: 'inputs.inputsForm'
    }))
    this.store.dispatch(new UpdateFormDirty({
      dirty: false,
      path: 'inputs.inputsForm'
    }))
    this.store.dispatch(new SetFormPristine('inputs.inputsForm'));
  }

  //Form Validators
  public noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
    };
  }

  publishFuture(value: boolean) {
    if (value) {
      this.publishFutureConfirm = true;
      this.publishForEditForm.get('publishDate').setValue('');
      this.publishForEditForm.get('publishTime').setValue('00:00');
      this.publishForEditForm.get('publishDate').setValidators([Validators.required]);
      this.publishForEditForm.get('publishTime').setValidators([Validators.required]);
    } else {
      this.publishFutureConfirm = false;
      this.clearPublishForEditForm();
      this.publishConfirmationMessage = this.appStringService.appStrings['publishNowConfirmationMessage'];
    }
  }

  publishConfirmationEvent() {
    if (this.publishFutureConfirm) {
      this.publishConfirmationMessage = this.appStringService.appStrings['publishFutureConfirmationMessage'] + String(this.publishForEditForm.get('publishDate').value) + ' at ' + this.publishForEditForm.get('publishTime').value + ' PST?';
    }
    this.publishConfirmation = true;
  }

  moveToAssigned() {
    this.selectedRels["notAssigned"].forEach(element => {
      if (this.versionsNotAssigned.indexOf(element) >= 0) {
        this.versionsNotAssigned.splice(this.versionsNotAssigned.indexOf(element), 1);
      }
      if (this.versionsNotAssignedPartially.indexOf(element) >= 0) {
        this.versionsNotAssignedPartially.splice(this.versionsNotAssignedPartially.indexOf(element), 1);
      }
      if (this.versionsAssignedPartially.indexOf(element) >= 0) {
        this.versionsAssignedPartially.splice(this.versionsAssignedPartially.indexOf(element), 1);
      }
    });
    this.versionsAssigned = Array.from(new Set(this.versionsAssigned.concat(this.selectedRels["notAssigned"])));
    this.selectedRels = {};
  }
  moveToUnAssigned() {
    this.selectedRels["assigned"].forEach(element => {
      if (this.versionsAssigned.indexOf(element) >= 0) {
        this.versionsAssigned.splice(this.versionsAssigned.indexOf(element), 1);
      }
      if (this.versionsNotAssignedPartially.indexOf(element) >= 0) {
        this.versionsNotAssignedPartially.splice(this.versionsNotAssignedPartially.indexOf(element), 1);
      }
      if (this.versionsAssignedPartially.indexOf(element) >= 0) {
        this.versionsAssignedPartially.splice(this.versionsAssignedPartially.indexOf(element), 1);
      }
    });
    this.versionsNotAssigned = Array.from(new Set(this.versionsNotAssigned.concat(this.selectedRels["assigned"])));
    this.selectedRels = {};
  }

  toggleSel(event, relId, category) {
    let ele = event.target;
    if (ele.classList.contains('selected')) {
      ele.classList.remove('selected');
      let index = this.selectedRels[category].indexOf(relId);
      this.selectedRels[category].splice(index, 1);
    }
    else {
      ele.classList.add('selected');
      if (!this.selectedRels[category]) {
        this.selectedRels[category] = [];
      }
      this.selectedRels[category].push(relId);
    }
  }
  clearPublishForEditForm() {
    this.publishForEditForm.clearValidators();
    const earlierDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.publishForEditForm.get('publishDate').setValue(earlierDate.toDateString());
    this.publishForEditForm.get('publishTime').setValue('00:00');
  }
}
