import { Listing } from '../models/listing.model';
import { Submission } from '../models/submission.model';

export class Utils {
    static clone<T>(from: T, to: T): T {
        for (let key in from) {
            if (from.hasOwnProperty(key)) {
                let element = from[key];
                to[key] = element;
            }
        }
        return to;
    }
    static cloneArray<T>(fromArray: Array<T>, toArray: Array<T>): Array<T> {
        for (let index = 0; index < fromArray.length; index++) {
            let element = fromArray[index];
            toArray[index] = Utils.clone(element, toArray[index]);
        }
        return toArray;
    }
    static pad2(number) {
        return (number < 10 ? '0' : '') + number
    }
    static joinSet(setObj): string {
        if(setObj){
            return Array.from(setObj.values()).toString();
        }
        return "";
    }
    static convertToSubmissions(listings: Listing[]): Submission[]{
        let returnVal:Submission[] = [];
        let subListMap: any = {};
        for (let index = 0; index < listings.length; index++) {
            const element = listings[index];
            if(subListMap[element["submissionId"]] == undefined){
                subListMap[element["submissionId"]] = [];
            }
            subListMap[element["submissionId"]].push(element);
        }
        for (const key in subListMap) {
            if (subListMap.hasOwnProperty(key)) {
                const element = subListMap[key];
                returnVal.push( { submissionId : key , listings: element});
            }
        }
        return returnVal;
    }
    static parseBoolean(string:string): boolean {
        let bool:boolean;
        switch(string.toLowerCase()){
            case "true" : bool = true; break;
            case "false" : bool = false; break;
        }
        return bool;
    }
    static createdisplayNameURL(productName : string){
        if(productName){
            return productName.replace(/\s/g, "-").replace("/", "_");
        }
        else{
            return "";
        }
    }

    static getDateString(str){
        let d ;
        if(typeof str == "string"){
            d= new Date(str);
        }
        else if(typeof str == "object"){
            d = str;
        }
        return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
    }
}