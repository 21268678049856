import { UserDetails } from './userdetails.model';

export class App {
    view?: View;
    admin?: any;
    manage?: any;
    user ?: UserDetails;
    token ?: string;
    error ?: string;
    sessionUser ?: any;
}
class View{
    searchString: string;
}


export class AppAlert{
    timeout: number;
    message : string;
    icon ?: string;
    type : 'success' | 'danger' | 'warning' | 'info';
    action ?: AlertAction;
}
export class AlertAction{
    label : string;
    callback : Function;
}