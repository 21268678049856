import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

    appDescription: string = "The VMware Ports and Protocols tool compiles a complete list of all ports and protocols used by VMware products and versions. When deploying multiple VMware products, you no longer have to hunt for ports data for different products in different places. You can easily create a dynamic list based on the specific products and versions in use.The tool has ports information for VMware products such as vSphere, vSAN, NSX Data Center for vSphere, vRealize Network Insight, vRealize Operations Manager, vRealize Automation, vCloud Availability, vCloud Usage Meter, VMware HCX, Horizon 7, and Workspace ONE Access."
    productDescriptions: any = {
      "vRealize Network Insight" : "Find out the communication ports that need to be enabled for inter communication and for various setups. The VMware Ports and Protocol tool includes the ports required for the platform cluster Setup, single platform setup, and proxy server set up.",
      "vSAN" : "Find out the TCP and UDP ports required to access VMware vSAN. The VMware ports and protocol tool includes the main port numbers, the optional ports, the protocol, the service description, and information about incoming and outgoing firewall connections.",
      "NSX Data Center for vSphere" : "Find out the Ports and Protocols required by NSX for vSphere. The VMware Ports and Protocol tool includes the main port numbers, the protocol, the purpose, the service description, and information about TLS enabled ports.",
      "vSphere" : "Find out the ports used to connect to VMWare vSphere, ESXi, and vCenter Server. The VMware ports and protocol tool includes the main port numbers, the optional ports, the protocol, the service description, and information about incoming and outgoing firewall connections."
    };
}