import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { trigger, transition, query, style, animate, stagger, keyframes } from '@angular/animations';
import { Submission } from 'src/app/models/submission.model';
import { Store, Select } from '@ngxs/store';
import { FetchCurrenUserSubmissions } from 'src/app/actions/listing.actions';
import { ListingsState } from 'src/app/state/listing.state';
import { Observable, Subscription } from 'rxjs';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { MyErrorHandler } from 'src/app/services/error-handler.service';
import { LocationStrategy } from '@angular/common';
import { CommentsState } from 'src/app/state/comments.state';
import { SubmissionComment } from 'src/app/models/submission-comment.model';
import { FetchComments, ClearAllComments } from 'src/app/actions/comments.action';
import { WhitneyService } from 'src/app/services/whitney.service';

@Component({
  selector: 'user-submissions',
  templateUrl: './user-submissions.component.html',
  styleUrls: ['./user-submissions.component.scss'],
  animations:
    [
      trigger('listAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),

          query('clr-dg-row', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ])
    ]
})
export class UserSubmissionsComponent implements OnInit, OnDestroy {

  @Select(ListingsState.getCurrentUserSubmissions) mySubmissions$: Observable<Submission[]>;
  @Select(CommentsState.getComments) comments$: Observable<SubmissionComment[]>;
  search: string = "";
  appLabels: any;
  transLang: any;
  loader: boolean = true;
  submissions: Submission[];
  errorMessage: any;
  hideErrorPopup: boolean = true;
  subComments: any = {};
  subscriptions: Array<Subscription> = [];
  showCommentPopup: boolean = false;
  commentText:string = ""
  constructor(private store: Store, private appStringService: AppStringsService,
    private errorHandler: MyErrorHandler, private _ref: ChangeDetectorRef,
    private location: LocationStrategy, private whitneyService: WhitneyService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title+" - View Submissions", "/manage/submissions", "");
    this.store.dispatch(new FetchCurrenUserSubmissions());
    let submsnSub = this.mySubmissions$.subscribe(
      (submissions) => {
        if (this.submissions) {
          this.loader = false;
        }
        this.submissions = submissions;
        //Fetch All comments
        let clearSub = this.store.dispatch(new ClearAllComments()).subscribe(
          () => {
            submissions.forEach(element => {
              this.store.dispatch(new FetchComments(element.submissionId));
            });
          }
        );
        this.subscriptions.push(clearSub);
      }
    );
    this.subscriptions.push(submsnSub);
    // Error Handler
    let errorSub = this.errorHandler.getMessage().subscribe(
      (error) => {
        console.log("in receiver");
        if (error && error.component && error.component == "user-submissions") {
          this.errorMessage = error.errorText;
          this.hideErrorPopup = false;
          this.loader = false;
          this._ref.detectChanges();
        }
      }
    );
    this.subscriptions.push(errorSub);
    this.subComments = {};
    let commentSub = this.comments$.subscribe(
      (result: SubmissionComment[]) => {
        result.forEach(element => {
          if (element) {
            this.subComments[element.submissionId] = element.submissionComment;
          }
        });
      }
    );
    this.subscriptions.push(commentSub);
  }

  reloadApp() {
    location.reload();
  }
  showCommentModal(text, event){
    this.commentText = text;
    this.showCommentPopup = true;
    event.preventDefault();
  }

}
