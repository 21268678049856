import { Select, Store } from '@ngxs/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClearNetworkDiagramMeta, FetchNetworkDiagramMeta, CreateNetworkDiagram, DeleteNetworkDiagram, UpdateNetworkDiagram, FetchNetworkDiagramById } from 'src/app/actions/app.actions';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { ProductState } from 'src/app/state/product.state';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { FetchCurrentUserProducts, UpdateSelectedInputProductForEdit } from 'src/app/actions/product.actions';
import { Release } from 'src/app/models/release.model';
import { ReleaseState } from 'src/app/state/release.state';
import { ClearReleases, FetchReleases } from 'src/app/actions/release.actions.';
import { LocationStrategy } from '@angular/common';
import { AppState } from 'src/app/state/app.state';
import { EnvironmentsService } from 'src/app/services/environment.service';
import { NetDiagramMeta } from 'src/app/models/netDiagraMeta.model';
import { WhitneyService } from 'src/app/services/whitney.service';

@Component({
  selector: 'update-netdiagram',
  templateUrl: './update-netdiagram.component.html',
  styleUrls: ['./update-netdiagram.component.scss']
})
export class UpdateNetdiagramComponent implements OnInit, OnDestroy {
  uploadForm: FormGroup;
  transLang: any;
  appLabels: any;
  subscriptions: Array<Subscription> = [];
  hideinputsForEditForm: boolean = true;
  showSuccess: boolean = false;
  disableSubmit: boolean = false;
  selectedProductId: string = "";
  fullscreenImgSrc: string = "";
  openModal: boolean = false;
  showGallery: boolean = true;
  env: any;
  showInputForm: boolean = false;
  idForDelete: string = "";
  openDeleteModal: boolean = false;
  deleteBtnDisabled: boolean = false;
  idForEdit: string = "";
  ProductId: string = "";
  isCreate: boolean = true;
  @Select(ProductState.getUserProducts) userProducts$: Observable<Product[]>;
  @Select(ReleaseState.getReleases) releases$: Observable<Release[]>;
  @Select(AppState.getNetworkDiagramsMeta) netDiagramsMeta$: Observable<any[]>;

  constructor(private formBuilder: FormBuilder, private store: Store, private appStringService: AppStringsService, 
    private location: LocationStrategy, private envService: EnvironmentsService, private whitneyService: WhitneyService) { }

  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title + " - Manage network diagrams", "/manage/network-diagrams", "");
    this.store.dispatch(new FetchCurrentUserProducts());
    this.appLabels = this.appStringService.appStrings;
    this.formReset();
    this.store.dispatch(new ClearReleases());
    this.hideinputsForEditForm = true;
    this.showSuccess = false;
    this.showGallery = true;
    this.isCreate = false;
    this.showInputForm = false;
    this.env = this.envService.config;
    this.store.dispatch(new ClearNetworkDiagramMeta());
  }

  formReset() {
    if (this.uploadForm)
      this.uploadForm.reset();
    this.uploadForm = this.formBuilder.group({
      productId: [{ value: this.selectedProductId, disabled: true }, Validators.required],
      releaseIds: [[], Validators.required],
      netDiagram: [[''], Validators.required],
      tags: ['', Validators.required],
      title: ['', Validators.required]
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let alertMsg = "";
      if (file.type != "image/png" && file.type != "image/jpeg") {
        alertMsg = "File type should be JPEG or PNG.";
      }
      if (file.size > (1 * 1024 * 1024)) {
        alertMsg += "File Size should not execeed 1MB";
      }
      if (alertMsg != "") {
        alert(alertMsg);
        this.uploadForm.get('netDiagram').setValue(['']);
      }
      else
        this.uploadForm.get('netDiagram').setValue(file);
    }
  }

  onSubmit() {
    this.disableSubmit = true;
    const formData = new FormData();
    formData.append('productId', this.uploadForm.get('productId').value);
    formData.append('releaseIds', this.uploadForm.get('releaseIds').value);
    formData.append('tags', this.uploadForm.get('tags').value);
    formData.append('title', this.uploadForm.get('title').value);
    let action;
    if (this.isCreate) {
      formData.append('file', this.uploadForm.get('netDiagram').value);
      action = new CreateNetworkDiagram(formData);
    }
    else {
      formData.delete('file');
      let fileValue = this.uploadForm.get('netDiagram').value;
      if (fileValue && fileValue["name"] && fileValue["type"] && fileValue["size"] && fileValue.size > 0) {
        //For POST ( Edit ) payload , send file field only when there is new network diagram uploaded
        formData.append('file', this.uploadForm.get('netDiagram').value);
      }
      action = new UpdateNetworkDiagram(formData, this.idForEdit);
    }
    this.store.dispatch(action).subscribe(
      (data) => {
        this.hideinputsForEditForm = true;
        this.showSuccess = true;
        this.formReset();
        this.store.dispatch(new ClearReleases());
        setTimeout(() => {
          this.hideinputsForEditForm = false;
          this.showSuccess = false;
          this.showInputForm = false;
          this.showGallery = true;
          this.isCreate = false;
          this.store.dispatch(new FetchNetworkDiagramMeta(this.selectedProductId));
        }, 3000);
      }
    )
  }

  updateInputProduct() {
    this.store.dispatch(new FetchReleases({ id: this.selectedProductId }));
  }


  ngOnDestroy(): void {
    this.store.dispatch(new ClearNetworkDiagramMeta());
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  updateNDList(event) {
    this.store.dispatch(new FetchNetworkDiagramMeta(this.selectedProductId));
  }

  openFullScreen(url) {
    this.fullscreenImgSrc = url;
    this.openModal = true;
  }

  addNewND() {
    this.isCreate = true;
    this.updateInputProduct();
    this.uploadForm.get('productId').setValue(this.selectedProductId);
    this.uploadForm.get('netDiagram').setValidators([Validators.required]);
    this.showGallery = false;
    this.showInputForm = true;
    this.hideinputsForEditForm = false;
  }

  backToGallery() {
    this.isCreate = false;
    this.showGallery = true;
    this.showInputForm = false;
    this.uploadForm.reset();
  }

  edit(metaId) {
    this.updateInputProduct();
    this.uploadForm.get('productId').setValue(this.selectedProductId);
    this.uploadForm.get('netDiagram').setValidators(null);
    this.idForEdit = metaId;
    this.store.dispatch(new FetchNetworkDiagramById(this.idForEdit)).subscribe(
      (state: any) => {
        let ndMeta: NetDiagramMeta = JSON.parse(JSON.stringify(state.appstate.netDiagram));
        this.uploadForm.get('releaseIds').setValue(ndMeta.releaseIds);
        this.uploadForm.get('title').setValue(ndMeta.title);
        this.uploadForm.get('tags').setValue(ndMeta.tags.join(","));
        this.showGallery = false;
        this.showInputForm = true;
        this.hideinputsForEditForm = false;
      }
    )
  }

  deleteConfirm(metaId) {
    this.idForDelete = metaId;
    this.openDeleteModal = true;
  }
  delete() {
    this.deleteBtnDisabled = true;
    this.store.dispatch(new DeleteNetworkDiagram(this.idForDelete, this.selectedProductId)).subscribe(
      () => {
        this.openDeleteModal = false;
        this.deleteBtnDisabled = false;
      }
    )
  }
}
function metaId(metaId: any) {
  throw new Error('Function not implemented.');
}

