import { Directive, ElementRef, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appPublishStatus]'
})
export class PublishStatusDirective implements AfterContentInit  {

  constructor(private el: ElementRef) {}

  ngAfterContentInit(){
    let statuses:Array<string> = this.el.nativeElement.innerText.split(",");
    let html = "";
    statuses.forEach(element => {
      let className = element.toLowerCase();
      html += '<span class="'+className+'"></span>';
      html += '<span>'+element+'</span>';
    });
    this.el.nativeElement.innerHTML = html;
}

}
