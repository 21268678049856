import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';

import { Listing } from 'src/app/models/listing.model';
import { FetchListings, DeleteListings, FetchListingsForEdit, FetchCurrenUserSubmissions, ClearAllListings, FetchSubmissionsForReview, ClearSubmissionsForReview } from '../actions/listing.actions';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Submission } from '../models/submission.model';
import { Utils } from '../lib/utils';
import { MyErrorHandler } from '../services/error-handler.service';
import { AppError } from '../models/app-error.model';
import { SubmissionComment } from '../models/submission-comment.model';
import { FetchComments, ClearAllComments, AddNewComment } from '../actions/comments.action';
import { EnvironmentsService } from '../services/environment.service';

export class CommentsStateModel {
    comments: SubmissionComment[];
}

@State<CommentsStateModel>({
    name: 'comments',
    defaults: {
        comments:[]
    }
})
export class CommentsState implements NgxsOnInit {

    constructor(private http: HttpClient, private errorHandler: MyErrorHandler, private envService: EnvironmentsService) { }

    //for initial state
    ngxsOnInit(ctx: StateContext<CommentsStateModel>) {
        //console.log('State initialized, now getting default listing details');
    }

    @Selector()
    static getComments(state: CommentsStateModel) {
        return state.comments;
    }

    @Action(FetchComments)
    fetchComments(ctx: StateContext<CommentsStateModel>, { payload }: any) {
        if (payload) {
            let state = ctx.getState();
            return this.http.get(this.envService.config.api + 'manage/v1/submissions/'+payload+'/comments').pipe(tap((result: SubmissionComment[]) => {
                console.log(result);
                ctx.patchState({
                    comments: [ ...state.comments, ...result]
                });
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not get comments', 'manage')); return new Observable<String>(); })
            );
        }
    }

    @Action(AddNewComment)
    addNewComment(ctx: StateContext<CommentsStateModel>, { payload }: any) {
        if (payload){
            return this.http.post(this.envService.config.api + '/manage/v1/submissions/'+payload.submissionId+'/comments',payload).pipe(tap((result: SubmissionComment[]) => {
                ctx.patchState({
                    comments: result
                });
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not add comment', 'manage')); return new Observable<String>(); })
            );
        }
    }

    @Action(ClearAllComments)
    clearAllComments(ctx: StateContext<CommentsStateModel>) {
        ctx.patchState({
            comments: []
        });
    }

}