import { Product } from './product.model';
import { Release } from './release.model';

export class Listing{
    rowIndex ?: number;
    id?: string;
    product: Product;
    version: Release[];
    source: string;
    destination: string;
    port: string;
    protocol: string;
    purpose: string;
    serviceDescription: string;
    classification: string;
    status?: string;
    active?: boolean;
    selected ?: boolean;
    submissionId ?: string;
    publishDate: string;
    releases ?: Array<Release>=[];
    createdBy ?: string;
    createdDate ?: string;
    productId ?:string;
    releasesId ?: string[];
}