import { Pipe, PipeTransform } from '@angular/core';
import { NetDiagramMeta } from '../models/netDiagraMeta.model';

@Pipe({
  name: 'tagsFilter',
  pure: false
})
export class TagsFilterPipe implements PipeTransform {
  transform(items: any[], searchTerms: any[]): any[] {
    if (!items) return [];
    // if (!searchText) return items;
    // searchText = searchText.toLowerCase();
    // let searchTerms = searchText.trim().split(",");
    if(!(searchTerms && Array.isArray(searchTerms) && searchTerms.length>0)){
      return items;
    }
    if (items[0] && items[0]['id']) {
      const filteredItems = items.filter((data: NetDiagramMeta) => {
        for (let index = 0; index < searchTerms.length; index++) {
          const term = searchTerms[index].value.toLowerCase().trim();
          const type = searchTerms[index].type.toLowerCase().trim();
          let termFound: boolean = false;
          if (term && type) {
            if (type =="tag" && typeof data["tags"] == "object" && data.tags && Array.isArray(data.tags)) {
              data.tags.forEach(
                ele =>{
                  termFound = termFound || ((ele != undefined && ele != null) && ele.toLowerCase().indexOf(term) >= 0);
                }
              )
            }
            if (type =="release" && typeof data["releases"] == "object" && data.releases && Array.isArray(data.releases)) {
              data.releases.forEach(
                ele =>{
                  termFound = termFound || ((ele != undefined && ele != null) && ele.toLowerCase().indexOf(term) >= 0);
                }
              )
            }
          }
          if(!termFound){
            return false;
          }
        }
        return true;
      });
      return filteredItems;
    }
  }
}
