import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';

import { Listing } from 'src/app/models/listing.model';
import { FetchListings, DeleteListings, FetchListingsForEdit, FetchCurrenUserSubmissions, ClearAllListings, FetchSubmissionsForReview, ClearSubmissionsForReview } from '../actions/listing.actions';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Submission } from '../models/submission.model';
import { Utils } from '../lib/utils';
import { MyErrorHandler } from '../services/error-handler.service';
import { AppError } from '../models/app-error.model';
import { AddNewFeedback } from '../actions/feedback.action';
import { Feedback } from '../models/feedback.model';
import { EnvironmentsService } from '../services/environment.service';

export class FeedbackStateModel {
    feedback: Feedback;
}

@State<FeedbackStateModel>({
    name: 'feedback',
    defaults: {
        feedback:new Feedback()
    }
})
export class FeedbackState implements NgxsOnInit {

    constructor(private http: HttpClient, private errorHandler: MyErrorHandler, private envService: EnvironmentsService) { }

    //for initial state
    ngxsOnInit(ctx: StateContext<FeedbackStateModel>) {
    }


    @Action(AddNewFeedback)
    addNewFeedback(ctx: StateContext<FeedbackStateModel>, { payload }: any) {
        if (payload){
            return this.http.post(this.envService.config.api + 'manage/v1/feedbacks',payload).pipe(tap((result: Feedback) => {
                console.log(JSON.stringify(result)+" Feedback Submitted!!");
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not send feedback', 'view')); return new Observable<String>(); })
            );
        }
    }
}