import { Pipe, PipeTransform } from '@angular/core';
import { NetDiagramMeta } from '../models/netDiagraMeta.model';

@Pipe({
  name: 'relTagsSetFilter'
})

export class RelTagsSetFilterPipe implements PipeTransform {
  transform(items: any[]): Set<string> {
    if (!items) return new Set();
    let allTags: Set<string> = new Set();
    let allReleases: Set<string> = new Set();
    let allFilterTags: Set<any> = new Set();
    for (let index = 0; index < items.length; index++) {
      items[index]["tags"].forEach(tag => allTags.add(tag.toLowerCase().trim()));
      if (items[index]["releases"])
        items[index]["releases"].forEach(rel => allReleases.add(rel.toLowerCase().trim()));
    }
    allReleases.forEach(ele => allFilterTags.add({ type: 'release' , value : ele}));
    allTags.forEach(ele => allFilterTags.add({ type: 'tag' , value : ele}));
    return allFilterTags;
  }
}
