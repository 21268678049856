import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { ActivatedRoute } from '@angular/router';
import { MyErrorHandler } from 'src/app/services/error-handler.service';
import { WhitneyService } from 'src/app/services/whitney.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  selectedTab:string = "users";
  appLabels:any;
  transLang: any;
  collapsed:boolean;
  subs: Array<any> = [];
  validRoutes: Array<string> = [
    "products" , "users"
  ];

  //Error Handler
  errorMessage: any = "";
  hideErrorPopup: boolean = true;
  showLoader: boolean = true;
  closable: boolean = true;

  constructor(private appStringService: AppStringsService, private route: ActivatedRoute,
    private errorHandler: MyErrorHandler, private _ref: ChangeDetectorRef, private whitneyService: WhitneyService) { }

  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    let routeSub = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        if(this.validRoutes.includes(params["id"])){
          this.selectedTab = params["id"];
        }
      }
    });
    // Error Handler
    let errorSub = this.errorHandler.getMessage().subscribe(
      (error) => {
        console.log("in receiver");
        if (error && error.component && error.component == "admin") {
          this.errorMessage = error.errorText;
          this.hideErrorPopup = false;
          this.showLoader = false;
          this._ref.detectChanges();
          setTimeout(() => {
            this.hideErrorPopup = true;
            this._ref.detectChanges();
          }, 5000);
        }
      }
    );
    this.subs.push(errorSub);
  }
  reloadApp() {
    location.reload();
  }

}
