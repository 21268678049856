import { Router } from '@angular/router';
import { Component, Inject, PLATFORM_ID, OnDestroy, ChangeDetectorRef, ElementRef, AfterViewInit, AfterViewChecked, OnChanges } from '@angular/core';
import { slideInAnimation } from './app-route-animations';
import { AppStringsService } from './services/app-strings.service';
import { AuthZService } from './services/auth-z.service';
import { Store, Select } from '@ngxs/store';
import { FetchSessionUser, LogoutUser, UpdateToken } from './actions/app.actions';
import { AppState } from './state/app.state';
import { Observable, Subject, fromEvent, merge, of, combineLatest, forkJoin } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { DOCUMENT } from '@angular/platform-browser';
import { isPlatformBrowser, AsyncPipe } from '@angular/common';
import { MyErrorHandler } from './services/error-handler.service';
//import { SwUpdate } from '@angular/service-worker';
import { mapTo } from 'rxjs/operators';
import { FetchProductsForHome, ClearAppAlert } from './actions/product.actions';
import { ProductState } from './state/product.state';
import { Product } from './models/product.model';
import { AuthUtils } from './utils/auth-utils';
import { CookieService } from 'ngx-cookie-service';
import { AppAlert } from './models/app.model';
import { Utils } from './lib/utils';
import { EnvironmentsService } from './services/environment.service';
import { WhitneyService } from './services/whitney.service';
import { TranslateService } from '@ngx-translate/core';
declare var esp;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnDestroy {
  id: string;
  appLabels: any;
  transLang: any;
  currentUserName: string = "Guest";
  ssoURL: string;
  themes: any;
  @Select(ProductState. getRouteValue) RouteValue$: Observable<Product[]>;
  @Select(AppState.getCurrentUserToken) token$: Observable<string>;
  @Select(AppState.getCurrentSessionUser) session$: Observable<string>;
  @Select(ProductState.getProductsForHome) products$: Observable<Product[]>;
  @Select(ProductState.getAppAlert) appAlertProducts$: Observable<AppAlert>;
  showAdmin: boolean = false;
  showManage: boolean = false;
  errorMessage: string;
  showErrorPopup: boolean = false;
  hideUpdateAlert: boolean = true;
  hideOfflineMessage: boolean = true;
  linkRef1: HTMLLinkElement;
  linkRef2: HTMLLinkElement;
  currentTheme: string;
  showThemeSelection: boolean = false;
  showAboutModal: boolean = false;
  aboutText: string = "";
  showAppAlert: boolean = false;
  appAlert: AppAlert = undefined;
  currentLocale: string = 'en';
  showLanguage: boolean;
  selectedLanguage: string;
  constructor(public appLabelsService: AppStringsService, private authZ: AuthZService,
    private store: Store, private jwthelper: JwtHelperService, private auth: AuthService,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object,
    private errorHandler: MyErrorHandler, private _ref: ChangeDetectorRef,
    private cookieService: CookieService, private hostElement: ElementRef,private route: Router,
    private env: EnvironmentsService, private whitneyService: WhitneyService  // ,private updates: SwUpdate) { }
  ) {
      if(localStorage.getItem('pnp-lang') === null || localStorage.getItem('pnp-lang') === 'en'){
        this.selectedLanguage = 'English';
      }
      else if(localStorage.getItem('pnp-lang') === 'ja'){
        this.selectedLanguage = '日本語'
      }
      this.env.selectedLang = this.selectedLanguage
  }
  ngOnInit() {
    this.appLabels = this.appLabelsService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    //theme switcher
    this.themes = {
      'chooseThemeLight': ['assets/css/clr-ui.min.css', 'assets/css/opaque.css'],
      'chooseThemeDark': ['assets/css/clr-ui-dark.min.css', 'assets/css/opaque.css'],
      'chooseThemeDarkTransp': ['assets/css/clr-ui-dark.min.css', 'assets/css/transparent.css'],
      'chooseThemeLightTransp': ['assets/css/clr-ui.min.css', 'assets/css/transparent.css']
    };
    });
    const redirectUri = window.location.origin + this.env.config.redirectUri;
    this.ssoURL = this.env.config["authApiUrl"]+"/authorize?response_type=code&client_id="+this.env.config.oktaClientId+"&redirect_uri="+location.protocol+"//"+location.host+"/auth"+"&state="
    +this.env.config["state"]+"&code_challenge="+this.env.config.oktaCodeChallenge+"&code_challenge_method=S256";

    this.auth.ssoURL = this.ssoURL;
    this.store.dispatch(new FetchProductsForHome());

    this.errorHandler.getMessage().subscribe(
      (error) => {
        console.log("in receiver");
        if (error && error.component && error.component == "app") {
          this.errorMessage = error.errorText;
          this.showErrorPopup = true;
        }
      }
    );

    this.store.dispatch(new UpdateToken(this.auth.tokenGetter()));
    if(this.auth.tokenGetter() &&  this.auth.tokenGetter()  != "null" && this.auth.tokenGetter()  != "undefined"){
      this.store.dispatch(new FetchSessionUser({ token :this.auth.tokenGetter()}));
    }
    this.session$.subscribe(
      (session) => {
        if (session) {
          this.currentUserName = session['fullName'];
          this.showAdmin = this.authZ.isAuthZed('admin', session);
          this.showManage = this.authZ.isAuthZed('manage', session);
        }
        else {
          this.currentUserName = "Guest";
        }
      }
    );
    combineLatest(this.appAlertProducts$).subscribe(
        (results) => {
          let alertConfig: AppAlert;
          for (let index = 0; index < results.length; index++) {
            const element = results[index];
            if (element) {
              alertConfig = element;
            }
          }
          if (alertConfig) {
            this.appAlert = Utils.clone(alertConfig, new AppAlert());
            if (this.appAlert.timeout) {
              setTimeout(() => {
                this.showAppAlert = false;
                this.appAlert = undefined;
                this.store.dispatch(new ClearAppAlert());
              }, this.appAlert.timeout);
            }
            if (!this.appAlert.icon) {
              this.appAlert.icon = "varies";
            }
            if (!this.appAlert.type) {
              this.appAlert.type = "info";
            }
            this.showAppAlert = true;
          }
        }
    )

    //theme switcher
    this.route.events.subscribe(
      (val) =>{
        if(val.hasOwnProperty("restoredState") && val["restoredState"] == null && val["url"].match(/\/(home|manage|admin|auth)\/?.*/) && val["id"] == 1){
          if (isPlatformBrowser(this.platformId)) {
            this.currentTheme = "Light";
            try {
              const stored = localStorage.getItem('theme');
              if (stored) {
                this.currentTheme = stored;
              }
            } catch (e) {
              // Nothing to do
            }
            this.linkRef1 = this.document.createElement('link');
            this.linkRef1.rel = 'stylesheet';
            if(this.themes){
            this.linkRef1.href = this.themes[this.currentTheme][0];
            this.linkRef2.href = this.themes[this.currentTheme][1];
            }
            this.document.querySelector('head').appendChild(this.linkRef1);
            this.linkRef2 = this.document.createElement('link');
            this.linkRef2.rel = 'stylesheet';
            this.document.querySelector('head').appendChild(this.linkRef2);
          }
        }
      }
    )


    merge(
      fromEvent(window, 'offline').pipe(mapTo(false)),
      fromEvent(window, 'online').pipe(mapTo(true)),
      of(navigator.onLine),
    ).subscribe(
      (event) => {
        this.hideOfflineMessage = event;
      }
    )
  }

  signOut() {
    AuthUtils.deleteAuthCookies(this.cookieService);
    this.store.dispatch(new LogoutUser()).subscribe(() => {
      this.store.dispatch(new UpdateToken(""));
      location.reload();
    }
    )
  }
  showThemeModal() {
    this.showThemeSelection = true;
  }
  switchTheme(theme) {
    this.currentTheme = theme
    localStorage.setItem('theme', this.currentTheme);
    this.updateSheets();
    this.showThemeSelection = false;
  }
  updateSheets() {
    this.linkRef1.href = this.themes[this.currentTheme][0];
    this.linkRef2.href = this.themes[this.currentTheme][1];
  }

  reloadApp() {
    location.reload();
  }

  showAbout() {
    this.aboutText = this.appLabels.aboutText;
    this.showAboutModal = true;
  }
  showIPF(){
    esp.track('HomePageFeedback');
  }

  changeLanguage(lang: string) {
    this.showLanguage = false;
    localStorage.setItem('pnp-lang', lang);
    location.reload();
  }

  onLanguageClick(){
    this.showLanguage = true;
  }

  onCancelClick(){
    this.showLanguage = false;
  }

  ngOnDestroy() {
    console.log("DESTROYED");
  }
}
