import { Release } from '../models/release.model';
import { Product } from '../models/product.model';

export class AddNewRelease{
    static readonly type = '[ADD RELEASE] Add Release';

    constructor(public payload: Release) {}

}

export class DeleteRelease{
    static readonly type = '[DELETE RELEASE] Delete Release';

    constructor(public payload: Release) {}

}

export class FetchReleases{
    //used by Inputs Component
    static readonly type = '[FETCH RELEASES] Fetch All Releases';

    constructor(public payload: Product) {}
}

export class ClearReleases{
    static readonly type = '[CLEAR RELEASES] Clear All Releases';
    constructor(){}
}

export class FetchReleasesForSelectedProduct{
    //used by releases component
    static readonly type = '[FETCH RELEASES FOR SELECTED] Fetch All Releases for selected product';

    constructor(public payload: Product) {}
}



export class UpdateSelectedProductForReleases{
    static readonly type = '[UPDATE SELECTED PRDOCUT FOR RELEASES] Update selected product for releases';
    constructor(public payload: any) {}
}

export class ClearReleasesForm{
    static readonly type = '[CLEAR RELEASE FORM] Clear Release Form';
}