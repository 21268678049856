import { SEOService } from './../../services/seo.service';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Product, SelectStatus } from '../../models/product.model'
import { ProductState } from '../../state/product.state';
import { Observable, Subscription, concat } from 'rxjs';
import { trigger, transition, query, stagger, animate, style, keyframes } from '@angular/animations';
import { ClrDatagrid, ClrDatagridColumn, DatagridStringFilter } from '@clr/angular';
import { MediaObserver } from '@angular/flex-layout';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { Listing } from 'src/app/models/listing.model';
import { ListingsState } from 'src/app/state/listing.state';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { AppState, AppStateModel } from 'src/app/state/app.state';
import { FetchListings, ClearAllListings } from 'src/app/actions/listing.actions';
import { UpdateViewSearch } from 'src/app/actions/app.actions';
import { UpdateSelectStatus, FetchProductsForHome } from 'src/app/actions/product.actions';
import { ActivatedRoute } from '@angular/router';
import { CSVUtils } from 'src/app/utils/csv-utils';
import { Papa } from 'ngx-papaparse';
import { FileSaverService } from 'ngx-filesaver';
import { AsyncPipe, DatePipe } from '@angular/common';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { JoinPipe } from 'src/app/pipes/join.pipe';
import { PDFUtils } from 'src/app/utils/pdf-utils';
import { MyErrorHandler } from 'src/app/services/error-handler.service';
import { ClrDatagridStringFilterInterface } from "@clr/angular";
import { ExcelService } from 'src/app/services/xlsx-helper.service';
import { AuthZService } from 'src/app/services/auth-z.service';
import { ClrDatagridComparatorInterface } from "@clr/angular";
import { JSONUtils } from 'src/app/utils/json-utils';
import { Utils } from 'src/app/lib/utils';
import { Meta, Title } from '@angular/platform-browser';

import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { ExportToCsv } from 'export-to-csv';
import { ConditionalExpr } from '@angular/compiler';
import { WhitneyService } from 'src/app/services/whitney.service';
import { EnvironmentsService } from 'src/app/services/environment.service';

class IntegerComparator implements ClrDatagridComparatorInterface<Listing> {
  compare(a: Listing, b: Listing) {
    if (isNaN(parseInt(a.port)) && isNaN(parseInt(b.port))) {
      return 0;
    }
    else if (isNaN(parseInt(a.port))) {
      return 1;
    }
    else if (isNaN(parseInt(b.port))) {
      return -1;
    }
    return parseInt(a.port) - parseInt(b.port);
  }
}
class ReleaseFilter implements ClrDatagridStringFilterInterface<Listing> {
  accepts(listing: Listing, search: string): boolean {
    let returnVal = false;
    for (let i = 0; i < listing.releases.length; i++) {
      let element = listing.releases[i];
      if (element.displayName == search || element.displayName.toLowerCase().indexOf(search) >= 0) {
        returnVal = true;
        break;
      }
    }
    return returnVal;
  }
}
class MultiStringFilter implements ClrDatagridStringFilterInterface<Listing> {
  private column: string;

  constructor(public columnName: string) {
    this.column = columnName;
  }
  accepts(listing: Listing, search: string): boolean {
    let returnVal = false;
    let searchTerms = search.split(/\s/);
    for (let i = 0; i < searchTerms.length; i++) {
      let term = searchTerms[i];
      let element: any;
      if (this.column.indexOf(".") != -1) {
        let keys = this.column.split(".");
        element = listing[keys[0]][keys[1]];
      }
      else {
        element = listing[this.column];
      }
      if (element && element.toLowerCase().indexOf(term) >= 0) {
        returnVal = true;
        break;
      }
    }
    return returnVal;
  }
}



@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  animations:
    [
      trigger('listAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),

          query('clr-dg-row', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ]),
      trigger('productAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),
          query('span', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ])
    ]
})
export class ViewComponent implements OnInit, OnDestroy {

  search: string;
  selected: boolean = true;
  appLabels: any;
  suggestions: string[];
  showDropDown: boolean;
  productSearch: string;
  collapsed: boolean;
  showLoader: boolean = true;
  currentLocale: string = 'en';
  private doc: any;
  private isSetupFontDone = false;
  private pdfData: any;
  private datePipe = new DatePipe('en-US');
  private defaultHeadingSize = 16;
  private defaultBigHeadingSize = 21;

  @ViewChildren(ClrDatagrid) datagrid:  QueryList<ClrDatagrid>;
  @ViewChildren(ClrDatagridColumn) columns: QueryList<ClrDatagridColumn>;
  @ViewChildren(DatagridStringFilter) columnFilters: QueryList<ClrDatagridColumn>;
  @Select(ProductState.getProductsForHome) products$: Observable<Product[]>;
  @Select(AppState.getCurrentSessionUser) session$: Observable<string>;
  @Select(ProductState.getDefaultProduct) defaultProduct$: Observable<string>;
  @Select(ProductState.getSelectStatus) selectStatus$: Observable<SelectStatus[]>;
  @Select(ListingsState.getListings) listings$: Observable<Listing[]>;
  @Select(AppState.getAppState) appState$: Observable<any>;

  subscriptions: Array<Subscription> = [];
  private currentFilterValue: string;
  filValues: any[];
  appLables: any;
  transLang:any;
  descriptions: any;
  firstTimeLoad: boolean = true;
  errorMessage: string = "";
  showErrorPopup: boolean = false;
  // For firing delayed fetch of listings for product selection
  fetchTimeoutObj: any;
  readonly fetchTimeout: number = 1000;
  prevData = {};
  selectedIds: string;
  showCSVBtn: boolean = false;
  public releaseFilter = new ReleaseFilter();
  public productFilter = new MultiStringFilter('product.displayName');
  public sourceFilter = new MultiStringFilter('source');
  public destinationFilter = new MultiStringFilter('destination');
  public portFilter = new MultiStringFilter('port');
  public protocolFilter = new MultiStringFilter('protocol');
  public purposeFilter = new MultiStringFilter('purpose');
  public serviceDescriptionFilter = new MultiStringFilter('serviceDescription');
  public classificationFilter = new MultiStringFilter('classification');

  readonly headerPropMap: any = {
    "Product": "product",
    "Releases": "releases",
    "Port": "port",
    "Protocol": "protocol",
    "Source": "source",
    "Destination": "destination",
    "Service Description": "serviceDescription",
    "Purpose": "purpose",
    "Classification": "classification"
  }

  public intComparator = new IntegerComparator();
  private aliasProductNames = {
    "NSX-T-Data-Center": "NSX",
    "vSphere-7": "vSphere"
  };
  constructor(private store: Store, public appLableStrings: AppStringsService, private route: ActivatedRoute,
    private papa: Papa, private fileSaver: FileSaverService, private _ref: ChangeDetectorRef,
    public mediaObserver: MediaObserver, private errorHandler: MyErrorHandler, private xlsService: ExcelService,
    private authZ: AuthZService, private meta: Meta, private title: Title,  private seoService: SEOService, private httpClient: HttpClient, private whitneyService: WhitneyService) {
    this.doc = new jsPDF({ orientation: 'landscape' });
    this.setupFont();
    this.suggestions = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit() {
    this.session$.subscribe((session) => {
        if(session && session["id"]) {
          this.showCSVBtn = this.authZ.isAuthZed('download-csv');

        } else {
          this.showCSVBtn = false;
        }
    });
    this.appLabels = this.appLableStrings.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.descriptions = this.seoService.productDescriptions;
    this.showDropDown = false;
    // RWD
    let mediaSub = this.mediaObserver.media$.subscribe(
      (mediaChange) => {
        if (mediaChange.mqAlias == "xs") {
          this.collapsed = true;
        }
        else {
          this.collapsed = false;
        }

      }
    );
    this.subscriptions.push(mediaSub);
    // Fetch list of products
    this.store.dispatch(new FetchProductsForHome());
    let productSub = this.products$.subscribe(
      products => {
        let selectStatusSub = this.selectStatus$.subscribe(
          (data: SelectStatus[]) => {
            if (JSON.stringify(data) == JSON.stringify(this.prevData)) {
              return;
            }
            else {
              this.prevData = data;
            }
            if (this.fetchTimeoutObj) {
              clearTimeout(this.fetchTimeoutObj);
            }
            this.fetchTimeoutObj = setTimeout(() => {
              this.onSelectionStatusChange(data, products);
            }, this.fetchTimeout);
          }
        );
        this.subscriptions.push(selectStatusSub);
      }
    );
    // Error Handler
    this.errorHandler.getMessage().subscribe(
      (error) => {
        if (error && error.component && error.component == "view") {
          this.errorMessage = error.errorText;
          this.showErrorPopup = true;
          this.showLoader = false;
          this._ref.detectChanges();
        }
      }
    );
    this.subscriptions.push(productSub);
    let listingSub = this.listings$.subscribe(
      (listings) => {
        if (listings) {
          //SEO - meta description
          let productSet: Set<string> = new Set<string>();
          if(listings && listings.length>0){
            let prodRels={};
            listings.forEach(element => {
              productSet.add(element.product.displayName);
              element.releases.forEach(rel => {
                if(prodRels[element.product.displayName] == undefined){
                  prodRels[element.product.displayName] = new Set<string>();
                }
                prodRels[element.product.displayName].add(rel.displayName);
              });
            });
            this.title.setTitle(Array.from(productSet).join(", ")+" - "+this.appLabels["title"]);
            let listingsRelContent = '';
            for (const key in prodRels) {
              if (prodRels.hasOwnProperty(key)) {
                const element: Set<string> = prodRels[key];
                if(this.descriptions[key]){
                  listingsRelContent+=this.descriptions[key]+"Ports and protocols information available for following releases: "+ Array.from(element).join(', ')+".";
                }
                else{
                  listingsRelContent += "Ports and protocols information for "+ key +" available for following releases: "+Array.from(element).join(', ')+".";
                }
              }
            }
            this.meta.updateTag({ name : 'description',  content: this.seoService.appDescription+" "+listingsRelContent});
          }
          // TODO releases array values and product names are not shown in suggestions
          let suggestionsSet: Set<string> = new Set<string>();
          listings.forEach(element => {
            for (const key in element) {
              if (key != "id" && element.hasOwnProperty(key)) {
                const field = element[key];
                if (typeof field == "string") {
                  suggestionsSet.add(field);
                }
                if (typeof field == "object" && field.displayName && typeof field.displayName == "string") {
                  suggestionsSet.add(field.displayName);
                }
                if (typeof field == "object" && Array.isArray(field) && field.length > 0 && field[0].displayName && typeof field[0].displayName == "string") {
                  let str = "";
                  for (let index = 0; index < field.length; index++) {
                    str += field[index].displayName + ",";
                  }
                  str = str.substr(0, str.length - 1);
                  suggestionsSet.add(str);
                }
              }
            }
          });
          this.suggestions = Array.from(suggestionsSet.values());
        }
      }
    )
    this.subscriptions.push(listingSub);

    let appSub = this.appState$.subscribe(
      (val: AppStateModel) => {
        if (val['view']) {
          this.search = val['view']['searchString'];
        }
      }
    );
    this.subscriptions.push(appSub);
    this.filValues = [];
  }

  onSelectionStatusChange(data: SelectStatus[], products: Product[]) {
    if (data.length != 0 && data[0]) {
      if (data[0].selected == undefined) {
        //First Time - set default value or take it from URL
        this.selectProductsFromURL(products);
      }
      else {
        //When user selects or deselects products
        let selectedIds: string = "";
        if (data.length != 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i] != undefined && data[i].selected) {
              selectedIds += data[i].id + ",";
            }
          }
          if (selectedIds != "") {
            selectedIds = selectedIds.substr(0, selectedIds.length - 1);
          }
        }
        if (this.selectedIds != selectedIds) {
          this.selectedIds = selectedIds;
          this.showLoader = true;
          if (selectedIds == "") {
            this.store.dispatch(new ClearAllListings());
            this.showLoader = false;
          }
          else {
            let fetchListSub = this.store.dispatch(new FetchListings(selectedIds)).subscribe(
              () => {
                this.showLoader = false;
              }
            );
            this.subscriptions.push(fetchListSub);
          }
        }
        else {
          this.showLoader = false;
        }

      }

    }
  }
  getDefaultProduct() {
    let defaultProduSub = this.defaultProduct$.subscribe(
      (defaultId: string) => {
        if (defaultId != '') {
          this.store.dispatch(new UpdateSelectStatus({ "prodId": defaultId, "value": true }));
        }
      }
    );
    this.subscriptions.push(defaultProduSub);
  }
  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
  }
  showDropdown(value) {
    if (value && (value.length > 0)) {
      this.showDropDown = true;
    }
    this.setViewSearchString(value);
  }
  selectedValue(suggestion) {
    this.search = suggestion;
    this.showDropDown = false;
  }

  setViewSearchString(value) {
    this.store.dispatch(new UpdateViewSearch(value));
  }


  exportToCSV() {
    let listingsInGrid = this.datagrid.first.items["_filtered"]
    let jsonData = CSVUtils.formatToCSVData(listingsInGrid, this.headerPropMap);
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers:[
        this.transLang['product'],
        this.transLang['releases'],
        this.transLang['ports'],  
        this.transLang['protocols'],
        this.transLang['source'],
        this.transLang['destination'],
        this.transLang['serviceDesc'],
        this.transLang['purpose'],
        this.transLang['classification']]
    }
      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(jsonData);
}
  exportToJSON() {
    let listingsInGrid = this.datagrid.first.items["_filtered"]
    let keyToJSONPathMap = {
      classification: this.transLang["classification"],
      destination: this.transLang["destination"],
      id: "id",
      port: "port",
      product: "$.product.productName",
      protocol: "protocol",
      purpose: "purpose",
      releases: "$.releases[*].displayName",
      serviceDescription: "serviceDescription",
      source: "source",
    };
    let heading = {
      product: this.transLang['product'],
      releases: this.transLang['releases'],
      port:this.transLang['ports'],  
      protocol: this.transLang['protocols'],
      source: this.transLang['source'],
      destination: this.transLang['destination'],
      serviceDescription: this.transLang['serviceDesc'],
      purpose: this.transLang['purpose'],
      classification:this.transLang['classification']
     }
     let header = null;
     if(localStorage.getItem('pnp-lang') != 'en'){
      header = CSVUtils.objectFlip(heading);
     } else {
      header = this.headerPropMap;
     }
     let data = CSVUtils.formatToCSVData(listingsInGrid, header);

    // let data = JSONUtils.formatToJSONData(listingsInGrid, keyToJSONPathMap);
    let blob = new Blob([JSON.stringify(data, null, 4)], { type: 'application/json' });
    this.fileSaver.save(blob, "vmware_ports.json");

  }
  exportToXLSX() {
    let listingsInGrid = this.datagrid.first.items["_filtered"]
    let heading = {
      product: this.transLang['product'],
      releases: this.transLang['releases'],
      port:this.transLang['ports'],  
      protocol: this.transLang['protocols'],
      source: this.transLang['source'],
      destination: this.transLang['destination'],
      serviceDescription: this.transLang['serviceDesc'],
      purpose: this.transLang['purpose'],
      classification:this.transLang['classification']
     }
     let header = null;
     if(localStorage.getItem('pnp-lang')!== 'en'){
      header = CSVUtils.objectFlip(heading);
     } else {
      header = this.headerPropMap;
     }
     let data = CSVUtils.formatToCSVData(listingsInGrid, header);
    this.xlsService.exportAsExcelFile(data, "vmware_ports");

}

  changeSelectStatus(prodId: string, event) {
    let checked = event.target.checked;
    this.showLoader = true;
    this.store.dispatch(new UpdateSelectStatus({ "prodId": prodId, "value": checked }));
  }

  changeSelectStatusFromLogo(prodId: string, selected: boolean, event:MouseEvent) {
    if((<HTMLElement>(<HTMLElement>(<HTMLElement>event.target).parentNode).querySelector("input"))){
      event.preventDefault();
      (<HTMLElement>(<HTMLElement>(<HTMLElement>event.target).parentNode).querySelector("input")).click();
    }
    this.showLoader = true;
    this.store.dispatch(new UpdateSelectStatus({ "prodId": prodId, "value": selected }));
  }

  selectProductsFromURL(products: Product[]) {
    let routeSub = this.route.params.subscribe(params => {
      if (params['id'] != undefined && params['id']!='')  {
        let separator = ",";
        if((<string>params['id']).indexOf("+") != -1){
          separator = "+";
        }
        let productNames: string[] = (<string>params['id']).split(separator);
        let filteredProducts = [];

        //fetch products matching product names sent in URL
        productNames.forEach(element => {
          filteredProducts.push(products.filter(
            (product, index, productsArr) => {
              if(Object.keys(this.aliasProductNames).includes(element)){
                element = this.aliasProductNames[element];
              }
              return (Utils.createdisplayNameURL(product["displayName"]) == element);
            })[0]);
        });

        if (filteredProducts.length > 0) {
          //fetch listings for products selected
          filteredProducts.forEach(element => {
            if (element && element["id"]) {
              this.store.dispatch(new UpdateSelectStatus({ "prodId": element["id"], "value": true}));
            }
            // this.getSelectedProductListings(element);
          });
        }
        else {
          this.getDefaultProduct();
        }
      } else {
        this.getDefaultProduct();
      }
    });
    this.subscriptions.push(routeSub);
  }

  printListings() {
    window.print();
  }


// ***************************************************pdf localization starts here******************************************************

private setupFont() {
  forkJoin(
      [
          this.httpClient.get('assets/fonts/jap-regular.ttf', { responseType: 'text' }),
      ]
  ).subscribe(
      response => {
          this.doc.addFileToVFS("JapaneseNormal.ttf", response[0]);
          this.doc.addFont("JapaneseNormal.ttf", "JapaneseNormal", "normal");
          this.isSetupFontDone = true;
      },
      error => {
          console.log(error);
      }
  )
}

private checkSetup() {
  if ((localStorage.getItem('pnp-lang')=== 'en' || localStorage.getItem('pnp-lang')=== 'ja') && !this.isSetupFontDone) {
      setTimeout(() => {
          return this.checkSetup();
      }, 1000);
  }
  return true;
}
    private init() {
      this.checkSetup();
      if(localStorage.getItem('pnp-lang')=== 'zh'){
          this.doc.setFont("Chinese", 'normal');
          this.doc.setFontSize(30);
      }else if(localStorage.getItem('pnp-lang')=== 'ja'){
          this.doc.setFont("JapaneseNormal", 'normal');
          this.doc.setFontSize(30);
      }else if(localStorage.getItem('pnp-lang')=== 'es'){
          this.doc.setFont("JapaneseNormal", 'normal');
          this.doc.setFontSize(this.defaultBigHeadingSize);
      }
      else{
          this.doc.setFont("helvetica", 'normal');
          this.doc.setFontSize(30);
      }
      this.doc.setTextColor(26, 66, 138);
      this.doc.text(this.transLang['title'], 80, 80);
      this.doc.setFontSize(this.defaultHeadingSize);
      this.doc.setTextColor(63, 63, 63);
      this.doc.text(this.datePipe.transform(new Date(), 'MMMM d, yyyy'), 80, 90);
      // this.addFooter();
  }
  

  downloadAsPDF() {
    this.init();
     let listingsInGrid = this.datagrid.first.items["_filtered"];
        let pdfFieldHeadersMap = {
          product: this.transLang["product"],
          releases: this.transLang["version"],
          source: this.transLang["source"],
          destination: this.transLang["destination"],
          port: this.transLang["ports"],
          protocol: this.transLang["protocols"],
          purpose:  this.transLang["purpose"],
          serviceDescription: this.transLang["serviceDesc"],
          classification: this.transLang["classification"]
        };
        let bodyValues = [];
        listingsInGrid .forEach(element => {
          let valueRow = [];
          for (const key in pdfFieldHeadersMap) {
            if (pdfFieldHeadersMap.hasOwnProperty(key)) {
              let value = element[key];
              if (typeof value == 'object') {
                if (Array.isArray(value)) {
                  value = new JoinPipe().transform(value, 'displayName');
                }
                else {
                  if (value.displayName != undefined) {
                    value = value.displayName;
                  }
                }
              }
              valueRow.push(value);
            }
          }
          bodyValues.push(valueRow);
        });
        let head = [];
        let lang;
        let fontStyle;
        let bodyLang;
        let bodyfontStyle;
        let width;
        if(localStorage.getItem('pnp-lang')=== 'ja'){
          this.doc.setFont("JapaneseNormal", 'normal');
          lang = "JapaneseNormal";
          fontStyle = "bold"
          bodyLang = "JapaneseNormal";
          bodyfontStyle = "normal"
          width = 7;
      }else{
          this.doc.setFont("helvetica", 'bold');
          lang = "helvetica";
          fontStyle = "bold";
          bodyLang = "helvetica";
          bodyfontStyle = "normal"
          width = 17
      }
        head.push(Object.values(pdfFieldHeadersMap));
        var img = new Image()
        img.src = 'assets/pdf-brand1.png'
        this.doc.addImage(img, 'png', 0, 20, 297, 170);
        this.doc.setFontSize(30);
        this.doc.setTextColor(26, 66, 138);
        this.doc.text(this.transLang['title'], 150, 80);
        this.doc.setFontSize(18);
        this.doc.setTextColor(63, 63, 63);
        let datePipe = new DatePipe('en-US');
       this.doc.text(datePipe.transform(new Date(), 'MMMM d, yyyy'), 200, 95);
        this.doc.addPage();
        // let footer = function (data) {
        //   this.doc.setFontSize(10);
        //   this.doc.setFontStyle('normal');
        //   let str = "Page " + this.doc.internal.getNumberOfPages();
        //   console.log(data);
        //   this.doc.text(str, data.settings.margin.left, this.doc.internal.pageSize.height - 5);
        // }
        this.doc.autoTable({
          head: head,
          body: bodyValues,
          headStyles: {
            font: lang,
            fontStyle: fontStyle,
            minCellWidth: width,
        },
          // didDrawPage: footer,
        });
        this.doc.save('vmware_ports.pdf');
  }

  //datagrid filtering manipulations
  filterValues(event, columnName) {
    let closeIcon: HTMLElement = event.target;
    if ((closeIcon.tagName == "CLR-ICON" && closeIcon.parentElement.getAttribute("class") == "close" && this.currentFilterValue) ||
      ((event.keyCode == 13 && !event.shiftKey) && event.target.value != "" && this.currentFilterValue)) {
      switch (columnName) {
        case 'Product':
          if (this.filValues.findIndex(obj => obj.column == "Project Name") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Project Name"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Version':
          if (this.filValues.findIndex(obj => obj.column == "Version") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Version"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Source':
          if (this.filValues.findIndex(obj => obj.column == "Source") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Source"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Destination':
          if (this.filValues.findIndex(obj => obj.column == "Destination") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Destination"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Ports':
          if (this.filValues.findIndex(obj => obj.column == "Ports") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Ports"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Protocols':
          if (this.filValues.findIndex(obj => obj.column == "Protocols") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Protocols"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Purpose':
          if (this.filValues.findIndex(obj => obj.column == "Purpose") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Purpose"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Service Description':
          if (this.filValues.findIndex(obj => obj.column == "Service Description") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Service Description"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
        case 'Classification':
          if (this.filValues.findIndex(obj => obj.column == "Classification") !== -1) {
            this.filValues.splice(this.filValues.findIndex(obj => obj.column == "Classification"), 1, { value: this.currentFilterValue, column: columnName })
            this.currentFilterValue = "";
          } else {
            this.filValues.unshift({ value: this.currentFilterValue, column: columnName });
            this.currentFilterValue = "";
          }
          break;
      }
    }
    else {
      this.currentFilterValue = event.target.value;
    }

  }
  removeSearchValue() {
    this.search = "";
  }
  removeFilVal(filter) {
    if (filter.column == 'Product') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'product.displayName') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Version') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'releases') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Source') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'source') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Destination') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'destination') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Ports') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'port') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Protocols') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'protocol') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Purpose') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'purpose') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Service Description') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'serviceDescription') {
          column.filterValue = ""
        }
      });
    } else if (filter.column == 'Classification') {
      this.filValues.splice(this.filValues.findIndex(obj => obj.value == filter.value), 1);
      this.columns.forEach(column => {
        if (column.field == 'classification') {
          column.filterValue = ""
        }
      });
    }
  }
  //clear filters
  clear() {
    this.filValues = [];
    this.columnFilters.forEach(
      (cFilter) => {
        cFilter["value"] = "";
      }
    )
  }

  reloadApp() {
    location.reload();
  }

  refresh() {
    this.showLoader = true;
    if (this.selectedIds == "") {
      this.store.dispatch(new ClearAllListings());
      this.showLoader = false;
    }
    else {
      let fetchListSub = this.store.dispatch(new FetchListings(this.selectedIds)).subscribe(
        () => {
          this.showLoader = false;
        }
      );
      this.subscriptions.push(fetchListSub);
    }
  }
  linkClick(event: MouseEvent){
    if((<HTMLElement>(<HTMLElement>event.target).querySelector("input"))){
      event.preventDefault();
      (<HTMLElement>(<HTMLElement>event.target).querySelector("input")).click();
    }
  }
}
