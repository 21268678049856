import { HttpClient } from '@angular/common/http';
import { StateContext, Selector, Action, State } from '@ngxs/store';
import { FetchRMReleasesMatching, ClearRMReleasesMatching } from '../actions/rmreleases.action';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { EnvironmentsService } from '../services/environment.service';

export class RMReleaseStateModel {
    matchedRMReleases?: [];
}

@State<RMReleaseStateModel>({
    name: 'rmreleases',
    defaults: {
        matchedRMReleases: null
    }
})

export class RMReleaseState {

    rmMatchReq: Observable<any>;
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(private http: HttpClient, private envService: EnvironmentsService) { }
    ngxsOnInit(ctx: StateContext<RMReleaseStateModel>) {
        //console.log('State initialized, now getting Release details from API');
    }
    @Selector()
    static getMatchedRMreleases(state: RMReleaseStateModel) {
        return state.matchedRMReleases;
    }


    @Action(FetchRMReleasesMatching)
    fetchRMReleasesMatching({ patchState }: StateContext<RMReleaseStateModel>, {payload,productName}: any) {
        if(this.rmMatchReq){
            this.ngUnsubscribe.next();
        }
         this.rmMatchReq = this.http.get(this.envService.config.api + 'manage/v1/releasemanager/rmproducts/releases?releasedInYears=2&pnprel=false&rmproductids=' + payload +'&rmproductname=' + productName)
         .pipe( takeUntil(this.ngUnsubscribe) )
         .pipe(
            tap((data: any) => {
                patchState({
                    matchedRMReleases: data["objects"]
                })
            }),
            catchError(error => { console.error('could not fetch matchin rm products'); return new Observable<String>(); })
        );
        return this.rmMatchReq ;
    }
    @Action(ClearRMReleasesMatching)
    clearRMReleasesMatching({ patchState }: StateContext<RMReleaseStateModel>) {
        patchState({
            matchedRMReleases: null
        });
    }
}
