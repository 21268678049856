import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { ClarityModule } from "@clr/angular";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FileSaverModule } from 'ngx-filesaver';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewComponent } from './components/view/view.component';
import { ManageComponent } from './components/manage/manage.component';
import { AdminComponent } from './components/admin/admin.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { UsersComponent } from './components/users/users.component';

import { ProductState } from './state/product.state';
import { ProductsComponent } from './components/products/products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpClientModule, HttpClient, } from '@angular/common/http';
import { ListingsState } from './state/listing.state';
import { JoinPipe } from './pipes/join.pipe';
import { AppState } from './state/app.state';
import { DropdownDirective } from './directives/dropdown.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { IdFilterPipe } from './pipes/id-filter.pipe';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ReleasesComponent } from './components/releases/releases.component';
import { InputsState } from './state/inputs.state';
import { AuthZService } from './services/auth-z.service';
import { AuthService } from './services/auth.service';
import { HttpModule } from '@angular/http';
import { ReleaseState } from './state/release.state';
import { PapaParseModule } from 'ngx-papaparse';
import { UsersState } from './state/users.state';
import { AuthComponent } from './components/auth/auth.component';
import { NewListingsComponent } from './components/new-listings/new-listings.component';
import { MyErrorHandler } from './services/error-handler.service';
import { EditListingsComponent } from './components/edit-listings/edit-listings.component';
import { UserSubmissionsComponent } from './components/user-submissions/user-submissions.component';
import { PublishStatusDirective } from './directives/publish-status.directive';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { JodaDateFilter } from './pipes/joda-date.pipe';
import { ReviewSubmissionsComponent } from './components/review-submissions/review-submissions.component';
import { AuthGuard } from './services/auth.guard';
import { RMReleaseState } from './state/rmreleases.state';
import { CommentsState } from './state/comments.state';
import { FeedbackState } from './state/feedback.state';
import { CanDeactivateGuard } from './services/deactivate.guard';
import { LogoLabelPipe } from './pipes/logo-label.pipe';
import { ExcelService } from './services/xlsx-helper.service';
import { CookieService } from 'ngx-cookie-service';
import { NameURLPipe } from './pipes/name-url.pipe';
import { ProductsSortPipe } from './pipes/sort-products.pipe';
import { DragulaModule } from 'ng2-dragula';
import { UpdateNetdiagramComponent } from './components/update-netdiagram/update-netdiagram.component';
import { ViewNetworkdiagramsComponent } from './components/view-networkdiagrams/view-networkdiagrams.component';
import { TagsFilterPipe } from './pipes/network-diagram-tags.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { RelTagsSetFilterPipe } from './pipes/rel-tags-set-filter.pipe';
import { KeywordFilterPipe } from './pipes/network-diagram-keyword.pipe';
import { LoginComponent } from './components/login/login.component';

import {TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.stage';
import { AuthInterceptor } from "./services/auth.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    ViewComponent,
    ManageComponent,
    AdminComponent,
    ReleaseNotesComponent,
    ContactUsComponent,
    UsersComponent,
    ProductsComponent,
    JoinPipe,
    DropdownDirective,
    FilterPipe,
    IdFilterPipe,
    LogoLabelPipe,
    ProductsSortPipe,
    JodaDateFilter,
    ReleasesComponent,
    AuthComponent,
    NewListingsComponent,
    EditListingsComponent,
    UserSubmissionsComponent,
    PublishStatusDirective,
    ReviewSubmissionsComponent,
    NameURLPipe,
    UpdateNetdiagramComponent,
    ViewNetworkdiagramsComponent,
    TagsFilterPipe,
    SplitPipe,
    RelTagsSetFilterPipe,
    KeywordFilterPipe,
    LoginComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    AppRoutingModule,
    ClarityModule,
    NgxsModule.forRoot([
      ProductState,
      ListingsState,
      AppState,
      InputsState,
      ReleaseState,
      UsersState,
      RMReleaseState,
      CommentsState,
      FeedbackState
    ], { developmentMode: true }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled : true , collapsed : true}),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: []
      }
    },),
    NgxsFormPluginModule.forRoot(),
    PapaParseModule,
    FileSaverModule,
    NguiAutoCompleteModule,
    FlexLayoutModule,
    DragulaModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js')
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useClass: MyErrorHandler
    // },
    AuthInterceptor,
    MyErrorHandler,
    AuthZService,
    AuthService,
    AuthGuard,
    CanDeactivateGuard,
    ExcelService,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem('auth-obj') && JSON.parse(localStorage.getItem('auth-obj'))["access_token"];
}
export function jwtOptionsFactory(tokenService) {
  return {
    tokenGetter: tokenGetter,
    headerName: 'x-vmw-esp-client',
    authScheme: '',
    whitelistedDomains: [RegExp(".*")],
    blacklistedRoutes: [RegExp("\/*view\/"), RegExp("\/*manage/v1/feedbacks$"), RegExp("\/admin/v1/auth/login$"), RegExp("\/admin/v1/auth/token$"), RegExp("\/manage/v1/supportedvalues")]
  }
}
export function createTranslateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.localizationFilesPath, '');
}



String.prototype["format"] = function() {
  var args = arguments;
  return this.replace(/{(\d+)}/g, function(match, number) {
    return typeof args[number] != 'undefined'
      ? args[number]
      : match
    ;
  });
};
