import { AppAlert } from './../models/app.model';
import { ProductsSortPipe } from './../pipes/sort-products.pipe';
import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap, takeUntil } from 'rxjs/operators';

import {
    AddNewProduct,
    ClearProductForm,
    DeleteProduct,
    FetchCurrentUserProducts,
    FetchProducts,
    FetchRMProductsMatching,
    UpdateSelectedInputProduct,
    UpdateSelectStatus,
    UpdateSelectedInputProductForEdit,
    FetchAllProducts,
    FetchProductsForHome,
    UpdateSortOrderForProducts,
    ClearAppAlert,
    FetchProductsForNetworkDiagrams,
} from '../actions/product.actions';
import { Utils } from '../lib/utils';
import { Product, SelectStatus } from '../models/product.model';
import { AppError } from '../models/app-error.model';
import { MyErrorHandler } from '../services/error-handler.service';
import { EnvironmentsService } from '../services/environment.service';

class StateForm {
    model: any = undefined;
    dirty: boolean = false;
    status: string = "";
    errors: any = {};
}

export class ProductStateModel {
    //published products
    selectedRouteValue: string;
    products: Product[];
    productsForHome: Product[];
    productsForNetworkDiagrams: Product[];
    // published and unpublished
    allProducts: Product[];
    defaultProduct: string;
    selectStatus: SelectStatus[];
    selectedInputProduct: Product;
    selectedInputProductForEdit: Product;
    userProducts: Product[];
    productsForm: StateForm;
    matchedRMProducts?: Array<any>;
    appAlert ?: AppAlert;
    productsNewOrder ?: Array<Product>;
}


@State<ProductStateModel>({
    name: 'products',
    defaults: {
        selectedRouteValue: '',
        products: [],
        allProducts: [],
        defaultProduct: '',
        selectStatus: [],
        selectedInputProduct: null,
        selectedInputProductForEdit: null,
        userProducts: null,
        productsForm: new StateForm(),
        productsForHome: [],
        productsForNetworkDiagrams:[],
        appAlert : undefined,
        productsNewOrder :  undefined
    }
})
export class ProductState {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    rmMatchReq: Observable<any>;

    constructor(private http: HttpClient, private location: LocationStrategy,
        private erroHandler: MyErrorHandler, private envService: EnvironmentsService) { }
    ngxsOnInit(ctx: StateContext<ProductStateModel>) {
        //console.log('State initialized, now getting Product details from API');
    }

    @Selector()
    static getAppAlert(state: ProductStateModel) {
        return state.appAlert;
    }

    @Selector()
    static getRouteValue(state: ProductStateModel) {
        return state.selectedRouteValue;
    }

    @Selector()
    static getProducts(state: ProductStateModel) {
        return state.products;
    }
    @Selector()
    static getAllProducts(state: ProductStateModel) {
        return state.allProducts;
    }
    @Selector()
    static getProductsForHome(state: ProductStateModel) {
        return state.productsForHome;
    }
    @Selector()
    static getProductsForNetworkDiagrams(state: ProductStateModel) {
        return state.productsForNetworkDiagrams;
    }
    @Selector()
    static getDefaultProduct(state: ProductStateModel) {
        return state.defaultProduct;
    }
    @Selector()
    static getSelectStatus(state: ProductStateModel) {
        return state.selectStatus;
    }

    @Selector()
    static getSelectInputProduct(state: ProductStateModel) {
        return state.selectedInputProduct;
    }

    @Selector()
    static getSelectedInputProductForEdit(state: ProductStateModel) {
        return state.selectedInputProductForEdit;
    }

    @Selector()
    static getUserProducts(state: ProductStateModel) {
        return state.userProducts;
    }

    @Selector()
    static getProductsForm(state: ProductStateModel) {
        return state.productsForm;
    }

    @Selector()
    static getMatchedRMproducts(state: ProductStateModel) {
        return state.matchedRMProducts;
    }

    @Action(FetchCurrentUserProducts)
    addUserProducts({ getState, patchState }: StateContext<ProductStateModel>) {
        return this.http.get(this.envService.config.api + "manage/v1/userproducts").pipe(
            tap((data: Product[]) => {
                const state = getState();
                let selectedInputProduct = state.selectedInputProduct != null ? state.selectedInputProduct : data[0];
                let selectedInputProductForEdit = state.selectedInputProduct != null ? state.selectedInputProductForEdit : data[0];

                patchState({
                    userProducts: data,
                    selectedInputProduct: selectedInputProduct,
                    selectedInputProductForEdit: selectedInputProductForEdit
                })

            }), catchError(error => { this.erroHandler.sendMessage(new AppError('could not fetch user products', 'manage')); console.error('could not fetch user products'); return new Observable<String>(); })
        );
    }

    @Action(AddNewProduct)
    add({ getState, patchState }: StateContext<ProductStateModel>, { payload }: AddNewProduct) {
        const state = getState();
        return this.http.post(this.envService.config.api + 'admin/v1/vmwareproducts', payload).pipe(
            tap((newProduct: Product) => {
                const state = getState();
                patchState({
                    products: [...state.products, newProduct]
                })
            }),
            catchError(error => { console.error('could not add product'); return new Observable<String>(); })
        );
    }

    @Action(DeleteProduct)
    remove(ctx: StateContext<ProductStateModel>, { payload }: DeleteProduct) {
        //return this.http.delete(environment.api + 'admin/v1/vmwareproducts/' + payload + "?forceDelete=true").pipe(
        return this.http.delete(this.envService.config.api + 'admin/v1/vmwareproducts/' + payload).pipe(
            tap(() => {
                console.log("Product Deleted!!");
            }),
            catchError(error => {
                if (error && error.status && error.status == 409) {
                    if (error.error && error.error.message) {
                        this.erroHandler.sendMessage(new AppError(error.error.message, "admin"));
                    }
                    else {
                        this.erroHandler.sendMessage(new AppError("Conflict in the operation!", "admin"));
                    }
                }
                //this.erroHandler.sendMessage(new AppError("No products to show", "view"));
                console.error('could not delete product');
                return throwError(error);
            })
        );

    }

    @Action(FetchProductsForHome)
    fetchProductsForHome({ patchState, getState }: StateContext<ProductStateModel>) {
        return this.http.get(
            this.envService.config.api + 'manage/view/v1/vmwareproducts/?empty=false',
            {
                headers : {
                    "X-ClientId" : this.envService.config.clientId
                }
            }
        ).pipe(
            tap((data: Product[]) => {
                let store = getState();
                let statusData = data as SelectStatus[];
                // if (store.selectStatus.length != 0 ) {
                //     statusData = store.selectStatus;
                // }
                store.selectStatus.forEach(element => {
                    if (element.selected) {
                        for (let index = 0; index < statusData.length; index++) {
                            if (element.id == statusData[index]["id"]) {
                                statusData[index]["selected"] = true;
                            }
                        }
                    }
                });
                if (data.length <= 0) {
                    patchState({
                        productsForHome: []
                    })
                    this.erroHandler.sendMessage(new AppError("No products to show", "view"));
                    return;
                    //throw new AppError("No products to show");
                }

                let defaultProd = data[0] ? data[0].id : "";
                if(Array.isArray(data) && data.length > 0){
                    defaultProd = new ProductsSortPipe().transform(data)[0]["id"];
                }
                patchState({
                    productsForHome: data,
                    defaultProduct: defaultProd,
                    selectStatus: statusData
                })
            })
            ,
            catchError(error => {
                //this.handleException('fetchProducts', error);
                this.erroHandler.sendMessage(error);
                return new Observable<String>();
            })

        );
    }

    @Action(FetchProductsForNetworkDiagrams)
    fetchProductsForNetworkDiagrams({ patchState, getState }: StateContext<ProductStateModel>) {
        return this.http.get(
            this.envService.config.api + 'manage/view/v1/networkdiagrams/vmwareproducts?releases=false',
            {
                headers : {
                    "X-ClientId" : this.envService.config.clientId
                }
            }
        ).pipe(
            tap((data: Product[]) => {
                if (data.length <= 0) {
                    patchState({
                        productsForNetworkDiagrams: []
                    })
                    this.erroHandler.sendMessage(new AppError("No products to show", "network diagrams"));
                    return;
                    //throw new AppError("No products to show");
                }
                patchState({
                    productsForNetworkDiagrams: data,
                })
            })
            ,
            catchError(error => {
                //this.handleException('fetchProducts', error);
                this.erroHandler.sendMessage(error);
                return new Observable<String>();
            })

        );
    }

    @Action(FetchProducts)
    fetchProducts({ patchState, getState }: StateContext<ProductStateModel>) {
        return this.http.get(
            this.envService.config.api + 'manage/view/v1/vmwareproducts',
            {
                headers : {
                    "X-ClientId" : this.envService.config.clientId
                }
            }
        ).pipe(
            tap((data: Product[]) => {
                if (data.length <= 0) {
                    patchState({
                        products: []
                    })
                    this.erroHandler.sendMessage(new AppError("No products to show", "view"));
                    return;
                    //throw new AppError("No products to show");
                }

                let defaultProd = data[0] ? data[0].id : "";
                if(Array.isArray(data) && data.length > 0){
                    defaultProd = new ProductsSortPipe().transform(data)[0]["id"];
                }
                patchState({
                    products: data,
                    defaultProduct: defaultProd,
                })
            })
            ,
            catchError(error => {
                //this.handleException('fetchProducts', error);
                this.erroHandler.sendMessage(error);
                return new Observable<String>();
            })

        );
    }

    @Action(FetchAllProducts)
    fetchAllProducts({ patchState, getState }: StateContext<ProductStateModel>) {
        return this.http.get(this.envService.config.api + 'manage/v1/vmwareproducts').pipe(
            tap((data: Product[]) => {
                if (data) {
                    patchState({
                        allProducts: data
                    });
                }
            })
            ,
            catchError(error => {
                this.erroHandler.sendMessage(error);
                return new Observable<String>();
            })

        );
    }

    @Action(UpdateSelectStatus)
    updateSelectStatus(ctx: StateContext<ProductStateModel>, payload: any) {
        const state = ctx.getState();
        let productsStr: string = "";
        //Initilizing - start
        let updatedSelectStatus = new Array<SelectStatus>();
        state.selectStatus.forEach(element => {
            updatedSelectStatus.push(new SelectStatus());
        });
        //Initilizing - end
        updatedSelectStatus = Utils.cloneArray(state.selectStatus, updatedSelectStatus);
        for (let index = 0; index < updatedSelectStatus.length; index++) {
            const element = updatedSelectStatus[index];
            if (updatedSelectStatus[index]["selected"] == undefined) {
                updatedSelectStatus[index]["selected"] = false;
            }
            if (updatedSelectStatus[index]["id"] == payload["payload"]["prodId"]) {
                updatedSelectStatus[index]["selected"] = payload["payload"]["value"] != undefined ? payload["payload"]["value"] : true;
            }
            if (updatedSelectStatus[index]["selected"]) {
                productsStr += updatedSelectStatus[index]["displayName"].replace(/\s/g, "-").replace("/", "_") + "+";
            }
        }
        ctx.patchState({
            selectStatus: updatedSelectStatus,
            selectedRouteValue: productsStr
        })
        productsStr = productsStr.substr(0, productsStr.length - 1);
        this.location.pushState({}, productsStr +"- VMware Ports and Protocols", "/home/" + productsStr+getLocaleParam(), "");
    }

    @Action(UpdateSelectedInputProduct)
    updateSelectedInputProduct({ patchState }: StateContext<ProductStateModel>, payload: string) {
        patchState(
            {
                selectedInputProduct: { id: payload["payload"] }
            }
        );
    }

    @Action(UpdateSelectedInputProductForEdit)
    updateSelectedInputProductForEdit({ patchState }: StateContext<ProductStateModel>, payload: string) {
        patchState(
            {
                selectedInputProductForEdit: { id: payload["payload"] }
            }
        );
    }

    @Action(FetchRMProductsMatching)
    fetchRMProductsMatching({ patchState }: StateContext<ProductStateModel>, payload) {
        if (this.rmMatchReq) {
            this.ngUnsubscribe.next();
        }
        if (payload["payload"]) {
            this.rmMatchReq = this.http.get(this.envService.config.api + 'manage/v1/releasemanager/rmproducts/?pnpprd=false&matchPattern=' + payload["payload"])
                .pipe(takeUntil(this.ngUnsubscribe))
                .pipe(
                    tap((data: any) => {
                        patchState({
                            matchedRMProducts: data
                        })
                    }),
                    catchError(
                        error => {
                            console.error('could not fetch matchin rm products');
                            return new Observable<String>();
                        })
                );
        }
        return this.rmMatchReq;

    }

    @Action(ClearProductForm)
    clearProductForm(ctx: StateContext<ProductStateModel>) {
        ctx.patchState({
            productsForm: new StateForm()
        })
    }

    @Action(UpdateSortOrderForProducts)
    updateSortOrderForProducts({ patchState }: StateContext<ProductStateModel>, { payload }) {
        return this.http.put(this.envService.config.api + 'admin/v1/vmwareproducts/sortOrder', payload).pipe(
            tap((products: Array<Product>) => {
                console.log(products);
                patchState({
                    appAlert : {
                        message: "Product order updated successfully!",
                        timeout: 5000,
                        type: 'success',
                        icon: 'success-standard'
                    },
                    productsNewOrder : products
                })
                setTimeout(() => {
                    patchState({
                        appAlert : undefined
                    })
                }, 5000);
            }),
            catchError(error => { console.error('could not update sort order for products'); return new Observable<String>(); })
        );
    }

    @Action(ClearAppAlert)
    clearAppAlert({ patchState }: StateContext<ProductStateModel>) {
        patchState(
            {
                appAlert: undefined
            }
        )
    }
}
export function getLocaleParam(){
    if(localStorage.getItem("pnp-lang") && localStorage.getItem("pnp-lang")!="en"){
        return "?locale="+localStorage.getItem("pnp-lang");
    }
    return "";
}