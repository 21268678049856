import { Pipe, PipeTransform } from '@angular/core';
import { Listing } from '../models/listing.model';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    let searchTerms = searchText.split(/\s/);
    // TODO releases array and product name are not searched
    if (items[0] && items[0]['id']) {
      return items.filter((data:Listing )=> {
        let returnVal: boolean = false;
        for (let index = 0; index < searchTerms.length; index++) {
          const term = searchTerms[index];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              if(typeof element == "string"){
                returnVal = returnVal || ( ( element != undefined && element != null) && element.toLowerCase().indexOf(term) >= 0);
              }
              if(typeof element == "object" && element.displayName && typeof element.displayName == "string"){
                let str =element.displayName;
                returnVal = returnVal || ( ( str != undefined && str != null) && str.toLowerCase().indexOf(term) >= 0);
              }
              if(typeof element == "object" && Array.isArray(element) && element.length>0 && element[0].displayName && typeof element[0].displayName == "string"){
                let str = "";
                for (let index = 0; index < element.length; index++) {
                  str += element[index].displayName + ",";
                }
                str = str.substr(0, str.length-1);
                returnVal = returnVal || ( ( str != undefined && str != null) && str.toLowerCase().indexOf(term) >= 0);
              }
            }
          }
        }
        return returnVal;
      });
    } else {
      if (!items) return [];
      if (!searchText) return items;
      searchText = searchText.toLowerCase();
      return items.filter(it => {
        if(it)
          return it.toLowerCase().includes(searchText);
        else
          false;
      });
    }
  }
}
