// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  trackingId : "UA-136336878-4",
  api : "https://ports.esp-stg.svespg1.vmw.saas.broadcom.com/",
  ssoURL : "https://myvmware-stg.workspaceair.com/SAAS/API/1.0/GET/apps/launch/app/aaa20196-48b0-4c54-b9aa-b1ba7ae3939f",
  clientId : "aed572517d2db4d2bb7fc504796972f4",
  ipfURL: "feedback.esp-staging.vmware-aws.com",
  state: "t3jbMJNi",
//   authApiUrl: "https://auth.esp-staging.vmware-aws.com/api/auth/v1",
  authApiUrl: "https://broadcom.okta.com/oauth2/default/v1",
  oktaClientId: "0oax4jp5vew1IRP8U2p7",
  oktaClientPassword: "7fcd38Qf4wBM_GJb1SHW5JraDyNPbEeSHW3Jnlsa4FpmSEG2ZJqAqNFMV6CIDCC7",
  oktaCodeChallenge: "2LWVsgxqjLD71xG1XpIxz7NRtloU0OsuvYGPJUvvlxU",
  oktaCodeVerifier: "wlikgZytmlN7lppH-S86QBvogvIHmJzChPbK4qb4IYgny84H2AgdMOUvd5Pa",
  espClientId: "pnp-client",
  redirectUri: "/auth",
  empAdUrl: "https://employee-ad.esp-staging.eng.vmware.com/",
  whitneyUrl: "https://ports.esp-stg.svespg1.vmw.saas.broadcom.com",
  localizationFilesPath: "https://ports.esp-stg.svespg1.vmw.saas.broadcom.com/manage/v1/supportedvalues?locale="
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
