import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Subject } from 'rxjs';
import { JwtHelperService} from '@auth0/angular-jwt';
import { AuthPayload } from '../models/authPayload.model';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

  private authNotice = new Subject<AuthPayload>();
  public authNotice$ = this.authNotice.asObservable();
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public errorCode: number;
  public errorMessage:string;
  public ssoURL: string;
  constructor(private http: Http, private router: Router) { }

  authNotify(token: string) {
    if (token == "") {
      this.authNotice.next(new AuthPayload());
    }
    else if (token != null && token != undefined) {
      try {
        let decodedToken: AuthPayload = this.jwtHelper.decodeToken(token);
        this.authNotice.next(decodedToken);
      }
      catch (e) {
        this.authNotify("");
      }

    }
  }
  loggedIn() {
    let returnVal: boolean;
    try {
      returnVal = ! ( this.jwtHelper.isTokenExpired(this.tokenGetter()) );
    }
    catch (e) {
      console.log(e);
      returnVal = false;
    }
    return returnVal;
  }

  getRole(token = undefined): string {
    //fetch authorization data for a given token
    if (!token) {
      token = localStorage.getItem("token");
    }
    if (token != null && token != undefined && token != "" && token != "null" && token != "undefined" && token != " ") {
      try {
        if(this.jwtHelper.isTokenExpired(token)){
          localStorage.removeItem("token");
          return "GUEST_USER";
        }
        else{
          let decodedToken: AuthPayload = this.jwtHelper.decodeToken(token);
          if(decodedToken["rol"]){
            return decodedToken["rol"];
          }
          else{
            let session = localStorage.getItem("sessionUser");
            if(session){
              return session["role"];
            }
          }
        }
      }
      catch (e) {
        console.log("redirecting role home");
        this.router.navigate(['/']);
      }
    }
    return "GUEST_USER";

  }

  tokenGetter(): string {
    return localStorage.getItem('auth-obj') && JSON.parse(localStorage.getItem('auth-obj'))?JSON.parse(localStorage.getItem('auth-obj'))["access_token"]:null;
  }

  refreshTokenGetter(): string {
    return localStorage.getItem('auth-obj') && JSON.parse(localStorage.getItem('auth-obj'))?JSON.parse(localStorage.getItem('auth-obj'))["refresh_token"]:null;
  }

  getUserSesion(): string {
    return localStorage.getItem("sessionUser");
  }
}



//TODO - isTokenExpired use to check expiriy beofre firing


