import { Product } from '../models/product.model';

// import { Listing } from 'src/app/models/listing.model';

export class FetchListings{
    static readonly type = '[LISTING] Get Listings';

    constructor(public payload: string) {}

}

export class FetchDrafts{
    static readonly type = '[DRAFTS] Fetch Draft Listings';

    constructor(public payload: string) {}
}
export class DeleteListings{
    static readonly type = '[DELETE LISTING] DELETE Listings';

    constructor(public payload: any) {}
}

export class FetchListingsForEdit{
    static readonly type = '[FETCH LISTING FOR EDIT] Fetch Listings for edit';
    constructor(public payload: string) {}
}

export class FetchCurrenUserSubmissions{
    static readonly type = '[FETCH CURRENT USER SUBMISSIONS] Fetch current user submissions/ listings';
    constructor() {}
}

export class FetchSubmissionsForReview{
    static readonly type = '[FETCH SUBMISSIONS FOR REVIEW] Fetch submissions/ listings for review';
    constructor(public payload : any) {}
}

export class ClearAllListings{
    static readonly type = '[CLEAR ALL LISTINGS IN VIEW] Clear all listings from view';
    constructor() {}
}

export class ClearListingsForEdit{
    static readonly type = '[CLEAR LISTINGS FOR EDIT] Clear listings for edit';
}

export class ClearSubmissionsForReview{
    static readonly type = '[CLEAR SUBMISSIONS FOR REVIEW] Clear submissions/ listings for review';
    constructor() {}
}
