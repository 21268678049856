import { ProductsSortPipe } from './../../pipes/sort-products.pipe';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClrDatagridStringFilterInterface } from '@clr/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AddNewProduct, DeleteProduct, FetchProducts, FetchRMProductsMatching, ClearProductForm, FetchAllProducts, UpdateSortOrderForProducts } from 'src/app/actions/product.actions';
import { Utils } from 'src/app/lib/utils';
import { Product } from 'src/app/models/product.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { ProductState } from 'src/app/state/product.state';
import { trigger, transition, query, style, animate, stagger, keyframes } from '@angular/animations';
import { LocationStrategy, AsyncPipe } from '@angular/common';
import { Papa } from 'ngx-papaparse';
import { FileSaverService } from 'ngx-filesaver';
import { CSVUtils } from 'src/app/utils/csv-utils';
import { WhitneyService } from 'src/app/services/whitney.service';

import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'products-list',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations:
    [
      trigger('listAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),

          query('clr-dg-row', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ])
    ]
})


export class ProductsComponent implements OnInit, OnDestroy {
  appLabels: any;
  transLang: any;
  matchedRMproducts: Array<any> = [];
  productFilter = new DataStringFilter();
  timer: any;
  rowSelected = [];
  showDeleteConfirm: boolean = false;
  showSuggestions: boolean = false;
  loader: boolean = true;
  hideProductsForm: boolean = true;
  formSubmitted: boolean = false;
  hideGrid: boolean = false;
  hideProductOrdering: boolean = true;
  //numberOfCalls: number = 0;
  disableConfirm: boolean = false;
  newOrderProducts = [];
  unOrderedProducts = [];
  readonly autoCompleteListLimit = 5;
  @Select(ProductState.getAllProducts) products$: Observable<Product[]>;
  @Select(ProductState.getMatchedRMproducts) matchedRMProducts$: Observable<any>;

  static headerPropertyMap: any = {
    'Product Name': 'productName',
    'Display Name': 'displayName',
    'Release master product ids': 'rmProductIds'
  }



  productsForm = this.formBuilder.group({
    product: this.formBuilder.group({
      productName: ['', Validators.required],
      displayName: ['', Validators.required],
      //publishDate: ['', Validators.required],
      logo: [''],
      rmProductId: ['', Validators.required]
    })
  });
  subscriptions: Array<Subscription> = [];
  ProductsComponentfileSaver: any;

  get product(): FormGroup {
    return <FormGroup>this.productsForm.get("product");
  }

  constructor(private store: Store, private formBuilder: FormBuilder,
    private appStringsService: AppStringsService, private location: LocationStrategy, private papa: Papa,
    private _ref: ChangeDetectorRef, private fileSaver: FileSaverService, private whitneyService: WhitneyService
          ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
  ngOnInit() {
    this.appLabels = this.appStringsService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title + " - Products", "/admin/products", "");
    this.store.dispatch(new FetchAllProducts());
    let prodSub = this.products$.subscribe(
      (productsData) => {
        if (productsData != null && productsData != undefined && this.loader == true) {
          this.loader = false;
        }
      }
    );
    this.subscriptions.push(prodSub);
    let rmProdSub = this.matchedRMProducts$.subscribe(
      (rmProducts: any) => {
        if (rmProducts) {
          this.showSuggestions = false;
          // this.numberOfCalls--;
          // if (this.numberOfCalls == 0) {
          //   this.showSuggestions = false;
          // }
          //this.matchedRMproducts = rmProducts.objects.slice(0, 4);
          this.matchedRMproducts = rmProducts.objects;
        }
      }
    );
    this.subscriptions.push(rmProdSub);
  }

  addProduct() {
    this.resetForm();
    this.store.dispatch(new ClearProductForm());
    this.hideProductsForm = false;
    this.hideGrid = true;
    this.matchedRMproducts = [];
  }

  isValidFile(file) {
    let validity = false;
    if (file) {
      if (file.type) {
        if (file.type.split("/")[0] == "image") {
          validity = true;
        }
        else {
          window.alert("Only files of image type are allowed.")
        }
      }
      if (validity && file.size) {
        if (file.size > 1024 * 50) {
          window.alert("Size of file is more than 50KB. Please choose another file.");
          validity = false;
        }
      }
    }
    return validity;
  }

  updateLogoFile(event) {
    let file = event.target.files[0];
    if (this.isValidFile(file)) {
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        window["selectedLogoFile"] = reader.result;
      }, false);
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    else {
      event.target.value = '';
    }
  }

  addNewProduct() {
    this.formSubmitted = true;
    let product: Product = new Product();
    product = Utils.clone(this.product.value, product);
    product.logo = window["selectedLogoFile"];
    let rmProductIds = []
    rmProductIds.push(product.rmProductId);
    product.rmProductIds = rmProductIds;
    //product.publishDate = new Date(this.product.value.publishDate).toISOString().split("T")[0] + " 00:00";
    let newProdSub = this.store.dispatch(new AddNewProduct(product)).subscribe(
      () => {
        this.resetForm();
        this.store.dispatch(new ClearProductForm());
        this.hideProductsForm = true;
        this.hideGrid = false;
        setTimeout(() => {
          //let the checkmark animation run for 2seconds
          this.formSubmitted = false;
        }, 2000);
        this.store.dispatch(new FetchAllProducts());
      });
       this.subscriptions.push(newProdSub);
  }

  resetForm() {
    this.productsForm.reset();
    this.showSuggestions = false;
    this.store.dispatch(new this.fetchMatchingProducts(undefined));
    this.matchedRMproducts = [];
  }

  deleteProduct(force = false) {
    if (!force) {
      this.showDeleteConfirm = true;
      return;
    }

    let deletionObjs = [];
    this.rowSelected.forEach(element => {
      deletionObjs.push(new DeleteProduct(element.id));
    });
    let deleteSub = this.store.dispatch(deletionObjs).subscribe(
      () => {
        this.showDeleteConfirm = false;
        this.disableConfirm = false;
        this.reloadApp();
      },
    );
    this.subscriptions.push(deleteSub);
  }

  editProduct() {

  }

  fetchMatchingProducts(event) {
    if (event) {
      let enteredText: string = event.target.value;
      if (enteredText.length > 3) {
        //this.numberOfCalls++;
        this.showSuggestions = true;
        this.store.dispatch(new FetchRMProductsMatching(enteredText));
      }
    }
  }

  updateRMId(event) {
    let enteredValue = event.target.value;
    this.matchedRMproducts.forEach(element => {
      if (element.name.trim() == enteredValue.trim()) {
        this.product.get("rmProductId").setValue(element.id);
        this.product.get("displayName").setValue(element.name);
        this.showSuggestions = false;
      }
    });
  }
  reloadApp() {
    location.reload();
  }

  showProductOrder() {
    let allProducts = new ProductsSortPipe().transform(new AsyncPipe(this._ref).transform(this.products$));
    this.newOrderProducts = allProducts.filter(ele => { return ele.sortOrder; }).sort((a, b) => { return a.sortOrder - b.sortOrder });
    this.unOrderedProducts = allProducts.filter(ele => { return !ele.sortOrder; });
    this.hideProductOrdering = false;
    this.hideGrid = true;
    this.hideProductsForm = true;
  }

  saveOrder() {
    let reqObj = [];
    this.newOrderProducts.forEach(
      (ele, index) => {
        ele = JSON.parse(JSON.stringify(ele));
        ele["sortOrder"] = index + 1;
        delete ele["publishDate"];
        reqObj.push(ele);
      }
    )
    this.store.dispatch(new UpdateSortOrderForProducts(reqObj)).subscribe(
      (data) => {
        this.store.dispatch(new FetchAllProducts()).subscribe(
          () => {
            this.showProductOrder();
          }
        )
      }
    )
  }

  cancelOrder() {
    this.newOrderProducts = [];
    this.unOrderedProducts = [];
    this.hideProductOrdering = true;
    this.hideGrid = false;
    this.hideProductsForm = true;
  }

  exportToCSV() {
    const productForm: FormArray = new FormArray([]);
    this.products$.subscribe((productsData) => {
      productsData.forEach(productData => {
        productForm.push(this.formBuilder.group({
          productName: [productData.productName],
          displayName: [productData.displayName],
          rmProductIds: [productData.rmProductIds]
        }));
      });
    });

    let data = CSVUtils.formatToCSVData(productForm.value, ProductsComponent.headerPropertyMap);
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers:[
        this.transLang['productName'],
        this.transLang['displayName'],
        this.transLang['rmProductId'],  
   ]
    }
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(data);
  }
}

class DataStringFilter implements ClrDatagridStringFilterInterface<Product> {
  accepts(product: Product, search: string): boolean {
    return "" + product.displayName == search
      || product.displayName.toLowerCase().indexOf(search) >= 0;
  }
}
