import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY, Observable } from 'rxjs';

import { UpdateToken, UpdateUserDetails, UpdateViewSearch, UpdateSelectedTabInManage,CreateNetworkDiagram, FetchNetworkDiagramMeta, ClearNetworkDiagramMeta, DeleteNetworkDiagram, FetchSessionUser, LogoutUser, FetchNetworkDiagramById, UpdateNetworkDiagram } from '../actions/app.actions';
import { App } from '../models/app.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { MyErrorHandler } from '../services/error-handler.service';
import { AppError } from '../models/app-error.model';
import { EnvironmentsService } from '../services/environment.service';

export class AppStateModel {
    appState: App;
    netDiagram: any;
    netDiagramsMeta: any[];
}

@State({
    name: 'appstate',
    defaults: {
        appState: {
            view: {
                searchString: ''
            },
            admin: '',
            manage: {
                selectedTab: 'new-listings'
            },
            user: {

            },
            token: '',
            sessionUser: {}
        },
        netDiagram: undefined,
        netDiagramsMeta : undefined,

    }
})
export class AppState {
    static getNetworkDiagramsMetaById(getNetworkDiagramsMetaById: any) {
      throw new Error('Method not implemented.');
    }

    constructor(private http: HttpClient, private errorHandler: MyErrorHandler, private envService: EnvironmentsService) { }

    @Selector()
    static getAppState(state: AppStateModel) {
        return state.appState;
    }
    @Selector()
    static getCurrentUserToken(state: AppStateModel) {
        return state.appState.token;
    }

    @Selector()
    static getCurrentSessionUser(state: AppStateModel) {
        return state.appState.sessionUser;
    }

    @Selector()
    static getManageState(state: AppStateModel) {
        return state.appState.manage;
    }
    @Selector()
    static getNetworkDiagramsMeta(state: AppStateModel) {
        return state.netDiagramsMeta;
    }


    @Action(UpdateToken)
    updateToken({ patchState, getState }: StateContext<AppStateModel>, payload: string) {
        console.log("toke payload"+payload);
        if (!payload["payload"]) {
            localStorage.removeItem('token');
        }
        localStorage.setItem('token', payload["payload"]);
        patchState({
            appState: { "token": payload["payload"] }
        })
        return EMPTY;
    }

    @Action(FetchSessionUser)
    fetchSessionUser(ctx: StateContext<AppStateModel>, { payload } : any) {
        if (payload){
            return this.http.put(this.envService.config.api + 'admin/v1/auth/login',payload).pipe(tap((result: any) => {
                ctx.patchState({
                    appState: { "sessionUser" : result }
                });
                localStorage.setItem('sessionUser', JSON.stringify(result));
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not add network diagram', 'manage')); return new Observable<String>(); })
            );
        }
    }

    @Action(UpdateViewSearch)
    UpdateViewSearch({ patchState }: StateContext<AppStateModel>, payload: any) {
        let data: App = { "view": { "searchString": payload['payload'] } };
        patchState({
            appState: data
        })
    }

    @Action(UpdateUserDetails)
    updateUserDetails({ patchState }: StateContext<AppStateModel>, payload: any) {
        let data: App = { "user": payload["payload"] };
        patchState({
            appState: data
        })
    }

    @Action(UpdateSelectedTabInManage)
    updateSelectedTabInManage({ patchState, getState }: StateContext<AppStateModel>, payload: string) {
        if (payload["payload"]) {
            patchState({
                appState: { "manage": { selectedTab : payload["payload"]} }
            });
        }
        return EMPTY;
    }

    @Action(CreateNetworkDiagram)
    updateNetworkDiagram(ctx: StateContext<AppStateModel>, { payload} : any) {
        if (payload){
            return this.http.post(this.envService.config.api + 'manage/v1/networkdiagram',payload).pipe(tap((result: any) => {
                ctx.patchState({
                    netDiagram : result
                });
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not add network diagram', 'manage')); return new Observable<String>(); })
            );
        }
    }

    @Action(UpdateNetworkDiagram)
    updateNetDiagram(ctx: StateContext<AppStateModel>, {payload, idForEdit} : any) {
        if (payload){
            return this.http.post(this.envService.config.api +'manage/v1/networkdiagram/'+idForEdit,payload).pipe(tap((result: any) => {
                ctx.patchState({
                    netDiagram : result
                });
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not update network diagram', 'manage')); return new Observable<String>(); })
            );
        }
    }

    @Action(FetchNetworkDiagramById)
    fetchNetworkDiagram(ctx: StateContext<AppStateModel>, { idForEdit } : any) {
        if (idForEdit){
            return this.http.get(this.envService.config.api +'manage/view/v1/networkdiagram/'+ idForEdit +'/meta').pipe(tap((result: any) => {
                ctx.patchState({
                    netDiagram : result
                }); 
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not fetch network diagram by ID', 'view')); return new Observable<String>(); })
            );
        }
    }

    @Action(FetchNetworkDiagramMeta)
    fetchNetworkDiagramMeta(ctx: StateContext<AppStateModel>, { payload } : any) {
        if (payload){
            return this.http.get(this.envService.config.api + 'manage/view/v1/' + payload + '/networkdiagram/meta').pipe(tap((result: any) => {
                ctx.patchState({
                    netDiagramsMeta : result
                }); 
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not fetch network diagram meta', 'view')); return new Observable<String>(); })
            );
        }
    }

    @Action(DeleteNetworkDiagram)
    deleteNetworkDiagram(ctx: StateContext<AppStateModel>, { idForDelete, productId } : any) {
        if (idForDelete && productId){
            return this.http.delete(this.envService.config.api + 'manage/v1/networkdiagram/'+idForDelete).pipe(tap((delResult: any) => {
                ctx.dispatch(new FetchNetworkDiagramMeta(productId));
            }),
                catchError(error => { this.errorHandler.handleError(new AppError('could not fetch network diagram meta', 'view')); return new Observable<String>(); })
            );
        }
    }

    @Action(ClearNetworkDiagramMeta)
    clearNetworkDiagramMeta(ctx: StateContext<AppStateModel>){
        ctx.patchState({
            netDiagramsMeta : undefined
        });
    }

    @Action(LogoutUser)
    logoutUser(){
        return this.http.request('delete',this.envService.config.api + 'admin/v1/auth/token',{
           body: { token : JSON.parse(localStorage.getItem('auth-obj'))["access_token"] }
         }).pipe(tap((result) => {
            localStorage.removeItem("auth-obj");
            localStorage.removeItem("token");
            localStorage.removeItem("sessionUser");
        }))
    }
}