import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AddNewUser, AddNewUserProducts, FetchAllUserProducts, FetchAllUsers, FetchADUsersMatching, ClearUserForm, DeleteUser } from '../actions/users.actions';
import { UserDetails } from '../models/userdetails.model';
import { Utils } from '../lib/utils';
import { ADUser } from '../models/aduser.model';
import { EnvironmentsService } from '../services/environment.service';


class StateForm {
    model: any = undefined;
    dirty: boolean = false;
    status: string = "";
    errors: any = {};
}

class UsersStateModel {
    users: UserDetails[];
    usersForm: StateForm;
    matchedADUsers?: ADUser[];
}

@State<UsersStateModel>({
    name: 'users',
    defaults: {
        users: [
            new UserDetails()
        ],
        usersForm: new StateForm()
    }
})
export class UsersState implements NgxsOnInit {

    constructor(private http: HttpClient, private envService: EnvironmentsService) { }

    //for initial state
    ngxsOnInit(ctx: StateContext<UsersStateModel>) {
       // console.log('State initialized, now getting  users details');
    }

    @Selector()
    static getUsers(state: UsersStateModel) {
        return state.users;
    }

    @Selector()
    static getUsersForm(state: UsersStateModel) {
        return state.usersForm;
    }

    @Selector()
    static getMatchedADUsers(state: UsersStateModel) {
        return state.matchedADUsers;
    }

    @Action(FetchAllUsers)
    fetchUsers(ctx: StateContext<UsersStateModel>) {

        return this.http.get(this.envService.config.api + 'admin/v1/users').pipe(tap((result: UserDetails[]) => {
            ctx.patchState({
                users: result
            });
        }),
            catchError(error => { console.error('could not get users; Error:' + error); return new Observable<String>(); })
        );
    }
    @Action(FetchAllUserProducts)
    fetchUserProducs(ctx: StateContext<UsersStateModel>) {

        return this.http.get(this.envService.config.api + 'admin/v1/userproducts').pipe(tap((result: any[]) => {
            let users = ctx.getState();
            let updatedUsers = new Array<UserDetails>(users["users"].length);
            for (let i = 0; i < users["users"].length; i++) {
                updatedUsers[i] = Utils.clone(users["users"][i], new UserDetails());
                for (let j = 0; j < result.length; j++) {
                    let userId: string = result[j]["user"] ? result[j]["user"]["id"] : "";
                    if(userId == users["users"][i]["id"]){
                        updatedUsers[i]["products"] = result[j]["products"];
                        break;
                    }
                }
            }
            console.log(updatedUsers);
            ctx.patchState({ users: updatedUsers });
        }),
            catchError(error => { console.error('could not get user products; Error:' + error); return new Observable<String>(); })
        );
    }

    @Action(AddNewUser)
    addUser(ctx: StateContext<UsersStateModel>, payload) {
        return this.http.post(this.envService.config.api + 'admin/v1/users', payload["payload"]).pipe(tap((result: UserDetails) => {
            console.log("New User Added!!");
        }),
            catchError(error => { throw { errorText : 'could not add user', error : error, component : "admin"}; return new Observable<String>(); })
        );
    }
    @Action(AddNewUserProducts)
    addUserProducs(ctx: StateContext<UsersStateModel>, payload: any) {
        return this.http.post(this.envService.config.api + 'admin/v1/userproducts', payload["payload"]).pipe(tap((result: any) => {
            console.log("user products added");
        }),
            catchError(error => { console.error('could not add user products; Error:' + error); return new Observable<String>(); })
        );
    }

    @Action(FetchADUsersMatching)
    fetchMatchingADUsers(ctx: StateContext<UsersStateModel>, payload) {
        const requestOptions = {
            headers: new HttpHeaders({ Authorization: "Bearer " + localStorage.getItem("token")}),
          };
        return this.http.post<any>(this.envService.config.empAdUrl + 'v1/users' ,payload["payload"],requestOptions ).pipe(tap((result: ADUser[]) => {
            ctx.patchState({
                matchedADUsers: result
            });
        }),
            catchError(error => { console.error('could not get AD users; Error:' + error); return new Observable<String>(); })
        );
    }

    @Action(ClearUserForm)
    clearUserForm(ctx: StateContext<UsersStateModel>){
        ctx.patchState({
            usersForm : new StateForm()
        })
    }

    @Action(DeleteUser)
    deleteUser(ctx: StateContext<UsersStateModel>, payload) {
        return this.http.delete(this.envService.config.api + 'admin/v1/users/'+payload["payload"]).pipe(tap((result: UserDetails) => {
            console.log("User Deleted!!");
        }),
            catchError(error => { console.error('could not delete user; Error:' + error); return new Observable<String>(); })
        );
    }
}