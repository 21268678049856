import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ESPApiService } from 'src/app/services/esp-auth.service';
import { EnvironmentsService } from 'src/app/services/environment.service';
import { Store } from '@ngxs/store';
import { FetchSessionUser, UpdateToken } from 'src/app/actions/app.actions';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(private store: Store, private route: ActivatedRoute, private router: Router, private envService: EnvironmentsService, private apiService: ESPApiService) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      map(params => params),
      mergeMap((data: any) => {
        const code = data.code;
        const state = data.state;
        if (state !== this.envService.config.state) {
          return Observable.throw('Wrong state...');
        }
        const redirectUri = window.location.origin + this.envService.config.redirectUri;
        return this.apiService.authenticate(code, this.envService.config.espClientId, redirectUri);
    })).subscribe(
      (response: AuthenticationResponse) => {
        localStorage.setItem('auth-obj', JSON.stringify(response));
        this.store.dispatch(new UpdateToken(response.access_token));
        this.store.dispatch(new FetchSessionUser({token : response.access_token }));
        this.router.navigate(['/home']);
      },
      (error) => {
        //logout
      }
    );
  }
}

export class AuthenticationResponse{
  access_token: string;
  expires_in: number;
  refresh_token: string
}