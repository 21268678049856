import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'logoLabel'
})
export class LogoLabelPipe implements PipeTransform {
    transform(label: string): string {
        label = label.trim();
        let words:Array<string> = label.split(/\s/);
        var logoLabel = "";
        words.forEach(element => {
            logoLabel += element[0].toUpperCase();
        });
        if(logoLabel.length > 2){
            logoLabel = logoLabel.substr(0,2);
        }
        return logoLabel;
    }
}