export const environment = {
  production: false,
  trackingId : "UA-136336878-2",
  api : "https://ports-dev.esp-staging.eng.vmware.com/api/",
  ssoURL : "https://myvmware-stg.workspaceair.com/SAAS/API/1.0/GET/apps/launch/app/f2e62a99-c45d-4c19-8d36-3b2690c2f775",
  clientId : "aed572517d2db4d2bb7fc504796972f4",
  ipfURL: "feedback.esp-staging.vmware-aws.com",
  state: "t3jbMJNi",
//   authApiUrl: "https://auth.esp-staging.vmware-aws.com/api/auth/v1",
  authApiUrl: "https://broadcom.oktapreview.com/oauth2/default/v1",
  oktaClientId: "0oa24uep5dgIc67WW0h8",
  oktaClientPassword: "xPtrQT-Iyzj549x-FIPLEdmqvRwNrURB3WZUzRzU8OfIuMIm8x3TTbOC__LUHMlg",
  oktaCodeChallenge: "CNEVLWahKdih6qzoM-5E7URKGNQ4kfbAn5GpT1_KWUA",
  oktaCodeVerifier: "pfDKD4JGyueJdwvVGOUMQ5vz0OO5SZW3TAIHJMNX18-wkslVxC3A2BDm3_w",
  espClientId: "pnp-client",
  redirectUri: "/auth",
  empAdUrl: "https://employee-ad.esp-staging.eng.vmware.com/",
  localizationFilesPath: "https://wp-configuration.esp.vmware.com/configurationapi/v1/ports/labels/supportedvalues?locale=",
  whitneyUrl: "https://wp-configuration.esp.vmware.com"
};
