import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { AppState } from 'src/app/state/app.state';
import { Observable } from 'rxjs';
import { UpdateSelectedTabInManage } from 'src/app/actions/app.actions';
import { AuthZService } from 'src/app/services/auth-z.service';
import { Utils } from 'src/app/lib/utils';
import { MyErrorHandler } from 'src/app/services/error-handler.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { WhitneyService } from 'src/app/services/whitney.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit, OnDestroy {

  appLabels: any;
  transLang: any;
  defaultTab: string = "default";
  selectedTab: string = 'default';
  collapsed: boolean = false;
  showConfirmPopup: boolean = false;
  manageActions: Array<any> = [];
  @Select(AppState.getManageState) manageState$: Observable<any>;
  newTab: string;

  //Error Handler
  errorMessage: any = "";
  hideErrorPopup: boolean = true;
  showLoader: boolean = true;
  validRoutes: Array<string> = [
    "addnew", "edit", "releases", "review", "submissions", "network-diagrams"
  ];
  subs: Array<any> = [];
  constructor(private store: Store, public appLabelService: AppStringsService,
    public authZ: AuthZService, private errorHandler: MyErrorHandler,
    private _ref: ChangeDetectorRef, private route: ActivatedRoute, private whitneyService: WhitneyService) { }

  hasUnsavedData() {
    let state = this.store.snapshot();
    return state.inputs.inputsForEditForm.dirty || state.inputs.inputsForm.dirty;
  }

  ngOnInit() {
    this.appLabels = this.appLabelService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    
    // TODO hide few functionality for Product Users
    this.manageActions = [
      {
        header: this.transLang['addReleases'],
        body: this.transLang['addRelCardOneText'],
        footer: "add-releases"
      },
      {
        header: this.transLang['addRelCardTwoaData'],
        body: this.transLang['addRelCardTwoText'],
        footer: "edit-listing"
      },
      {
        header: this.transLang['viewSubmissions'],
        body: this.transLang['addRelCardThreeText'],
        footer: "view-submissions"
      },
      {
        header: this.transLang['reviewNpublish'],
        body: this.transLang['addRelCardFourText'],
        footer: "publish-review"
      },
      {
        header: this.transLang['manageNetDiagram'],
        body: this.transLang['addRelCardFiveText'],
        footer: "update-netdiagram"
      }
    ]
  });

    let routeSub = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        if (this.validRoutes.includes(params["id"])) {
          this.selectedTab = params["id"];
        }
      }
    });
    this.subs.push(routeSub);
    // To retain last edited tab
    // this.manageState$.subscribe(
    //   (manageState) => {
    //     if (manageState) {
    //       this.selectedTab = manageState.selectedTab;
    //     }
    //     else {
    //       this.updateTab(this.defaultTab);
    //     }
    //   }
    // )
    // Error Handler
    let errorSub = this.errorHandler.getMessage().subscribe(
      (error) => {
        console.log("in receiver");
        if (error && error.component && error.component == "manage") {
          this.errorMessage = error.errorText;
          this.hideErrorPopup = false;
          this.showLoader = false;
          this._ref.detectChanges();
        }
      }
    );
    this.subs.push(errorSub);
  }
  // updateTab(tab:string) {
  //   if (this.checkForUnsavedChanges(this.selectedTab, tab)) {
  //     //show confirmation box
  //     this.showConfirmPopup = true;
  //     this.newTab = tab;
  //   }
  //   else {
  //     this.store.dispatch(new UpdateSelectedTabInManage(tab));
  //   }
  // }

  updateTab(tab: string) {
    if (this.checkForUnsavedChanges(this.selectedTab, tab)) {
      //show confirmation box
      this.showConfirmPopup = true;
      this.newTab = tab;
    }
    else {
      this.selectedTab = tab;
    }
  }
  // confirmSwitch() {
  //   this.store.dispatch(new UpdateSelectedTabInManage(this.newTab));
  //   this.showConfirmPopup = false;
  // }
  confirmSwitch() {
    this.selectedTab = this.newTab;
    this.showConfirmPopup = false;
  }
  cancelSwitch() {
    this.showConfirmPopup = false;
  }
  setVerticalNav(collapsed: string) {
    this.collapsed = Utils.parseBoolean(collapsed);
  }

  checkForUnsavedChanges(oldTab: string, newTab: string) {
    if (oldTab == "edit") {
      let store = this.store.snapshot();
      return store.inputs.inputsForEditForm.dirty;
    }
    if(oldTab == "addnew"){
      let store = this.store.snapshot();
      return store.inputs.inputsForm.dirty;
    }
    return false;
  }

  reloadApp() {
    location.reload();
  }

  revealHide(event) {
    let classes: DOMTokenList = event.target.classList;
    let ele = event.target;
    if (classes.contains('hide-overflow')) {
      classes.remove('hide-overflow');
      classes.add('show-overflow');
    }
    else {
      classes.remove('show-overflow');
      classes.add('hide-overflow');
    }
    ele.classList = classes;
  }

  ngOnDestroy() {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }

}
