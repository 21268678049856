import { DatePipe } from '@angular/common';
import { Listing } from '../models/listing.model';
import { JoinPipe } from '../pipes/join.pipe';

export class CSVUtils {
    static headerPropertyMap: any = {
        "Ports": "port",
        "Protocols": "protocol",
        "Source": "source",
        "Destination": "destination",
        "Service Description": "serviceDescription",
        "Purpose": "purpose",
        "Classification": "classification"
    }
   
    static getListingsFromCSV(fields: string[], headerIndexes: any): Listing {
        /*headerIndexes = {
            "port" : 3,
            "protocol" : 4,
            "classification" :6
         };*/
        let listing: Listing = new Listing();
        for (const key in headerIndexes) {
            if (headerIndexes.hasOwnProperty(key)) {
                const element = headerIndexes[key];
                listing[key] = fields[element] == "" ? "-" : fields[element];
            }
        }
        return listing;
    }
    static getHeaderIndexes(fieldNames: string[]): any {
        //trimming spaces at front and rear
        for (let i = 0; i < fieldNames.length; i++) {
            fieldNames[i] = fieldNames[i].trim();
        }
        let headerIndexes = {};
        for (const key in CSVUtils.headerPropertyMap) {
            if (CSVUtils.headerPropertyMap.hasOwnProperty(key)) {
                const element = CSVUtils.headerPropertyMap[key];
                headerIndexes[element] = fieldNames.indexOf(key);
            }
        }
        return headerIndexes;
    }

    static objectFlip(obj) {
        const ret = {};
        Object.keys(obj).forEach((key) => {
            ret[obj[key]] = key;
        });
        return ret;
    }

    static formatToCSVData(data, headerPropMap = undefined) {
        let csvData = [];
        let propertyHeaderMap: any;
        if (headerPropMap) {
            propertyHeaderMap = CSVUtils.objectFlip(headerPropMap);
        }
        else {
            propertyHeaderMap = CSVUtils.objectFlip(CSVUtils.headerPropertyMap);
        }
        if (typeof data == "object") {
            data.forEach(element => {
                let obj = {};
                for (const key in propertyHeaderMap) {
                    if (propertyHeaderMap.hasOwnProperty(key)) {
                        if (typeof element[key] == "string") {
                            obj[propertyHeaderMap[key]] = CSVUtils.sanitizeStringFormula(element[key]);
                        }
                        else if(typeof element[key] == "number"){
                            if(key == "createdDate"){
                                obj[propertyHeaderMap[key]] = new DatePipe("en-US").transform(element[key]);
                            }
                            else{
                                obj[propertyHeaderMap[key]] = CSVUtils.sanitizeStringFormula(element[key].toString());
                            }
                        }
                        else if ( element[key] && typeof element[key] == "object") {
                            if (element[key] && element[key]["id"] && element[key]["id"] != undefined) {
                                // Object
                                if(element[key]["displayName"])
                                    obj[propertyHeaderMap[key]] = element[key] ? CSVUtils.sanitizeStringFormula(element[key]["displayName"]) : "";
                                if(element[key]["fullName"])
                                    obj[propertyHeaderMap[key]] = element[key] ? CSVUtils.sanitizeStringFormula(element[key]["fullName"]) : "";
                            }
                            else {
                                //Array
                                if (CSVUtils.hasDisplayName(element[key])) {
                                    obj[propertyHeaderMap[key]] = CSVUtils.sanitizeStringFormula(new JoinPipe().transform(element[key], "displayName"));
                                }
                                else {
                                    obj[propertyHeaderMap[key]] = CSVUtils.sanitizeStringFormula((element[key]).join(', '));
                                }

                            }
                        }
                    }
                }
                csvData.push(obj);
            });
        }
        return csvData;
    }
    static hasDisplayName(data){
        let returnVal = false;
        if(Array.isArray(data) && data.length > 0 ){
            data.forEach(element => {
                if(element && element["displayName"]){
                    returnVal = true;
                }
            });
        }
        return returnVal;
    }
    static sanitizeCSVFormula(arr) {
        let returnArr = [];
        for (let index = 0; index < arr.length; index++) {
            let tempArr = [];
            const element = arr[index];
            for (let index1 = 0; index1 < element.length; index1++) {
                const value = element[index1];
                tempArr[index1] = CSVUtils.sanitizeStringFormula(value);
            }
            returnArr.push(tempArr);
        }
        return returnArr;
    }
    static sanitizeStringFormula(str){
        return str.replace(/^(\+|=|@|-)*/, "");
    }
}