import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewComponent } from './components/view/view.component';
import { ManageComponent } from './components/manage/manage.component';
import { AdminComponent } from './components/admin/admin.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { AuthGuard } from './services/auth.guard';
import { AuthComponent } from './components/auth/auth.component';
import { CanDeactivateGuard } from './services/deactivate.guard';
import { ViewNetworkdiagramsComponent } from './components/view-networkdiagrams/view-networkdiagrams.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: 'home/:id', component: ViewComponent, data: { animation: 'VIEW' } },
  { path: 'network-diagrams', component: ViewNetworkdiagramsComponent, data: { animation: 'VIEW' } },
  { path: 'network-diagrams/:id', component: ViewNetworkdiagramsComponent, data: { animation: 'VIEW' } },
  { path: 'home', component: ViewComponent, data: { animation: 'VIEW' } },
  { path: 'manage/:id', component: ManageComponent, data: { animation: 'MANAGE' }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'manage', component: ManageComponent, data: { animation: 'MANAGE' }, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateGuard]},
  { path: 'admin/:id', component: AdminComponent, data: { animation: 'ADMIN' }, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, data: { animation: 'ADMIN' }, canActivate: [AuthGuard] },
  // { path: 'release-notes', component: ReleaseNotesComponent, data: { animation: 'RELEASE' } },
  // { path: 'contact-us', component: ContactUsComponent, data: { animation: 'CONTACT' } },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'auth', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
