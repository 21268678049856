import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {

  constructor(private _elementRef: ElementRef) { }
  @Output() public appDropdown = new EventEmitter();
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement){
    if(targetElement.id === 'search'){
      return ;
    }
    const isCLickableInside = this._elementRef.nativeElement.contains(targetElement);
    if(!isCLickableInside){
      this.appDropdown.emit(null);
    }
  }
}
