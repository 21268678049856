export class FetchComments{
    static readonly type = '[FETCH SUBMISSION COMMENTS] Fetch Submission Comments';
    constructor(public payload: any) {}
}
export class AddNewComment{
    static readonly type = '[ADD NEW COMMENT] Add New Submission Comment';
    constructor(public payload: any) {}
}
export class ClearAllComments{
    static readonly type = '[CLEAR ALL COMMENTS] Clear All Submission Comments';
    constructor() {}
}