import { Pipe, PipeTransform } from '@angular/core';
import { NetDiagramMeta } from '../models/netDiagraMeta.model';

@Pipe({
  name: 'keywordFilter'
})
export class KeywordFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    let searchTerms = searchText.trim().split(/\s/);
    if (items[0] && items[0]['id']) {
      return items.filter((data: NetDiagramMeta) => {
        let returnVal: boolean = false;
        for (let index = 0; index < searchTerms.length; index++) {
          const term = searchTerms[index].toLowerCase().trim();
          if (term) {
            if (typeof data["tags"] == "object" && data.tags && Array.isArray(data.tags)) {
              let str = data.tags.join(" ");
              returnVal = returnVal || ((str != undefined && str != null) && str.toLowerCase().indexOf(term) >= 0);
            }
            if (typeof data.title == "string" && data.title) {
              let str = data.title;
              returnVal = returnVal || ((str != undefined && str != null) && str.toLowerCase().indexOf(term) >= 0);
            }
            if (typeof data["releases"] == "object" && data.releases && Array.isArray(data.releases)) {
              let str = data.releases.join(" ");
              returnVal = returnVal || ((str != undefined && str != null) && str.toLowerCase().indexOf(term) >= 0);
            }
          }
        }
        return returnVal;
      });
    } else {
      if (!items) return [];
      if (!searchText) return items;
      searchText = searchText.toLowerCase();
      return items.filter(it => {
        if (it)
          return it.toLowerCase().includes(searchText);
        else
          false;
      });
    }
  }
}
