import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthZService } from '../services/auth-z.service';
import { ActivatedRoute } from '@angular/router';
import { Params } from '@angular/router';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private location: Location, private auth: AuthService, private authz: AuthZService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.loggedIn()) {
      return true;
    } else if (route.routeConfig.path == "error-page") {
      return true;
    } else if (this.location.path(false).indexOf("?errorCode=") != -1) {
      return false;
    } else {
      //this.router.navigate(['login'], { queryParams: { src: state.url } });
      console.log("navigating to guard");
      window.location.href = this.auth.ssoURL;
      return false;
    }
  }
}