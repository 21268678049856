import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';

import { Listing } from 'src/app/models/listing.model';
import { FetchListings, DeleteListings, FetchListingsForEdit, FetchCurrenUserSubmissions, ClearAllListings, FetchSubmissionsForReview, ClearSubmissionsForReview, ClearListingsForEdit } from '../actions/listing.actions';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Submission } from '../models/submission.model';
import { Utils } from '../lib/utils';
import { MyErrorHandler } from '../services/error-handler.service';
import { AppError } from '../models/app-error.model';
import { EnvironmentsService } from '../services/environment.service';

export class ListingStateModel {
    listings: Listing[];
    listingsForEdit: Listing[];
    userSubmissions: Submission[];
    submissionsForReview: Submission[];
}

@State<ListingStateModel>({
    name: 'listings',
    defaults: {
        listings: [

        ],
        listingsForEdit: [

        ],
        userSubmissions: [

        ],
        submissionsForReview :[

        ]
    }
})
export class ListingsState implements NgxsOnInit {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    listingsReq: Observable<any>;

    constructor(private http: HttpClient, private errorHandler: MyErrorHandler, private envService: EnvironmentsService) { }

    //for initial state
    ngxsOnInit(ctx: StateContext<ListingStateModel>) {
        //console.log('State initialized, now getting default listing details');
    }

    @Selector()
    static getListings(state: ListingStateModel) {
        return state.listings;
    }
    @Selector()
    static getlistingsForEdit(state: ListingStateModel) {
        return state.listingsForEdit;
    }

    @Selector()
    static getCurrentUserSubmissions(state: ListingStateModel) {
        return state.userSubmissions;
    }

    @Selector()
    static getSubmissionsForReview(state: ListingStateModel) {
        return state.submissionsForReview;
    }

    @Action(FetchListings)
    fetchListings(ctx: StateContext<ListingStateModel>, payload: string) {
        if (payload["payload"]) {
            if(this.listingsReq){
                this.ngUnsubscribe.next();
            }
            this.listingsReq = this.http.get(
                this.envService.config.api + 'manage/view/v1/vmwareproducts/' + payload['payload'] + '/listings1',
                {
                    headers : {
                        "X-ClientId" : this.envService.config.clientId
                    }
                }
            )
            .pipe( takeUntil(this.ngUnsubscribe) )
            .pipe(tap((result: Listing[]) => {
                ctx.patchState({
                    listings: result
                });
            }),
                catchError(error => { console.error('could not get listings'); return new Observable<String>(); })
            );
        }
        return this.listingsReq;
    }

    @Action(ClearAllListings)
    clearAllListings(ctx: StateContext<ListingStateModel>) {
        ctx.patchState({
            listings: []
        });
    }
    @Action(ClearListingsForEdit)
    clearListingsForEdit(ctx: StateContext<ListingStateModel>) {
        ctx.patchState({
            listingsForEdit: []
        });
    }
    @Action(DeleteListings)
    deleteListings(ctx: StateContext<ListingStateModel>, { payload }: any) {
        //payload is array of listings
        if (payload && payload[0]) {
            let productId = payload[0]['product']['id']; // Assumption : all the listings sent for deletion belong to same product
            return this.http.request('delete', this.envService.config.api + 'manage/v1/vmwareproducts/' + productId + '/listings', { body: payload }).pipe(
                tap(() => {
                    console.log("Listings Deleted !");
                }
                ),
                catchError(error => { throw { errorText: 'could not delete listings', error: error, component: "manage" }; return new Observable<String>(); })
            );
        }
    }

    @Action(FetchListingsForEdit)
    fetchListingsForEdit(ctx: StateContext<ListingStateModel>, payload: string) {
        if (payload["payload"]) {
            return this.http.get(this.envService.config.api + 'manage/v1/vmwareproducts/' + payload['payload'] + '/editablelistings').pipe(tap((result: Listing[]) => {
                ctx.patchState({
                    listingsForEdit: result
                });
            }),
                catchError(error => { console.error('could not fetch editable listings'); return new Observable<String>(); })
            );
        }
    }

    @Action(FetchCurrenUserSubmissions)
    fetchCurrenUserSubmissions(ctx: StateContext<ListingStateModel>) {
        return this.http.get(this.envService.config.api + 'manage/v1/userlistings?groupBy=submissionId').pipe(tap((result: Submission[]) => {
            //update status of the submission
            //update date of the submission
            for (let index = 0; index < result.length; index++) {
                let submission = result[index];
                let statusSet = new Set();
                let dateSet = new Set();
                for (let i = 0; i < submission.listings.length; i++) {
                    let listing = submission.listings[i];
                    statusSet.add(listing.status);
                    dateSet.add(listing.createdDate);
                }
                result[index].status = Utils.joinSet(statusSet);
                result[index].creationDate = Utils.joinSet(dateSet);
            }
            ctx.patchState({
                userSubmissions: result
            });
        }),
            catchError(error => { this.errorHandler.sendMessage(new AppError('could not fetch current user listings/submission', 'user-submissions')); console.error('could not fetch current user listings/submission'); return new Observable<String>(); })
        );
    }

    @Action(FetchSubmissionsForReview)
    fetchSubmissionsForReview(ctx: StateContext<ListingStateModel>, payload : any) {
        return this.http.get(this.envService.config.api + 'manage/v1/vmwareproducts/'+payload["payload"]+'/listings?status=REVIEW&groupBy=submissionId').pipe(
            tap((result: Submission[]) => {
            const state = ctx.getState();
            //update date of the submission
            for (let index = 0; index < result.length; index++) {
                let submission = result[index];
                let dateSet = new Set();
                let ownerSet = new Set();
                for (let i = 0; i < submission.listings.length; i++) {
                    let listing = submission.listings[i];
                    dateSet.add(listing.publishDate);
                    ownerSet.add(listing.createdBy);
                }
                result[index].publishDate = Utils.joinSet(dateSet);
                result[index].submittedBy = Utils.joinSet(ownerSet);
            }
            ctx.patchState({
                submissionsForReview : [...state.submissionsForReview, ...result]
            });
        }),
            catchError(error => { console.error('could not fetch  listings/submission for review'); console.error(error); return new Observable<String>(); })
        );
    }
    @Action(ClearSubmissionsForReview)
    ClearSubmissionsForReview(ctx: StateContext<ListingStateModel>){
        ctx.patchState({
            submissionsForReview : []
        })
    }
}