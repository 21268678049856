import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UpdateToken } from 'src/app/actions/app.actions';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AuthUtils } from 'src/app/utils/auth-utils';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private route: ActivatedRoute, private store: Store,
    private location: Location, private router: Router, private cookieService: CookieService,
    @Inject(DOCUMENT) private document) { }

  ngOnInit() {
    this.document.querySelector("#app-root").style.display = "block";
    this.document.querySelector("#static-section").style.display = "none";
    let token = undefined;
    if (this.cookieService.check('ports-token')) {
      token = this.cookieService.get('ports-token');
      AuthUtils.deleteAuthCookies(this.cookieService)
    }
    if (token != undefined) {
      this.store.dispatch(new UpdateToken(token)).subscribe(
        {
          complete: () => {
            this.location.replaceState('/home');
            this.router.navigate(['/home'])
          }
        }
      );
    }
  }
}
