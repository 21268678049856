export const environment = {
  production: true,
  trackingId : "UA-136336878-1",
  api : "https://ports.esp.spespg1.vmw.saas.broadcom.com/",
  ssoURL : "https://myvmware.workspaceair.com/SAAS/API/1.0/GET/apps/launch/app/68d53d2f-52d4-4791-b004-4917026301b1",
  clientId : "aed572517d2db4d2bb7fc504796972f4",
  ipfURL: "feedback.esp.vmware.com",
  state: "t3jbMJNiPROD",
//   authApiUrl: "https://auth.esp.vmware.com/api/auth/v1",
  authApiUrl: "https://broadcom.okta.com/oauth2/default/v1",
  oktaClientId: "0oax4jp5vew1IRP8U2p7",
  oktaClientPassword: "7fcd38Qf4wBM_GJb1SHW5JraDyNPbEeSHW3Jnlsa4FpmSEG2ZJqAqNFMV6CIDCC7",
  oktaCodeChallenge: "2LWVsgxqjLD71xG1XpIxz7NRtloU0OsuvYGPJUvvlxU",
  oktaCodeVerifier: "wlikgZytmlN7lppH-S86QBvogvIHmJzChPbK4qb4IYgny84H2AgdMOUvd5Pa",
  espClientId: "pnp-client",
  redirectUri: "/auth",
  empAdUrl: "https://employee-ad.esp.eng.vmware.com/",
  whitneyUrl: "https://ports.esp.spespg1.vmw.saas.broadcom.com",
  localizationFilesPath: "https://ports.esp.spespg1.vmw.saas.broadcom.com/manage/v1/supportedvalues?locale="
};

