import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClrDatagridStringFilterInterface } from '@clr/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import {
  AddNewRelease,
  DeleteRelease,
  UpdateSelectedProductForReleases,
  FetchReleasesForSelectedProduct,
  ClearReleasesForm,
} from 'src/app/actions/release.actions.';
import { FetchRMReleasesMatching, ClearRMReleasesMatching } from 'src/app/actions/rmreleases.action';

import { Utils } from 'src/app/lib/utils';
import { Product } from 'src/app/models/product.model';
import { Release } from 'src/app/models/release.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { ProductState } from 'src/app/state/product.state';
import { ReleaseState } from 'src/app/state/release.state';
import { FetchCurrentUserProducts } from 'src/app/actions/product.actions';
import { RMReleaseState } from 'src/app/state/rmreleases.state';
import { LocationStrategy } from '@angular/common';
import { WhitneyService } from 'src/app/services/whitney.service';


@Component({
  selector: 'releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss'],
  animations:
    [
      trigger('listAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),

          query('clr-dg-row', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ])
    ]
})
export class ReleasesComponent implements OnInit, OnDestroy {
  appLabels: any;
  transLang: any;
  matchedRMreleases: Array<any> = [];
  releaseFilter = new DataStringFilter();
  timer: any;
  rowSelected = [];
  selectedProductId: string = "";
  currentUserProducts: Product[] = [];
  showDeleteConfirm: boolean = false;
  loader: boolean = true;
  hideReleasesForm: boolean = true;
  formSubmitted: boolean = false;
  showSuggestions: boolean = false;
  disableConfirm: boolean = false;
  showCheckmark: boolean = false;
  readonly autoCompleteListLimit = 5;
  hideErrorPopup: boolean = true;
  closable: boolean = true;
  @Select(ReleaseState.getReleasesForSelectedProduct) releases$: Observable<Release[]>;
  @Select(RMReleaseState.getMatchedRMreleases) matchedRMReleases$: Observable<any>;
  @Select(ProductState.getUserProducts) userProducts$: Observable<Product[]>;
  @Select(ReleaseState.getSelectedProductForReleases) selectedProduct$: Observable<Product>;

  releasesForm = this.formBuilder.group({
    release: this.formBuilder.group({
      releaseName: ['', Validators.required],
      displayName: ['', Validators.required],
      // publishDate: ['', Validators.required],
      rmReleaseId: ['', Validators.required]
    })
  });
  disableProdSelect: boolean = false;;
  subscriptions: Array<Subscription> = [];
  selectedProductFromSub: any; // Used only while fetching RM Releases
  get release(): FormGroup {
    return <FormGroup>this.releasesForm.get("release");
  }

  constructor(private store: Store, private formBuilder: FormBuilder,
    private appStringsService: AppStringsService, private _ref: ChangeDetectorRef,
    private location: LocationStrategy,private whitneyService: WhitneyService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit() {
    this.showCheckmark = true;
    this.appLabels = this.appStringsService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title + " - Add Release Versions", "/manage/releases", "");
    this.store.dispatch(new FetchCurrentUserProducts()).subscribe();
    let userProdSub = this.userProducts$.subscribe(
      (userProducts: Product[]) => {
        if (userProducts) {
          this.currentUserProducts = userProducts;
          this.selectedProductId = userProducts[0]["id"];
          this.store.dispatch(new UpdateSelectedProductForReleases(userProducts[0]));
        }
      }
    );
    this.subscriptions.push(userProdSub);
    let selectProdSub = this.selectedProduct$.subscribe(
      (selectedProduct: Product) => {
        if (selectedProduct) {
          this.selectedProductFromSub = selectedProduct;
          this.store.dispatch(new FetchReleasesForSelectedProduct(selectedProduct)).subscribe(
            () => {
              this.disableProdSelect = false;
            }
          );
          if (selectedProduct.rmProductId) {
            this.showSuggestions = true;
            this.store.dispatch(new ClearRMReleasesMatching());
            if (this.hideReleasesForm == false) {
              this.store.dispatch(new FetchRMReleasesMatching(selectedProduct.rmProductIds,selectedProduct.productName));
            }
          }
        }
      }
    );
    this.subscriptions.push(selectProdSub);
    let relSub = this.releases$.subscribe(
      (releasesData) => {
        if (releasesData != null && releasesData != undefined && releasesData.length != 0 && this.loader == true) {
          this.loader = false;
        }
        else if (releasesData == undefined && this.selectedProductId != "") {
          this.store.dispatch(new FetchReleasesForSelectedProduct({ "id": this.selectedProductId }));
        }
        else {
          this.loader = false;
        }
      }
    );
    this.subscriptions.push(relSub);
    let rmRelSub = this.matchedRMReleases$.subscribe(
      (rmReleases: any) => {
        if (rmReleases) {
          if (rmReleases.length > 0) {
            this.showSuggestions = false;
          }
          if (rmReleases.length == 0) {
            this.showSuggestions = false;
            this.hideErrorPopup = false;
            setTimeout(() => {
              this.hideErrorPopup = true;
            }, 5000);
          }
          this.matchedRMreleases = rmReleases;
        }
        else {
          this.matchedRMreleases = null;
        }
      }
    );
    this.subscriptions.push(rmRelSub);
  }

  updateSelectedProduct(event) {
    this.release.get("displayName").setValue("");
    this.release.get("releaseName").setValue("");
    this.release.get("rmReleaseId").setValue("");
    this.selectedProductId = event.target.value;
    let selectedProduct: Product;
    for (let index = 0; index < this.currentUserProducts.length; index++) {
      const element = this.currentUserProducts[index];
      if (element.id == this.selectedProductId) {
        selectedProduct = element;
        break;
      }
    }
    this.disableProdSelect = true;
    this.store.dispatch(new UpdateSelectedProductForReleases(selectedProduct));
  }
  addRelease() {
    this.hideReleasesForm = false;
    this.store.dispatch(new FetchRMReleasesMatching(this.selectedProductFromSub.rmProductIds,this.selectedProductFromSub.productName));
  }
  addNewRelease() {
    this.formSubmitted = true;
    let release: Release = new Release();
    release = Utils.clone(this.release.value, release);
    // release.publishDate = new Date(this.release.value.publishDate).toISOString().split("T")[0] + " 00:00";
    release.product = { "id": this.selectedProductId };
    release.productId = this.selectedProductId;
    let newRelSub = this.store.dispatch(new AddNewRelease(release)).subscribe(
      () => {
        this.resetForm();
        this.store.dispatch(new ClearReleasesForm());
        this.hideReleasesForm = true;
        setTimeout(() => {
          //let the checkmark animation run for 2seconds
          this.formSubmitted = false;
        }, 2000);
        this.store.dispatch(new FetchReleasesForSelectedProduct({ "id": this.selectedProductId }));
      });
    this.subscriptions.push(newRelSub);
  }

  resetForm() {
    this.releasesForm.reset();
  }

  deleteRelease(force = false) {
    if (!force) {
      this.showDeleteConfirm = true;
      return;
    }
    let deletionObjs = [];
    this.rowSelected.forEach(element => {
      deletionObjs.push(new DeleteRelease(element));
    });
    let delSub = this.store.dispatch(deletionObjs).subscribe(
      () => {
        this.showDeleteConfirm = false;
        let selectProdSub = this.selectedProduct$.subscribe((product: Product) => {
          if (product) {
            this.disableConfirm = false;
            this.store.dispatch(new FetchReleasesForSelectedProduct(product));
          }
        });
        this.subscriptions.push(selectProdSub);
      }
    );
    this.subscriptions.push(delSub);
  }

  editRelease() {

  }

  // fetchMatchingReleases(event) {
  //   // TODO Fetch all the RM releases for a product RM Id
  //   let enteredText: string = event.target.value;
  //   if (enteredText.length > 3) {
  //     //identify the RM product Id of the selected product
  //     let productRMId;
  //     this.userProducts$.subscribe(
  //       (products: Product[]) => {
  //         for (let index = 0; index < products.length; index++) {
  //           const element = products[index];
  //           if (element.id == this.selectedProductId) {
  //             productRMId = element.rmProductId;
  //           }
  //         }
  //         this.store.dispatch(new FetchRMReleasesMatching(
  //           {
  //             "matchPattern": enteredText,
  //             "rmProductId": productRMId
  //           }
  //         ));
  //       }
  //     )
  //   }
  // }

  updateRMId(event) {
    let enteredValue = event.target.value;
    this.matchedRMreleases.forEach(element => {
      if (element.name == enteredValue) {
        this.release.get("rmReleaseId").setValue(element.id);
        //this.release.get("displayName").setValue(element.name);
      }
    });
  }
  reloadApp() {
    location.reload();
  }
}



class DataStringFilter implements ClrDatagridStringFilterInterface<Release> {
  accepts(release: Release, search: string): boolean {
    return "" + release.displayName == search
      || release.displayName.toLowerCase().indexOf(search) >= 0;
  }
}
