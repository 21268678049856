import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Renderer, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { ClrDatagridStringFilterInterface, ClrDatagridComparatorInterface } from '@clr/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { FetchAllUserProducts, FetchAllUsers, AddNewUser, AddNewUserProducts, FetchADUsersMatching, ClearUserForm, DeleteUser } from 'src/app/actions/users.actions';
import { UserDetails } from 'src/app/models/userdetails.model';
import { AppStringsService } from 'src/app/services/app-strings.service';
import { AuthZService } from 'src/app/services/auth-z.service';
import { AuthService } from 'src/app/services/auth.service';
import { UsersState } from 'src/app/state/users.state';
import { ProductState } from 'src/app/state/product.state';
import { Product } from 'src/app/models/product.model';
import { FetchProducts } from 'src/app/actions/product.actions';
import { ADUser } from 'src/app/models/aduser.model';
import { Utils } from 'src/app/lib/utils';
import { LocationStrategy } from '@angular/common';
import { Papa } from 'ngx-papaparse';
import { FileSaverService } from 'ngx-filesaver';
import { CSVUtils } from 'src/app/utils/csv-utils';
import { async } from '@angular/core/testing';
import { ProductsComponent } from '../products/products.component';
import { getLineAndCharacterOfPosition } from 'typescript';
import { WhitneyService } from 'src/app/services/whitney.service';

import { ExportToCsv } from 'export-to-csv';

class DataStringFilter implements ClrDatagridStringFilterInterface<UserDetails> {
  accepts(user: UserDetails, search: string): boolean {
    return "" + user.emailId == search
      || user.emailId.toLowerCase().indexOf(search) >= 0;
  }
}

class NameDataStringFilter implements ClrDatagridStringFilterInterface<UserDetails> {
  accepts(user: UserDetails, search: string): boolean {
    return "" + user.fullName == search
      || user.fullName.toLowerCase().indexOf(search) >= 0;
  }
}

class RoleDataStringFilter implements ClrDatagridStringFilterInterface<UserDetails> {
  accepts(user: UserDetails, search: string): boolean {
    return "" + user.role == search
      || user.role.toLowerCase().indexOf(search) >= 0;
  }
}



class ProductsNameDataStringFilter implements ClrDatagridStringFilterInterface<UserDetails> {
  accepts(user: UserDetails, search: string): boolean {
    let productName = '';
    
    for(let i=0;i<user.products.length;i++){
      if(user.products[i] ){
        productName = productName +', '+user.products[i].productName;
      }
    }
    return "" + productName == search
      || productName.toLowerCase().indexOf(search) >= 0;
  }
}

class ProductNameComparator implements ClrDatagridComparatorInterface<UserDetails> {
  compare(a: UserDetails, b: UserDetails) {
    let productNameA = '';
    let productNameB = '';
    // tslint:disable: prefer-for-of
    for (let i = 0; i < a.products.length; i++) {
      if (a.products[i] !== null && a.products[i] !== undefined) {
        productNameA += a.products[i].productName;
      }
    }
    for (let i = 0; i < b.products.length; i++) {
      if (b.products[i] !== null && b.products[i] !== undefined) {
        productNameB += b.products[i].productName;
      }
    }
    return productNameA.toLocaleUpperCase().localeCompare(productNameB.toLocaleUpperCase());
  }
}

@Component({
  selector: 'users-list',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations:
    [
      trigger('listAnimation', [
        transition('* => *', [

          query(':enter', style({ opacity: 0 }), { optional: true }),

          query('clr-dg-row', stagger('50ms', [
            animate('1s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
        ])
      ])
    ]
})

export class UsersComponent implements OnInit, OnDestroy {
  transLang: any;
  appLabels: any;
  loader: boolean = true;
  productsFilter = new ProductsNameDataStringFilter();
  productSort = new ProductNameComparator();
  timer: any;
  rowSelected = [];
  hideUsersForm: boolean = true;
  formSubmitted: boolean = false;
  matchedADUsers = [];
  readonly autoCompleteListLimit = 5;
  hideGrid: boolean = false;
  showSuggestions: boolean = false;
  showDeleteConfirm: boolean = false;
  disableConfirm: boolean = false;
  subscriptions: Array<Subscription> = [];
  @Select(ProductState.getProducts) products$: Observable<Product[]>;

  static headerPropertyMap: any = {
    'Email Id': 'emailId',
    'Name': 'fullName',
    'Role': 'role',
    'Products': 'products',
    'Created By': 'createdBy',
    'Created Date':'createdDate'
  }

  usersForm = this.formBuilder.group({
    user: this.formBuilder.group({
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      fullName: ['', Validators.required],
      role: ['', Validators.required],
      loginType: ['', Validators.required],
      products: [[], Validators.required],
      mail: ['']
    })
  });

  @Select(UsersState.getUsers) users$: Observable<UserDetails[]>;
  @Select(UsersState.getMatchedADUsers) matchedADUsers$: Observable<UserDetails[]>;
  users: UserDetails[];

  get user(): FormGroup {
    return <FormGroup>this.usersForm.get("user");
  }
  constructor(private store: Store, private formBuilder: FormBuilder,
    private appStringService: AppStringsService, private renderer: Renderer,
    public authZ: AuthZService, private auth: AuthService,
    private ref: ChangeDetectorRef, private location: LocationStrategy, 
    private papa: Papa, private fileSaver: FileSaverService, private whitneyService: WhitneyService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
  ngOnInit() {
    this.users$.subscribe( data => {
      this.users = data;
    })
    this.appLabels = this.appStringService.appStrings;
    this.whitneyService.getTranslation().subscribe((response) => {
      this.transLang = response[0];
    });
    this.location.pushState({}, this.appLabels.title + " - Users", "/admin/users", "");
    this.appLabels = this.appStringService.appStrings;
    this.store.dispatch(new FetchProducts());
    this.fetchAllUsers();
    const userSub = this.users$.subscribe(
      (usersData) => {
        if (usersData != null && usersData != undefined && usersData.length != 0 && this.loader == true) {
          this.ref.detectChanges();
          this.loader = false;
          const allUserProdSub = this.store.dispatch(new FetchAllUserProducts()).subscribe(
            () => {
              this.ref.detectChanges();
            }
          );
          this.subscriptions.push(allUserProdSub);
        }
      }
    );
    this.subscriptions.push(userSub);
    const adUserSub = this.matchedADUsers$.subscribe(
      (adUsers: ADUser[]) => {
        if (adUsers) {
          this.matchedADUsers = adUsers.slice(0, 4);
          this.showSuggestions = false;
        }
      }
    );
    this.subscriptions.push(adUserSub);
  }
  addUser() {
    this.resetForm();
    this.store.dispatch(new ClearUserForm());
    this.hideUsersForm = false;
    this.hideGrid = true;
  }

  deleteUser(force = false) {
    if (!force) {
      this.showDeleteConfirm = true;
      return;
    }
    const deletionObjs = [];
    this.rowSelected.forEach(element => {
      deletionObjs.push(new DeleteUser(element.id));
    });
    const deleteSub = this.store.dispatch(deletionObjs).subscribe(
      () => {
        this.showDeleteConfirm = false;
        this.disableConfirm = false;
        //update users tabel after deletion
        this.fetchAllUsers();
      }
    );
    this.subscriptions.push(deleteSub);
  }

  edituser() {

  }
  resetForm() {
    this.usersForm.reset();
  }

  fetchMatchingUsers(event) {
    const enteredText: string = event.target.value;
    if (enteredText.length > 3) {
      this.showSuggestions = true;
     let payload ={"name" : enteredText} 
      this.store.dispatch(new FetchADUsersMatching(payload));
    }
  }

  addNewUser() {
    //this.hideUsersForm = true;
    //add new user
    this.formSubmitted = true;
    const user = Utils.clone(this.user.value, new UserDetails());
    delete (user["products"]);
    const newUserSub = this.store.dispatch(new AddNewUser(user)).subscribe(() => {
      if (user.role != "SUPER_ADMIN") {
        const products: Product[] = [];
        //convert array of product strings to product objects
        this.user.value.products.forEach((element: string) => {
          products.push({ id: element })
        });
        const newUserProducts: any = {
          user: user,
          products: products
        }
        //add products for newly added user
        const newUserProdSub = this.store.dispatch(new AddNewUserProducts(newUserProducts)).subscribe(
          () => {
            this.afterNewUserAdded();
          }
        );
        this.subscriptions.push(newUserProdSub);
      }
      else {
        this.afterNewUserAdded();
      }
    });
    this.subscriptions.push(newUserSub);
  }

  afterNewUserAdded() {
    this.resetForm();
    this.store.dispatch(new ClearUserForm());
    this.hideUsersForm = true;
    setTimeout(() => {
      //let the checkmark animation run for 2seconds
      this.formSubmitted = false;
      this.hideGrid = false;
      this.fetchAllUsers();
    }, 2000);

  }

  updateFullName(event) {
    const enteredValue = event.target.value;
    this.matchedADUsers.forEach(element => {
      if (element.mail == enteredValue) {
        this.user.get("fullName").setValue(element.fullName);
        this.user.get("emailId").setValue(element.mail);
      }
    });
  }

  fetchAllUsers() {
    const allUserSub = this.store.dispatch(new FetchAllUsers()).subscribe(
      () => {
        this.ref.detectChanges();
        const alluserProdSub = this.store.dispatch(new FetchAllUserProducts()).subscribe(
          () => {
            this.ref.detectChanges();
          }
        );
        this.subscriptions.push(alluserProdSub)
      }
    );
    this.subscriptions.push(allUserSub);
  }

  onRoleChange(event) {
    const role = event.target.value;
    if (role == "SUPER_ADMIN") {
      this.usersForm.get('user').get('products').clearValidators();
    }
    else {
      this.usersForm.get('user').get('products').setValidators(Validators.required);
    }
    this.usersForm.get('user').get('products').updateValueAndValidity();
    this.usersForm.get('user').updateValueAndValidity();
    this.usersForm.updateValueAndValidity();
  }

  reloadApp() {
    location.reload();
  }

  exportToCSV() {
    let data = CSVUtils.formatToCSVData(this.users, UsersComponent.headerPropertyMap);
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers:[
        this.transLang['emailId'],
        this.transLang['name'],
        this.transLang['role'],  
        this.transLang['products'],
        this.transLang['createdBy'],
        this.transLang['createdDate'],
   ]
    }
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(data);
  }

}
