import { Product } from '../models/product.model';
import { UserDetails } from '../models/userdetails.model';


export class FetchAllUsers{
    static readonly type = '[USERS] Get Users';
}

export class FetchAllUserProducts{
    static readonly type = '[ALL USER PRODUCTS] Get User Products';
}

export class AddNewUser{
    static readonly type = '[ADD NEW USER] Add New User';
    constructor(public payload: any) {}

}

export class AddNewUserProducts{
    static readonly type = '[ADD NEW USER PRODUCTS] Add User Products';
    constructor(public payload: any) {}

}

export class FetchADUsersMatching{
    static readonly type = '[FETCH AD USERS] Fetch Matching ADUsers';
    constructor(public payload: any) {}
}

export class ClearUserForm{
    static readonly type = '[CLEAR USER FORM] Clear User Form';
}
export class DeleteUser{
    static readonly type = '[DELETE USER] Delete User ';
    constructor(public payload: any) {}
}