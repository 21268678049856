import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStringsService {

  appStrings: any;

  constructor() {

    this.appStrings = {
      'vmware' : 'VMware',
      'title': 'VMware Ports and Protocols',
      'product': 'Product',
      'products' : 'Products',
      'version': 'Version',
      'source': 'Source',
      'destination': 'Destination',
      'ports': 'Ports',
      'protocols': 'Protocols',
      'purpose': 'Purpose',
      'serviceDesc': 'Service Description',
      'classification': 'Classification',
      'selectProducts': 'Select Products',
      'view': 'View',
      'manage': 'Manage',
      'admin': 'Admin',
      'releaseNotes': 'Release Notes',
      'contactUs': 'Contact Us',
      'productContact': 'Product Contact',
      'about': "About",
      "feedback" : "Feedback",
      'settings': 'Settings',
      'signOut': 'Sign Out',
      'signIn' : 'Sign In',
      'actions' : 'Actions',
      'inputs' : 'Inputs',
      'addRelease' : 'Add Release',
      "editRelease" : "Edit Release",
      "deleteRelease" : "Delete Release",
      'users' : 'Users',
      "publishDate" : "Publish Date",
      "publishTime" : "Publish Time",
      "displayName" : "Display Name",
      "productName" : "Product Name",
      "loadDrafts" : "Load Latest Drafts",
      "exportToCSV" : "Export to CSV",
      "removeRow" : "Remove Row",
      "associateRel" : "Associate Release",
      "addRow" : "Add Row",
      "choosePubDate" : "Choose publish date",
      "confirm" : "Confirm",
      "cancel" : "Cancel",
      "reset" : "Reset",
      "assignRel" : "Assign Releases",
      "assignRelSelectedPorts" : "Assign Release Version to Selected Ports",
      "selectRel" : "Select Releases to associate with selected listings",
      "assign" : "Assign",
      "publish" : "Publish",
      "submitReview" : "Submit for Review",
      "saveDraft" : "Save",
      "enterText" : "Enter Text",
      "switchTheme" : "Change Theme",
      "name" : "Name",
      "role" : "Role",
      "emailId" : "Email Id",
      "createdBy" : "Created By",
      "createdDate" : "Created Date",
      "addUser" : "Add User",
      "editUser" : "Edit User details",
      "deleteUser" : "Delete User",
      "loginType" : "Login Type",
      "empLogin" : "Employee Login",
      "productAdmin" : "Product Admin",
      "productUser" : "Product User",
      "superAdmin" : "Super Admin",
      "fullName" : "Full Name",
      "rmProductId" : "Release Master Product Id",
      "rmProductIds" : "Release Master Product Ids",
      "confirmProdDeleteMsg" : "Are you sure you want to delete selected product(s)?",
      "confirmRelDeleteMsg" : "Are you sure you want to delete selected release version(s)?",
      "confirmUserDeleteMsg" : "Are you sure you want to delete selected user(s)?",
      "releases" : "Releases",
      "release" : "Release",
      "releaseVersion" : "Release Version",
      "rmReleaseId" : "Release Master Release Id",
      "editListings" : "Edit Listings",
      "deleteListings" : "Delete Listings",
      "updatedListings" : "Updated Listings",
      "resetWarning" : "Reset Warning!",
      "resetWarningMsg" : "Few listings were edited. Still want to reset ?",
      "deleteWarning" : "Delete Warning!",
      "deleteWarningMsg" : "Confirm deletion of selected listings",
      "delete" : "Delete",
      "confirmDelete" : "Confirm Delete",
      "submissionId" : "Submission ID",
      "status" : "Status",
      "date" : "Date",
      "mySubmissions" : "My Submissions",
      "reviewNpublish" : "Review & Publish",
      "action" : "Action",
      "approveSub" : "Approve submission",
      "rejectSub" : "Reject submission",
      "downloadAsCSV" : "Download as CSV",
      "downloadAsJSON" : "Download as JSON",
      "downloadAsXls" : "Download as Excel",
      "downloadAsPDF" : "Download as PDF",
      "download": "Download",
      "submittedBy" : "Submitted By",
      "home" : "Home",
      "addProduct" : "Add Product",
      "deleteProduct" : "Delete Product",
      "addPortData" : "Add Port Data",
      "addReleases" : "Add Releases",
      "editPortData" : "Edit Port Data",
      "viewSubmissions" : "View Submissions",
      "details" : "Details",
      "fbtype" : "Feedback Type",
      "appFb" : "Application Feedback",
      "prodFb" : "Product Data Feedback",
      "fbType" : "Feedback Type",
      "submit" : "Submit",
      "yourEmailId" : "Your Email-id",
      "contactForProduct" : "If your product is not listed in the tool, contact ports_admin@vmware.com.",
      "aboutText" : "This VMware Ports and Protocols tool describes the ports and protocols requirements for using VMware products.",
      "productLogo" : "Product Logo",
      "publishNowConfirmationMessage": "Are you sure you want to publish this information now?",
      "publishFutureConfirmationMessage": "Are you sure you want to publish this information on ",
      "versionsNotAssigned" : "Versions not assigned",
      "versionsAssigned" : "Versions assigned",
      "productOrder" : "Re-arranging products order",
      "usersData": "UsersData",
      "manageNetDiagram": "Manage Network Diagram",
      "networkDiagrams" : "Network Diagrams",
      "labels" : "Labels",
      "tags" : "Tags",
      "titleKey" : "Title",
      "netDiaFile" : "Network Diagram File",
      "selectProductMsg" : "Select a product from the list to view corresponding Network diagrams.",
      "productsData": "ProductsData",
      "fullScreen": "Full Screen",
      "clearFilters": "Clear Filters",
      "ndFor": "Network Diagrams for",
      "edit": "Edit",
      "addNewND": "Add New Network Diagram",
      "addNew": "Add New",
      "noNDForProduct": "No Network Diagram available for selected product.",
      "deleteConfirmNDMsg": "Are you sure you want to delete Network diagram?"
    };
   }
}
