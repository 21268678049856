import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Release } from '../models/release.model'
import { AddNewRelease, DeleteRelease, FetchReleases, UpdateSelectedProductForReleases, FetchReleasesForSelectedProduct, ClearReleasesForm, ClearReleases} from '../actions/release.actions.'
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '../models/product.model';
import { EnvironmentsService } from '../services/environment.service';

export class ReleaseStateModel {
    releasesforInputProduct: Release[]; // inputs componenet uses these releases
    defaultRelease: string;
    matchedRMReleases?: [];
    selectedProductForReleases?: Product;
    releasesForSelectedProduct?: Release[]; // releases component uses these releases
    releasesForm : StateForm
}

class StateForm {
    model: any = undefined;
    dirty: boolean = false;
    status: string = "";
    errors: any = {};
}

@State<ReleaseStateModel>({
    name: 'releases',
    defaults: {
        releasesforInputProduct: [],
        defaultRelease: '',
        selectedProductForReleases: new Product(),
        releasesForm: new StateForm()
    }
})
export class ReleaseState {

    constructor(private http: HttpClient, private envService: EnvironmentsService) { }
    ngxsOnInit(ctx: StateContext<ReleaseStateModel>) {
        //console.log('State initialized, now getting Release details from API');
    }

    @Selector()
    static getReleases(state: ReleaseStateModel) {
        return state.releasesforInputProduct;
    }
    @Selector()
    static getDefaultRelease(state: ReleaseStateModel) {
        return state.defaultRelease;
    }


    @Selector()
    static getSelectedProductForReleases(state: ReleaseStateModel) {
        return state.selectedProductForReleases;
    }

    @Selector()
    static getReleasesForSelectedProduct(state: ReleaseStateModel) {
        return state.releasesForSelectedProduct;
    }

    @Action(AddNewRelease)
    add(ctx: StateContext<ReleaseStateModel>, { payload }: AddNewRelease) {
        return this.http.post(this.envService.config.api+'manage/v1/vmwareproducts/' + payload["product"]["id"] + '/releases',  payload).pipe(
            tap(newRelease => {
                console.log("New Release added !");
            }),
            catchError(error => { console.error('could not add release'); return new Observable<String>(); })
        );
    }

    @Action(DeleteRelease)
    remove(ctx: StateContext<ReleaseStateModel>, { payload }: DeleteRelease) {
        return this.http.delete(this.envService.config.api+'manage/v1/vmwareproducts/' + payload["product"].id + '/releases/' + payload["id"]).pipe(
            tap(newRelease => {
                console.log("Release deleted !");
            }),
            catchError(error => { console.error('could not delete release'); return new Observable<String>(); })
        );
    }

    @Action(FetchReleases)
    fetchReleases({ patchState }: StateContext<ReleaseStateModel>, payload: Product) {
        if (payload != null && payload["payload"] != undefined && payload["payload"]["id"] != undefined) {
            return this.http.get(this.envService.config.api + 'manage/view/v1/vmwareproducts/' + payload["payload"].id + '/releases').pipe(
                tap((data: Release[]) => {
                    // statusData[0]['selected'] = true;
                    if (data.length) {
                        patchState({
                            releasesforInputProduct: data,
                            defaultRelease: data[0].id
                        })
                    }
                    else{
                        patchState({
                            releasesforInputProduct: [],
                            defaultRelease: undefined
                        })
                    }
                }),
                catchError(error => { console.error('could not get releases'); console.log(error); return new Observable<String>(); })
            );
        }
    }


    @Action(ClearReleases)
    clearReleases({patchState}: StateContext<ReleaseStateModel>){
        patchState({
            releasesforInputProduct: [],
            defaultRelease: undefined
        })
    }

    @Action(FetchReleasesForSelectedProduct)
    fetchReleasesForSelectedProduct({ patchState }: StateContext<ReleaseStateModel>, payload: Product) {
        if (payload != null && payload["payload"] != undefined && payload["payload"]["id"] != undefined) {
            return this.http.get(this.envService.config.api + 'manage/view/v1/vmwareproducts/' + payload["payload"].id + '/releases').pipe(
                tap((data: Release[]) => {
                    // statusData[0]['selected'] = true;
                        patchState({
                            releasesForSelectedProduct: data,
                        })
                }),
                catchError(error => { console.error('could not get releases'); console.log(error); return new Observable<String>(); })
            );
        }
    }

    @Action(UpdateSelectedProductForReleases)
    updateSelectedProductForReleases({ patchState }: StateContext<ReleaseStateModel>, payload) {
        patchState({
            selectedProductForReleases: payload["payload"]
        });
    }



    @Action(ClearReleasesForm)
    clearReleaseForm(ctx: StateContext<ReleaseStateModel>){
        ctx.patchState({
            releasesForm : new StateForm()
        })
    }
}