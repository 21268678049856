import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';
import { Product } from '../models/product.model';

@Pipe({
    name: 'idFilter'
})
export class IdFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): Product {
        let selectedProduct: Product;
        if (isArray(items)) {
            items.filter(
                (product: Product) => {
                    if (product.id == searchText) {
                        selectedProduct = product;
                    }
                }
            )
        }
        return selectedProduct;
    }
}