import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FetchSessionUser, UpdateToken } from 'src/app/actions/app.actions';
import { ESPApiService } from './esp-auth.service';
import { AuthService } from './auth.service'
import { JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthInterceptor {
    refreshingToken: boolean = false;
    public jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(private store: Store, private authService: AuthService, private eSPApiService: ESPApiService ) { }

    intercept(): void {
        if(this.refreshingToken == true)
            return;
        const accessToken = this.authService.tokenGetter();
        if (!accessToken) {
            return;
        }
        console.log(this.isAboutExpire(accessToken));
        if (accessToken && this.isValidToken(accessToken) && this.isAboutExpire(accessToken)) {
            this.refreshingToken = true;
            const refreshToken = this.authService.refreshTokenGetter() || '';
            this.eSPApiService.refreshToken(refreshToken).subscribe(
                (response: any) => {
                    this.refreshingToken = false
                    localStorage.setItem('auth-obj', JSON.stringify(response));
                    this.store.dispatch(new UpdateToken(response.access_token));
                    this.store.dispatch(new FetchSessionUser({token : response.access_token }));
                },
                (error) => {
                    this.refreshingToken = false
                    console.error(error);
                }
            );
        }
    }
    //check if the token is about to exprie in 30seconds
    isAboutExpire(token: string): boolean{
        let timeToExpireMillie = this.jwtHelper.getTokenExpirationDate(token).getTime() - new Date().getTime();
        return timeToExpireMillie <= 30000 && timeToExpireMillie > 0;
    }

    isValidToken(token): boolean{
        try{
            let str = this.jwtHelper.decodeToken(token);
            return true;
        }
        catch{
            return false;
        }
    }
}
