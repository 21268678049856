import { Injectable } from '@angular/core';
import { Console } from 'console';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthZService {

  role:string = null;
  readonly actions:any = {
    GUEST_USER : ['view', 'releaseNotes', 'contactUs', 'productContact' ],
    PRODUCT_USER: ['manage', 'import', 'view', 'releaseNotes', 'contactUs', 'productContact', 'edit-listing', 'view-submissions','submit-for-review','download-csv'],
    PRODUCT_ADMIN: ['publish','import', 'manage', 'view', 'releaseNotes', 'contactUs', 'productContact','publish-review','add-releases', 'edit-listing', 'view-submissions', 'submit-for-review','download-csv', 'update-netdiagram'],
    SUPER_ADMIN: ['addProduct', 'addUser' ,'publish', 'import', 'manage', 'admin', 'view', ,'publish-review', 'add-releases','releaseNotes', 'contactUs', 'productContact', 'edit-listing', 'view-submissions', 'submit-for-review','download-csv',  'update-netdiagram']
}

  constructor(private auth: AuthService) { }

  isAuthZed(action:string, session: any = undefined): boolean{
    if(session){
      this.role = session["role"];
    }
    if(this.role == null){
      this.role = this.auth.getRole(this.auth.tokenGetter());
    }
    return this.actions[this.role]? (this.actions[this.role].indexOf(action) != -1): false;
  }
}
