import { Product } from './product.model';

export class UserDetails{
    id ?: string;
    role: string;
    products ?: Product[] = [];
    emailId: string;
    loginType: string;
    fullName: string;
    createdBy ?: UserDetails;
    lastModifiedBy ?: UserDetails;
    createdDate ? : Date;
    lastModifiedDate ?: Date;
}